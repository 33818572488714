import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-due-demand-report',
  templateUrl: './credit-due-demand-report.component.html',
  styleUrls: ['./credit-due-demand-report.component.scss']
})
export class CreditDueDemandReportComponent {
  config:any
  name='Credit Due Demand Report'
  reportInformation  = 'Provide Due Amount Details Of The Customers'
}





