<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap" rel="stylesheet">

<div class="d-flex">
    <nav>
        <ol class="breadcrumb justify-content-center ps-0">
            <mat-icon class="chevron ms-5" (click)="goBack()">chevron_left</mat-icon>
            <li class="d-flex justify-content-center" style="color:#B5ADD3;">{{currentModule==='ICE Module' ? 'Risk' : currentModule}}<mat-icon style="color:#B5ADD3;">double_arrow</mat-icon><b style="font-family: 'Poppins', sans-serif !important;
                color: #3E3E3E;">{{report_name}}</b></li>
        </ol>
    </nav>
</div>
<div class="container-fluid outer-padding">
<!-- <div class="container"> -->
    <div class="below-content">
        <label class="heading" >Report Filter</label>
        <!-- <form   style="display: flex; gap:2.5em; flex-wrap: wrap;" (ngSubmit)="onSubmit()" name="filterReport" [formGroup]="filterReport"> -->
        <form (ngSubmit)="onSubmit()" name="filterReport" [formGroup]="filterReport">
<div class="align-filters mt-2">
            <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showDate" class="row-align" >

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'From date'}}</label>
                <mat-form-field class="calendar">
                    <input matInput [matDatepicker]="datepicker" formControlName="from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('from_date',$event)" >
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker  #datepicker></mat-datepicker>
                  </mat-form-field>


            </div>
            <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showDate" class="row-align">

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'To date'}}</label>
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker2" formControlName="to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('to_date',$event)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </div>
          <!------------------------------due date------------------------------------------------------------------------>
          <div *ngIf="showDueDate" class="row-align" >

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Due From date'}}</label>
            <mat-form-field class="calendar">
                <input matInput [matDatepicker]="datepicker" formControlName="due_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('due_from_date',$event)" >
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker  #datepicker></mat-datepicker>
              </mat-form-field>

        </div>
        <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
        <div *ngIf="showDueDate" class="row-align">

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Due To date'}}</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="due_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('due_to_date',$event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

        </div>
         <!------------------------------latest due date------------------------------------------------------------------------>
         <div *ngIf="showLatestDueDate" class="row-align" >

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Latest Due From date'}}</label>
            <mat-form-field class="calendar">
                <input matInput [matDatepicker]="datepicker" formControlName="latest_due_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('latest_due_from_date',$event)" >
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker  #datepicker></mat-datepicker>
              </mat-form-field>

        </div>
        <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
        <div *ngIf="showLatestDueDate" class="row-align">

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Latest Due To date'}}</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="latest_due_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('latest_due_to_date',$event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

        </div>
        <!----------------------lead from and to date----------   -->
        <div *ngIf="showLeadDate" class="row-align" >

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Lead From date'}}</label>
            <mat-form-field class="calendar">
                <input matInput [matDatepicker]="datepicker" formControlName="lead_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('lead_from_date',$event)" >
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker  #datepicker></mat-datepicker>
              </mat-form-field>

        </div>
        <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
        <div *ngIf="showLeadDate" class="row-align">

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Lead To date'}}</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="lead_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('lead_to_date',$event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

        </div>
        <!-----------------eleigibility check from and to date-->
        <!----------------------lead from and to date----------   -->
        <div *ngIf="showEligibilityDate" class="row-align" >

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Eligibility Check From date'}}</label>
            <mat-form-field class="calendar">
                <input matInput [matDatepicker]="datepicker" formControlName="eligibility_check_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('eligibility_check_from_date',$event)" >
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker  #datepicker></mat-datepicker>
              </mat-form-field>

        </div>
        <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
        <div *ngIf="showEligibilityDate" class="row-align">

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Eligibility Check To date'}}</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="eligibility_check_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('eligibility_check_to_date',$event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

        </div>


        <!----------paid date-->
        <div *ngIf="showPaidDate" class="row-align" >

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Paid From date'}}</label>
            <mat-form-field class="calendar">
                <input matInput [matDatepicker]="datepicker" formControlName="due_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('paid_from_date',$event)" >
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker  #datepicker></mat-datepicker>
              </mat-form-field>

        </div>
        <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
        <div *ngIf="showPaidDate" class="row-align">

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Paid To date'}}</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="paid_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('paid_to_date',$event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

        </div>
        <!-----------------------disbursed at  from and to  date-->
        <div *ngIf="showDisbursedDate" class="row-align" >

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Disbursed From date'}}</label>
            <mat-form-field class="calendar">
                <input matInput [matDatepicker]="datepicker" formControlName="disbursed_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('disbursed_from_date',$event)" >
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker  #datepicker></mat-datepicker>
              </mat-form-field>

        </div>
        <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
        <div *ngIf="showDisbursedDate" class="row-align">

            <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Disbursed To date'}}</label>
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="picker2" formControlName="disbursed_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('disbursed_to_date',$event)">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

        </div>
       <!--------------payment date-->
            <div *ngIf="showPaymentDate" class="row-align" >

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Payment From date'}}</label>
                <mat-form-field class="calendar">
                    <input matInput [matDatepicker]="datepicker" formControlName="payment_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('payment_from_date',$event)" >
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker  #datepicker></mat-datepicker>
                  </mat-form-field>

            </div>
            <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showPaymentDate" class="row-align">

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Payment To date'}}</label>
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker2" formControlName="payment_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('payment_to_date',$event)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </div>

            <!-----------tnc accepted-->

            <div *ngIf="showTncDate" class="row-align" >

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Tnc Accepted From date'}}</label>
                <mat-form-field class="calendar">
                    <input matInput [matDatepicker]="datepicker" formControlName="tnc_accepted_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('tnc_accepted_from_date',$event)" >
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker  #datepicker></mat-datepicker>
                  </mat-form-field>

            </div>
            <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showTncDate" class="row-align">

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Tnc Accepted To date'}}</label>
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker2" formControlName="tnc_accepted_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('tnc_accepted_to_date',$event)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </div>
            <div *ngIf="showPaymentUpdateDate" class="row-align" >

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(From)' : 'Payment Updated at From date'}}</label>
                <mat-form-field class="calendar">
                    <input matInput [matDatepicker]="datepicker" formControlName="payment_updated_at_from_date" placeholder="YYYY-MM-DD" (dateChange)="onDateSelected('payment_updated_at_from_date',$event)" >
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker  #datepicker></mat-datepicker>
                  </mat-form-field>



            </div>
            <!-- <div *ngIf="showDate" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showPaymentUpdateDate" class="row-align">

                <label>{{(reportSelected ==='test_disbursement_report' || reportSelected ==='test_collection_report' || reportSelected ==='test_hlp_report') ? 'Disbursement Date(To)' : 'Payment Updated at To date'}}</label>
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker2" formControlName="payment_updated_at_to_date" placeholder="YYYY-MM-DD"  (dateChange)="onDateSelected('payment_updated_at_to_date',$event)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </div>



            <!-- <div *ngIf="showProductCode" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showProductCode" class="row-align">

                <mat-label>Product Code</mat-label>
                <!-- <input matInput class="input-box" formControlName="product_code"> -->
                <mat-select placeholder="Select Product Code" class="input-box" formControlName="product_code">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let product of product_code" [value]="product">{{product}}</mat-option>
                </mat-select>
            </div>
            <div *ngIf="showLoanStatus" class="col-md-3 p-4 m-2" class="row-align">

                <label>Loan Status</label>
                <!-- <mat-form-field appearance="fill"> -->
                <!-- <mat-label>Select Center</mat-label> -->
                <mat-select placeholder="Select Status" class="input-box" formControlName="loan_status">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let status of loan_status" [value]="status">{{status}}</mat-option>
                </mat-select>
                <!-- </mat-form-field> -->
            </div>
            <!-- <div *ngIf="showCenter" class="col-md-3 p-4 m-2"> -->
            <!-- <div *ngIf="loading" class="loader-container">
                <div class="loader"></div>
                <p>Loading...</p>
            </div>
                 -->
            <div *ngIf="showCenter" class="row-align">

                <label>Center</label>
                <!-- <mat-form-field appearance="fill"> -->
                <!-- <mat-label>Select Center</mat-label> -->
                <mat-select placeholder="Select Center" class="input-box" formControlName="center">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let branch  of branches" [value]="branch">{{branch}}</mat-option>
                </mat-select>
                <!-- </mat-form-field> -->
            </div>
            <!-- <div *ngIf="showCluster" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showCluster" class="row-align">

                <label>Cluster</label>
                <!-- <mat-form-field appearance="fill"> -->
                <!-- <mat-label>Select Center</mat-label> -->
                <mat-select placeholder="Select Cluster" class="input-box" formControlName="cluster">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let cluster  of clusters" [value]="cluster">{{cluster}}</mat-option>
                </mat-select>
                <!-- </mat-form-field> -->
            </div>
            <!-- <div *ngIf="showCustomerUcid" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showCustomerUcid" class="row-align">

                <label>Customer UCIC</label>
                <input class="input-box" formControlName="customer_ucic">
            </div>
            <!-- <div *ngIf="showLoanAccountNumber" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showLoanUid" class="row-align">

                <label>Loan UID</label>
                <input class="input-box" formControlName="loan_uid">
            </div>
            <div *ngIf="showMobile" class="row-align">

                <label>Mobile</label>
                <input class="input-box" formControlName="mobile">
            </div>
            <!-- <div *ngIf="showLeadId" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showLeadId" class="row-align">

                <label>Lead ID</label>
                <input class="input-box" formControlName="lead_id">
            </div>
            <div *ngIf="showHotleadLeadId" class="row-align">

                <label>Hotlead Lead ID</label>
                <input class="input-box" formControlName="hotlead_lead_id">
            </div>
            <div *ngIf="showSourcingPersonLeadId" class="row-align">

                <label>Sourcing Person ID</label>
                <input class="input-box" formControlName="sourcing_person_id">
            </div>
            <!-- <div *ngIf="showClc" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showClc" class="row-align">
                <label>Employee Name</label>
                <mat-select placeholder="Select Employee" class="input-box" formControlName="clc_name">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let clc_name of clc_names" [value]="clc_name">{{clc_name}}</mat-option>
                </mat-select>
            </div>
            <!-- <div *ngIf="showPartner" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showPartner" class="row-align">

                <label>Partner</label>
                <!-- <mat-form-field appearance="fill"> -->
                <!-- <mat-label>Select Center</mat-label> -->
                <mat-select placeholder="Select Partner" class="input-box" formControlName="partner">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let partner of partners" [value]="partner">{{partner}}</mat-option>

                </mat-select>
                <!-- </mat-form-field> -->
            </div>
            <!-- <div *ngIf="showCpId" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showCpId" class="row-align">

                <label>HLP Id</label>
                <input class="input-box" formControlName="cp_id">
            </div>
            <!-- <div *ngIf="showPincode" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showPincode" class="row-align">

                <label>Pincode</label>
                <input class="input-box" formControlName="pincode">
            </div>
            <!-- <div *ngIf="showSubOccupation" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showSubOccupation" class="row-align">

                <label>Sub-Occupation</label>
                <input class="input-box" formControlName="sub_occupation">
            </div>
            <!-- <div *ngIf="showCreditScore" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showCreditScore" class="row-align">

                <label>Credit Score</label>
                <input class="input-box" formControlName="credit_score">
            </div>
            <div *ngIf="showHlp" class="row-align">

                <label>HLP</label>
                <input class="input-box" formControlName="hlp">
            </div>
            <!-- <div *ngIf="showBankName" class="col-md-3 p-4 m-2"> -->
            <div *ngIf="showBankName" class="row-align">

                <label>Bank Name</label>
                <input class="input-box" formControlName="bank_name">
            </div>
            <div *ngIf="showProductType" class="row-align">

                <label>Product Type</label>
                <mat-select placeholder="Select Product Type" class="input-box" formControlName="product_type">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let product of product_type" [value]="product">{{product}}</mat-option>

                </mat-select>
            </div>
            <div *ngIf="showLenderType" class="row-align">

                <label>Lender Type</label>
                <mat-select placeholder="Select Lender Type" class="input-box" formControlName="lender_type">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let lender of lender_type" [value]="lender">{{lender}}</mat-option>
                </mat-select>
            </div>
            <div *ngIf="showClcStatus" class="row-align">

                <label>Clc Status</label>
                <mat-select placeholder="Select clc status" class="input-box" formControlName="clc_status">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let clc of clc_status" [value]="clc_status">{{clc_status}}</mat-option>
                </mat-select>
            </div>
            <div *ngIf="showCafStatus" class="row-align">

                <label>Caf Status</label>
                <mat-select placeholder="Select caf status" class="input-box" formControlName="caf_status">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let caf of caf_status" [value]="caf_status">{{clc_status}}</mat-option>
                </mat-select>
            </div>
            <div *ngIf="showDpd" class="row-align">

                <label>DPD Bucket</label>
                <!-- <input class="input-box" formControlName="dpd_bucket"> -->
                <mat-select placeholder="Select DPD Bucket" class="input-box" formControlName="dpd_bucket">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let dpd of dpd_bucket" [value]="dpd">{{dpd}}</mat-option>
                </mat-select>
            </div>
            <div *ngIf="showPaymentMode" class="row-align">

                <label>Payment Mode</label>
                <!-- <input class="input-box" formControlName="payment_mode"> -->
                <mat-select placeholder="Select Payment Mode" class="input-box" formControlName="payment_mode">
                    <!-- <mat-option  [value]="''">All</mat-option> -->
                    <mat-option *ngFor="let payment of payment_mode" [value]="payment">{{payment}}</mat-option>
                </mat-select>
            </div>

        </div>

            <div style='display:flex; justify-content: center;' class="mt-3">
                <button  class=" search px-3" type="submit">Search</button>
                <!-- <button *ngIf="reportSelected==='ckyc_report'" (click)="generateCkyc()" class=" search px-3 ms-3" type="button">Generate Report</button> -->

            </div>
        </form>
        <div *ngIf="searchreports">
        <hr>
        <div class="d-flex">

            <label class="heading">Report</label>
            <mat-icon *ngIf="reportSelected!=='test_collection_report'" class="download-icon" (click)="downloadXlsx()">login</mat-icon>
        </div>
            <div class="table mt-3">
                <table id="style-2"  rules="all">
                    <tr >
                        <th *ngFor="let col of config">{{col.header}}</th>
                    </tr>
                    <tr *ngFor="let res of response | paginate: { itemsPerPage: 10, currentPage: p }" >


                        <td *ngFor="let col of config">
                         <span *ngIf="col.field!=='url' && col.field!=='ckyc_name'">  {{res[col.field] || '-'}}</span>
                        <span *ngIf="col.field==='url'">
                            <!-- <button class=" search px-3" (click)="downloadCkycReport(res[col.field])">Download Report</button> -->
                            <img class="ms-5" type="button" (click)="downloadCkycReport(res[col.field])" [src]="'assets/icons/download.png'">
                        </span>
                        <span *ngIf="col.field==='ckyc_name'">
                            <label>ckyc_report_{{res['created_at']}}</label>
                        </span>
                        </td>
                      </tr>

                </table>
                    <div class="d-flex justify-content-center my-3" *ngIf="reportSelected==='ckyc_report'" >
                        <div class="pagination align-items-center d-flex">
                    <pagination-controls  class="custom-pagination" style="margin-top: 15px;" (pageChange)="p = $event"></pagination-controls>
                    <span *ngIf="response.length" class="mx-4">Showing {{p*10-9}} to {{p*10>response.length ? response.length : p*10}} of {{response.length}} entries</span>
                </div>
                    </div>
            </div>
        </div>

    </div>

</div>
