import { Component,OnInit, Input } from '@angular/core';
import { SpinnerOverlayService } from '../spinner-overlay-service.service';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent {
  @Input() message: string; // Default message

  constructor() { }

  ngOnInit() {
  }
}
