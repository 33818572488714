import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-credit-repayment-overall-report',
  templateUrl: './credit-repayment-overall-report.component.html',
  styleUrls: ['./credit-repayment-overall-report.component.scss']
})
export class CreditRepaymentOverallReportComponent {
  name='Credit Repayment Overall Report'
  reportInformation  = 'Provide  overall installment wise loan repayment.'
  }


