import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoadPermissionService {

  private apiBaseUrl = environment.apiUrl

  constructor(private http:HttpClient){

  }
  esHeaders() {
		let headers = new HttpHeaders();
		let accessToken:any = localStorage.getItem('refereshToken');
        // //console.log(accessToken)
		if (accessToken) {
			headers = headers.set('Authorization', `b ${accessToken}`);
		}
		return {headers};
	}
  loadPermissions():any{
		let data:any = localStorage.getItem('role');
    return this.http.get(this.apiBaseUrl+'roles/'+data,this.esHeaders())
  }
}
