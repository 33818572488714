<div *ngIf="isLoading" class="loading-container">
  <img src="assets/icons/ARTHKOSH1.gif" alt="Loading" class="loading-gif">
</div>

<div *ngIf="!isLoading && !errorMessage" class="dashboard-container">

  <!-- Center Selection -->
  <mat-form-field appearance="fill" class="center-select">
    <mat-label>Select Centers</mat-label>
    <mat-select [(value)]="selectedCenters" multiple (selectionChange)="onCenterChange()">
      <mat-option *ngFor="let center of allCenters" [value]="center">{{ center }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class ="buckets"></div>

  <!-- Funnel Section -->
  <h2 class="section-heading">Monthly Funnel Overview</h2>
  <div class="funnel-cards">
    <ng-container *ngFor="let center of customer_details">
      <div *ngIf="selectedCenters.includes(center.bank_branches)" class="center-section">
        <h3 class="center-name">{{ center.bank_branches }}</h3>
        <div class="status-cards">
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(0) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Total Assigned</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.total_assignment }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(1) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Total Visits</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.total_visits }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(2) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Total CAF Attempted</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.total_caf_attempted }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(3) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Total CAF Done</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.total_caf_done }}</p>
            </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Customers Section -->
  <h2 class="section-heading">Monthly Customers Insights</h2>
  <div class="customer-cards">
    <ng-container *ngFor="let center of customer_details">
      <div *ngIf="selectedCenters.includes(center.bank_branches)" class="center-section">
        <h3 class="center-name">{{ center.bank_branches }}</h3>
        <div class="status-cards">
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(5) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Credits</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.credits }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(6) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Insurance</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.insurance }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(7) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>Payment</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.paymint }}</p>
            </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>


  <!--customer details-->
  <h2 class="section-heading">Customer Engagement and Demographics Overview</h2>
  <ng-container *ngFor="let center of customerDetailData; let i = index">
    <div *ngIf="selectedCenters.includes(center.bank_branches)">
    <h3>{{ center.bank_branches }}</h3>
    <div class="chart-grid">
      <div class="chart-container">
        <div id="chart-center-{{ i }}-0"></div>
        <p>Occupation</p>
      </div>
      <div class="chart-container">
        <div id="chart-center-{{ i }}-1"></div>
        <p>Visits</p>
      </div>
      <div class="chart-container">
        <div id="chart-center-{{ i }}-2"></div>
        <p>Loans</p>
      </div>
      <div class="chart-container">
        <div id="chart-center-{{ i }}-3"></div>
        <p>Gender</p>
      </div>
    </div>
  </div>
  </ng-container>


  <!-- PQ Section -->
  <h2 class="section-heading">PORTFOLIO QUALITY</h2>
  <div class="pq-cards">
    <ng-container *ngFor="let center of customer_details">
      <div *ngIf="selectedCenters.includes(center.bank_branches)" class="center-section">
        <h3 class="center-name">{{ center.bank_branches }}</h3>
        <div class="status-cards">
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(10) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>On-time</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.on_time }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(11) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>0-10 Days</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.days_0_10 }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(12) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>11-30 Days</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.days_11_30 }}</p>
            </div>
          </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(13) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>31-60 Days</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.days_31_60 }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(14) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>61-90 Days</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.days_61_90 }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(15) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>91-180 Days</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.days_91_180 }}</p>
            </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(15) }">
            <mat-card-header class="card-header">
              <h3><strong><mat-card-title>180 Plus Days</mat-card-title></strong></h3>
            </mat-card-header>
            <mat-card-content> <div class="chart-container">
              <p>{{ center.days_180_plus }}</p>
            </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </div>


    <!--PQ_matrix_details-->
    <h2 class="section-heading">Portfolio Quality Metrics</h2>
    <ng-container *ngFor="let center of PQmatrixData; let i = index">
      <div *ngIf="selectedCenters.includes(center.bank_branches)">
        <h3>{{ center.bank_branches }}</h3>
        <div id="rader-chart-center-{{ i }}-0"></div>
      </div>
    </ng-container>




</div>

<div *ngIf="errorMessage">
  <p>{{ errorMessage }}</p>
</div>
