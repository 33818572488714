<nav class="navbar fixed-top navbar-light bg-light">
  <div class="left_content">
    <a routerLink="/home" class="nav-link">
      <!-- <mat-icon style="transform: scale(2);" svgIcon="Arth"></mat-icon> -->
      <img style="transform: scale(0.7)" [src]="'assets/icons/Arth_logo.svg'" />
    </a>

    <ul class="navbar-nav" [ngClass]="{ selected: onHome }">
      <li class="nav-item">
        <a routerLink="/dashboard" class="nav-link">
          <i class="fa-solid fa-chart-line" style="transform: scale(1.3)"></i>
          Dashboard</a
        >
      </li>
    </ul>
    <ul
      class="navbar-nav"
      [ngClass]="{ selected: isMisSelected }"
      *ngxPermissionsOnly="misPermission"
    >
      <li class="nav-item">
        <a routerLink="/home/mis" class="nav-link">
          <i class="fa-solid fa-file-excel" style="transform: scale(1.3)"></i>
          Reports</a
        >
      </li>
    </ul>
    <!-- <ul class="navbar-nav">
        <li class="nav-item">
            <a routerLink="/home" class="nav-link ">
            <mat-icon class="logo" svgIcon="Nudge"></mat-icon>
                Nudge</a>
        </li>
    </ul>
    <ul class="navbar-nav">
        <li class="nav-item">
            <a routerLink="/home" class="nav-link">
            <mat-icon class="logo" svgIcon="OVC"></mat-icon>
                OVC</a>
        </li>
    </ul> -->
    <ul
      class="navbar-nav"
      [ngClass]="{ selected: isUserSelected }"
      style="cursor: pointer"
      *ngxPermissionsOnly="['user_management_view']"
    >
      <li class="nav-item">
        <a routerLink="/home/users" class="nav-link">
          <mat-icon class="logo" style="transform: scale(1.3)">person</mat-icon>
          Users</a
        >
      </li>
    </ul>
    <!-- <ul class="navbar-nav" [ngClass]="{selected:isRoleSelected}" *ngxPermissionsOnly="['role_management_view']">
        <li class="navitem">
            <a routerLink="/home/roles" class="nav-link">
                <mat-icon class="logo" style="transform: scale(1.3);" svgIcon="dashboard"></mat-icon>
                Roles</a>
        </li>
    </ul> -->
    <ul
      class="navbar-nav"
      [ngClass]="{ selected: isRoleSelected }"
      *ngxPermissionsOnly="['role_management_view']"
    >
      <li class="navitem">
        <a routerLink="/home/roles" class="nav-link">
          <i class="fa-solid fa-users" style="transform: scale(1.3)"></i>
          Roles
        </a>
      </li>
    </ul>
    <ul
      class="navbar-nav"
      [ngClass]="{ selected: isArchiveReportSelected }"
      *ngxPermissionsOnly="['archive_reports']"
    >
      <li class="navitem">
        <a routerLink="/home/archive" class="nav-link">
          <i class="fa-sharp fa-solid fa-file-arrow-down"></i>
          Archive Report
        </a>
      </li>
    </ul>
  </div>
  <div class="d-flex flex-row me-5">
    <ul class="navbar-nav me-5">
      <li class="navitem">
        <a routerLink="/home" class="nav-link">
          On Demand
          <!-- <mat-icon class="logo" style="transform: scale(1.3);">notifications_none</mat-icon> -->
        </a>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="navitem">
        <a class="nav-link">
          <mat-icon
            (click)="logout()"
            class="logo"
            style="transform: scale(1.1)"
            >logout</mat-icon
          >
        </a>
      </li>
    </ul>
  </div>
</nav>
<!-- <div class="header row-align">
    <div *ngIf="isCreateUser || isCreateRole || updateRole || updateUser" class="back-button">
        <mat-icon style="transform: scale(1.5); cursor: pointer;" (click)="goBack()">chevron_left</mat-icon>
        <mat-label class="margin-layout">{{isCreateRole ? 'Create New Role' : (isCreateUser ? 'Create New User' :
            (updateUser ? 'Update User' : (updateRole ? 'Update Role': '')    ))}}</mat-label>
</div>
</div> -->
<!-- <button class="right-carousel-button" (click)="showCluster = !showCluster"> > </button>
<button class="left-carousel-button" (click)="showCluster = !showCluster"> < </button>

<div *ngIf="showCluster">
    <app-cluster></app-cluster>
  </div> -->
<div class="router">
  <router-outlet></router-outlet>
</div>

<button
  class="right-carousel-button"
  *ngIf="isHomePage()"
  (click)="stopLoop(); showDailyFunnel = !showDailyFunnel"
>
  >
</button>
<button
  class="left-carousel-button"
  *ngIf="isHomePage()"
  (click)="stopLoop(); showProductivity = true"
>
  <
</button>

<div *ngIf="showCluster">
  <app-cluster></app-cluster>
</div>

<div *ngIf="showDailyFunnel">
  <app-daily-funnel></app-daily-funnel>
</div>

<!-- Render Productivity component if showProductivity is true -->
<div *ngIf="showProductivity">
  <app-productivity></app-productivity>
</div>

<div
  class="welcome-message"
  *ngIf="isHomePage() && !showDailyFunnel && !showProductivity && !showCluster"
>
  <h2>
    <!-- <span>Welcome</span>
        <span>to </span> -->
    <span>Funnel </span>
    <span>Tracker </span>
    <span>{{ currentMonth }} - </span>
    <span>{{ currentYear }} </span>
  </h2>
</div>
<!---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------->

<!------------------------------------------------------------------------------------------------------------------------------------------------------------------>
<div class="container">
  <div
    id="table-one-container"
    class="tableone-container"
    *ngIf="
      isHomePage() && !showDailyFunnel && !showProductivity && !showCluster
    "
  >
    <table>
      <thead>
        <tr>
          <th>LEAD</th>
          <th>LOAN FORM</th>
          <th>OFFERED</th>
          <th>LOAN ACCEPTANCE</th>
          <th>QC</th>
          <th>QC APPROVED</th>
          <th>RFD</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of responseArray">
          <tr>
            <td style="font-weight: bold">{{ item.LEAD }}</td>
            <td style="font-weight: bold">{{ item.Loan_Form }}</td>
            <td style="font-weight: bold">{{ item.Offered }}</td>
            <td style="font-weight: bold">{{ item.Loan_Acceptance }}</td>
            <td style="font-weight: bold">{{ item.QC }}</td>
            <td style="font-weight: bold">{{ item.QC_Approved }}</td>
            <td style="font-weight: bold">{{ item.RFD }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div
    id="main-dashboard"
    class="main-dashboard center-content"
    *ngIf="
      isHomePage() && !showDailyFunnel && !showProductivity && !showCluster
    "
  >
    <div class="table-wrapper">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
          </thead>
          <tbody>
            <!-- Adjusted logic for populating table rows -->
            <ng-container *ngFor="let item of dashboardData">
              <tr>
                <!-- First column for 'Serial' -->
                <td style="width: 150px">{{ item.serial }}</td>
                <!-- Use the same logic for other columns -->
                <ng-container *ngFor="let data of item | keyvalue">
                  <!-- Exclude the 'Serial' column -->
                  <ng-container *ngIf="data.key !== 'serial'">
                    <td>{{ data.value }}</td>
                  </ng-container>
                </ng-container>
                <!-- No additional <td> needed here -->
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <button class="btn btn-primary main-carousel-button" (click)="showTableOneContainer()"> > </button> -->
  </div>

  <app-overlay *ngIf="showOverlay"></app-overlay>

  <!-- <div class="Funnel" *ngIf="isHomePage()">
        <h4>Funnel Tracker - April 2024</h4>
    </div> -->
</div>
