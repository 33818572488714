<!-- <div class="header row-align">
    <div class="back-button">
        <mat-icon style="transform: scale(1.5); cursor: pointer;" (click)="goBack()">chevron_left</mat-icon>
        <mat-label class="margin-layout">{{isCreateRole ? 'Create New Role' : 'Update Role'}}</mat-label>
</div>
</div> -->
<div class="d-flex">
    <nav>
        <ol class="breadcrumb justify-content-center ps-0">
            <mat-icon class="chevron ms-5" (click)="goBack()">chevron_left</mat-icon>
            <li>{{isCreateRole ? 'Create New Role' : 'Update Role'}}</li>
        </ol>
    </nav>
</div>
<div class="d-flex flex-column m-3 ms-5 mt-3 role-name-container">
<mat-label class="mb-2">Role name</mat-label>
<input class="me-auto role-name d-flex justify-content-center align-items-start ps-2" [(ngModel)]="rolename" >
</div>

<form  (ngSubmit)="onSubmit()" name="permissionForm" [formGroup]="permissionForm">
<div class=" outer-padding ">
    <div class="report-container">
    <div class="report_heading d-flex justify-content-center">
    <mat-label  >Roles</mat-label> </div>
    <div class='row m-3 mt-4'>
    <div class="col-md-3 p-0 m-3   module">
        <span class="tab-heading">
            <mat-label class="tab-label">User Management</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'user_management_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Role Management</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'role_management_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Customers</mat-label></span>
            <span class="checkbox-container">
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'customer_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Communication Setup</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'communication_setup_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Strategy Builder</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'strategy_builder_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Campaigns</mat-label></span>
            <span class="checkbox-container">
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'campaign_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Ticket</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'create_ticket_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Dashboard</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'dashboard_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Telecom</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'telecom_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Reports</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'reports_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    <div class="col-md-3 p-0 m-3   module" >
        <span class="tab-heading">
            <mat-label class="tab-label">Dispositions</mat-label></span>
            <span class="checkbox-container" >
        <span class="d-flex align-items-center mt-1 mb-1" *ngFor="let option of options">
            <input type="checkbox" class="checkbox-option" [formControlName]="'create_disposition_'+option">
            <mat-label class="tab-option">{{option | titlecase}}</mat-label>
        </span>
            </span>
    </div>
    </div>
    </div>
</div>

</form>

<div class="outer-padding">
    <div class="report-container">
    <div class="report_heading d-flex justify-content-center">
    <mat-label  >Reports</mat-label> </div>
    <div class="branch row m-3 mt-4" >
      <mat-label class='report_heading'></mat-label> 
        <!-- <div *ngFor="let branch of branches" style="cursor: pointer;" class="col-md-2 p-5 m-3   module">{{branch}} -->
        <!-- </div> -->
        <form name="branchForm"  [formGroup]="branchForm">
        <span class="d-flex flex-row flex-wrap">
            <span *ngFor="let branch of branches" class="d-flex align-items-center mt-2 mb-2 mx-5">
                <input type="checkbox" class="checkbox-option" formControlName="{{branch}}">
                <mat-label class="tab-option">{{branch}}</mat-label>
            </span>
                </span>
            </form>
    </div>
<form (ngSubmit)="onSubmit()" name="permissionForm" [formGroup]="permissionForm">

    <div class="branch row m-3 mt-4" >
        <mat-label class='report_heading'>Modules</mat-label> 
          <div  class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Corporate Finance</mat-label></span>
                <span class="checkbox-container">
                <span class="d-flex align-items-center mt-2 mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_due_demand_report" >
                        <mat-label class="tab-option">Credit Due Demand Report</mat-label>
                </span>
                <span class="d-flex align-items-center mb-2">
                    <input type="checkbox" class="checkbox-option" formControlName="loan_collection_report">
                    <mat-label class="tab-option">Credit Repayment Report</mat-label>
                </span>
                <span class="d-flex align-items-center mb-2">
                    <input type="checkbox" class="checkbox-option" formControlName="kinara_business_details_report" >
                    <mat-label class="tab-option">Credit Co Lending KC Business Detail Report</mat-label>
                </span>
                <span class="d-flex align-items-center mb-2">
                    <input type="checkbox" class="checkbox-option" formControlName="kinara_customer_details_report" >
                    <mat-label class="tab-option">Credit Co Lending KC Customer Detail Report</mat-label>
                </span>
                <span class="d-flex align-items-center mb-2">
                    <input type="checkbox" class="checkbox-option" formControlName="credit_funel_tracking_report" >
                    <mat-label class="tab-option">Funnel Tracking Report</mat-label>
                </span>
       
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="kinara_disbursement_details_report" >
                <mat-label class="tab-option">Credit Co Lending KC Disbursement Detail Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="master_data_report" >
                <mat-label class="tab-option">Master Business  Report</mat-label>
            </span>
            
                </span>
          </div>
          <!-------------------business finance------------>
          <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Business Finance</mat-label></span>
                <span class="checkbox-container">
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="insurance_report" >
                <mat-label class="tab-option">Credit Insurance Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="disbursement_details_report" >
                <mat-label class="tab-option">Bank Disbursement Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_due_demand_report" >
                <mat-label class="tab-option">Credit Due Demand Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="ckyc_report" >
                <mat-label class="tab-option">Ckyc Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="loan_collection_report">
                <mat-label class="tab-option">Credit Repayment Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_reconciliation_report" >
                <mat-label class="tab-option">Credit Reconciliation Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="arth_static_lms_report">
                <mat-label class="tab-option">Credit Old Customer Repayment Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_business_overall_report" >
                <mat-label class="tab-option">Master Business Overall Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="master_data_report" >
                <mat-label class="tab-option">Master Business  Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_repayment_overall_report" >
                <mat-label class="tab-option">Credit Repayment Overall Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_business_plus_report" >
                <mat-label class="tab-option">Master Business 365 Plus Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_repayment_plus_report" >
                <mat-label class="tab-option">Credit Repayment 365 Plus Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="kinara_business_details_report" >
                <mat-label class="tab-option">Credit Co Lending KC Business Detail Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="kinara_customer_details_report" >
                <mat-label class="tab-option">Credit Co Lending KC Customer Detail Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_funel_tracking_report" >
                <mat-label class="tab-option">Funnel Tracking Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="pre_demand_monthly_report" >
                <mat-label class="tab-option">Pre Demand Monthly Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="kinara_disbursement_details_report" >
                <mat-label class="tab-option">Credit Co Lending KC Disbursement Detail Report</mat-label>
            </span>
        </span>
          </div>
          <!-------------welfare report---------------------------->
          <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Welfare</mat-label></span>
                <span class="checkbox-container">
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="welfare_report" >
                <mat-label class="tab-option">Welfare Report</mat-label>
            </span>
                </span>
          </div>
    
          <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Portfolio Collections</mat-label></span>
                <span class="checkbox-container">
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_funel_tracking_report" >
                        <mat-label class="tab-option">Funnel Tracking Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mt-2 mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_reconciliation_report" >
                        <mat-label class="tab-option">Credit Reconciliation Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mt-2 mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_due_demand_report" >
                        <mat-label class="tab-option">Credit Due Demand Report</mat-label>
                </span>
             
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_repayment_plus_report" >
                        <mat-label class="tab-option">Credit Repayment 365 Plus Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_business_overall_report" >
                        <mat-label class="tab-option">Master Business Overall Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="master_data_report" >
                        <mat-label class="tab-option">Master Business  Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_repayment_overall_report" >
                        <mat-label class="tab-option">Credit Repayment Overall Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_repayment_report" >
                        <mat-label class="tab-option">Credit Repayment Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="credit_business_plus_report" >
                        <mat-label class="tab-option">Master Business 365 Plus Report</mat-label>
                    </span>
          
                </span>
          </div>
          <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Central Ops</mat-label></span>
                <span class="checkbox-container">
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="master_data_report" >
                <mat-label class="tab-option">Master Business Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="customer_journey_drop_report" >
                <mat-label class="tab-option">Customer Journey Drop Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_funel_tracking_report" >
                <mat-label class="tab-option">Funnel Tracking Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="eligibility_check_failed_customer_report" >
                <mat-label class="tab-option">Credit Eligibility Status Report</mat-label>
            </span>
    
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="hlp_hotlead_report" >
                <mat-label class="tab-option">HLP Hotlead Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="hlp_report" >
                <mat-label class="tab-option">HLP Status Report</mat-label>
            </span>
                </span>
          </div>

          <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Risk</mat-label></span>
                <span class="checkbox-container">
           
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_funel_tracking_report">
                <mat-label class="tab-option">Credit Funel Tracking Report</mat-label>
            </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_evaluation_report">
                <mat-label class="tab-option">Credit Evaluation Report</mat-label>
            </span>
                <span class="d-flex align-items-center mb-2">
                    <input type="checkbox" class="checkbox-option" formControlName="loan_collection_report">
                    <mat-label class="tab-option">Credit Repayment Report</mat-label>
                </span>
            </span>
                
           </div>
          
          <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">S&D</mat-label></span>
                <span class="checkbox-container">
           
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="hlp_hotlead_report" >
                        <mat-label class="tab-option">HLP Hotlead Report</mat-label>
                    </span>
                    <span class="d-flex align-items-center mb-2">
                        <input type="checkbox" class="checkbox-option" formControlName="hlp_report" >
                        <mat-label class="tab-option">HLP Status Report</mat-label>
                    </span>
            <span class="d-flex align-items-center mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="master_data_report" >
                <mat-label class="tab-option">Master Business  Report</mat-label>
            </span>
            <span class="d-flex align-items-center mt-2 mb-2">
                <input type="checkbox" class="checkbox-option" formControlName="credit_due_demand_report" >
                <mat-label class="tab-option">Credit Due Demand Report</mat-label>
        </span>
        <span class="d-flex align-items-center mb-2">
            <input type="checkbox" class="checkbox-option" formControlName="loan_collection_report">
            <mat-label class="tab-option">Credit Repayment Report</mat-label>
        </span>
        <span class="d-flex align-items-center mb-2">
            <input type="checkbox" class="checkbox-option" formControlName="credit_funel_tracking_report" >
            <mat-label class="tab-option">Funnel Tracking Report</mat-label>
        </span>
                </span>
          </div>
                   
          <!-- <div  style="cursor: pointer;" class="col-md-3 p-0 m-3   module">
            <span class="tab-heading">
                <mat-label class="tab-label">Requirement</mat-label></span>
          </div> -->
      </div>
    </form>
    </div>
</div>

 <div class="outer-padding d-flex align-items-center justify-content-center">
    <button class="role-user-button" type="submit" (click)="onSubmit()">
        <mat-label>{{edit ? 'Update' :'
            Create'}}
        </mat-label>
    </button>
</div>


