import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import Chart from 'chart.js/auto';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LoopService } from '../loop.service';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss']
})

export class ClusterComponent implements OnInit {
  clusterDataSubscription: Subscription;
  showProductivity: boolean = false;
  showLead: boolean = false;
  showCluster: boolean = false;
  showDailyFunnel:boolean = false;
  ClusterIndex = -1;
  currentClusterIndex = -1;
  showDashboard = true;
  isActive: boolean = true;
  chart1: any;
  chart2: any;
  chartOptions: any;
  clusterDataResponse: any = null;
  clustersArray: string[] = [];
  currentIndex: number = 0;
  private dataFetched: boolean = false;
  currentMonth:string;
  currentYear: number;

  clusters = [
    "Arth-Ayodhya",
    "Arth-Bhiwadi",
    "Arth-NCR",
    "Arth-Raebareli",
    "Arth-Lucknow",
    "Arth-Jaipur"
];

  data: any[] = [
    { label: '', value: 50 },
    { label: '', value: 20 },
    { label: '', value: 10 },
    { label: '', value: 10 }
  ];
  data2: any[] = [
    { label: '', value: 50 },
    { label: '', value: 70 },
  ];
  data3: any[] = [
    { label: '', value: 50 },
    { label: '', value: 70 },
  ];

  constructor(private dashboardService: DashboardService,public router: Router, private loopService: LoopService,private http: HttpClient) {

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const label = context.dataset.label;
              const value = context.dataset.data[context.dataIndex];
              return `${label}: ${value}%`;
            }
          }
        }
      }
    };
  }

  ngOnInit(): void {
    const date = new Date();
    this.currentMonth = date.toLocaleDateString('en-Us', {month:'long'});
    this.currentYear = date.getFullYear();

    this.showClusterDashboard();


      // setTimeout(() => {
      //   this.renderPieCharts();
      // }, ); // Adjust the delay as needed
    }
    ngOnDestroy() {
      if (this.clusterDataSubscription) {
        this.clusterDataSubscription.unsubscribe();
      }
    }


    stopLoop(): void {
      this.loopService.stopLoop();
    }
    showClusterDashboard(): void {
      // Check if the component is on the home page
      if (!this.isHomePage()) {
        return; // Stop the API call if not on the home page
      }

      // Don't make the API call if on specific pages
      if (this.showProductivity || this.showLead || this.showDailyFunnel) {
        return;
      }

      // Set parameters for the API call
      const params = new HttpParams().set('cluster_name', 'all_clusters');

      // Make the API call every 10 minutes
      interval(10 * 60 * 1000)
        .pipe(
          startWith(0), // Emit a value immediately when the component initializes
          switchMap(() => this.dashboardService.getClusterWiseDashbord(params))
        )
        .subscribe(
          (response) => {
            // Handle successful response here
            this.clusterDataResponse = response; // Store the response data

            // Increment the currentClusterIndex for the next iteration
            // this.currentClusterIndex = (this.currentClusterIndex + 1) % this.clusters.length;
            this.clustersArray = Object.keys(this.clusterDataResponse);
            this.displayCluster();

            // Call showClusterDashboard() recursively after 10 seconds


          },
          (error) => {
            // Handle error here
          }
        );
    }

    displayCluster(): void {
      if (!this.isHomePage()) {
        return; // Stop the API call if not on the home page
      }


      // Don't make the API call if on specific pages

      setTimeout(() => {
        if (!this.isHomePage() || !this.loopService.isLoopRunning()) {
          return; // Exit the function if the loop should stop
        }
        this.currentIndex++;
        if (this.currentIndex >= this.clustersArray.length) {
          this.currentIndex = 0; // Reset index if all clusters have been displayed
        }
        this.showCluster = true;
        this.displayCluster(); // Recursive call for the next cluster
      }, 5000); // 10 seconds timeout
    }
    renderClusterData(data: any): void {
      // Render the data in HTML
      // Example: Update properties or call a method to update UI
      //console.log('Data for current cluster:', data);
      // Update UI or call methods to render data in HTML
    }


  isHomePage(): boolean {
    return this.router.url === '/home';
  }


  renderPieCharts(): void {
    const processedData1 = this.processData(this.data);
    const processedData2 = this.processData(this.data2);
    const processedData3 = this.processData(this.data3);

    const ctx1 = document.getElementById('pieChart1') as HTMLCanvasElement;
    this.chart1 = new Chart(ctx1, {
      type: 'pie',
      data: {
        labels: processedData1.labels,
        datasets: [{
          label: 'Pie Chart 1',
          data: processedData1.values,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: this.chartOptions // Reusing the same options
    });

    const ctx2 = document.getElementById('pieChart2') as HTMLCanvasElement;
    this.chart2 = new Chart(ctx2, {
      type: 'pie',
      data: {
        labels: processedData2.labels,
        datasets: [{
          label: 'Pie Chart 2',
          data: processedData2.values,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1
        }]
      },
      options: this.chartOptions // Reusing the same options
    });

  const ctx3 = document.getElementById('pieChart3') as HTMLCanvasElement;
  this.chart2 = new Chart(ctx3, {
    type: 'pie',
    data: {
      labels: processedData3.labels,
      datasets: [{
        label: 'Market',
        data: processedData3.values,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1
      }]
    },
    options: this.chartOptions // Reusing the same options
  });
}
// get clustersArray(){
//   return Object.keys(this.clusterDataResponse || {});

// }
  processData(data: any[]): { labels: string[], values: number[] } {
    const labels = data.map(item => item.label);
    const values = data.map(item => item.value);
    return { labels, values };
  }



}
