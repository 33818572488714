import { Component,ElementRef ,OnInit} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { LoopService } from '../loop.service';

import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-daily-funnel',
  templateUrl: './daily-funnel.component.html',
  styleUrls: ['./daily-funnel.component.scss']
})
export class DailyFunnelComponent implements OnInit {
  showLead: boolean = false;
  showCluster: boolean = false;
  showProductivity: boolean = false;
  showTableOne:  boolean = true;
  Triggered: boolean = false;
  carouselClicked: boolean = false;
  initialized: boolean = false;
  loopRunning: boolean = true;
  currentMonth:string;
  currentYear:number;

  constructor(public router: Router,public route: ActivatedRoute,private elementRef: ElementRef, private loopService: LoopService, private dashboardService: DashboardService) {}




  dailyFunnelData: any;
  getObjectEntries(obj: any): any[] {
    return Object.entries(obj || {});
  }



  ngOnInit(): void {
    const date = new Date();
    this.currentMonth = date.toLocaleDateString('en-US', {month:'long'});
    this.currentYear = date.getFullYear();
    // this.startCarousel();
    this.dashboardService.getDailyLeadsSalesFunnelDashboard().subscribe(
      (data: any) => {
        this.dailyFunnelData = data;
        // this.headerIndex = this.dashboardData && this.dashboardData.length > 0 ?
        //                   Object.keys(this.dashboardData[0]).length + 1 : 0;

        // //console.log(typeof(this.dailyFunnelData), 'dashboard');
      },
      (error) => {
        console.error('API Error:', error);
      }
    );


  }
  get centerKeys() {
    return Object.keys(this.dailyFunnelData.center || {});
  }

  get centerMiddleIndex() {
    return Math.floor(this.centerKeys.length/2);
  }


  get partnerMiddleIndex() {
    //
    return Math.floor(this.partnerKeys.length/2);
   }
  get partnerKeys(){
    return Object.keys(this.dailyFunnelData.partner || {});


  }
  isHomePage(): boolean {
    return this.router.url === '/home';
  }
  stopLoop(): void {
    this.loopService.stopLoop();
  }
}

