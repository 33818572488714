<div *ngIf="isLoading" class="loading-container">
  <iframe src="https://lottie.host/embed/3a024223-432e-4360-bc86-d5488b6b2c41/qTvNOnZBAo.json"
  class="large-iframe"                     ></iframe>
</div>

<div *ngIf="!isLoading && !errorMessage" class="dashboard4-container">
  <div class = 'title_p'>
    <p><b>Bussiness Prime Plus+ Projection {{currentMonth}}  {{currentYear}} </b></p>
  </div>
  <div class = 'prime_projection'>
    <div class="stack_hor_chart">
      <div id = "prime_plus_stack_chart"></div>
    </div>
    <div class="stack_ver_chart">
      <div  id = "prime_plus_collumn_chart"></div>
    </div>
  </div>
  <div class = 'title_p'>
    <p><b>Bussiness Prime Projection {{currentMonth}}  {{currentYear}} </b></p>
  </div>
  <div class = 'prime_projection'>
    <div class="stack_hor_chart">
      <div id = "prime_stack_chart"></div>
    </div>
    <div class="stack_ver_chart">
      <div  id = "prime_collumn_chart"></div>
    </div>
  </div>
</div>
