import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {
  edit: boolean = false;
  isCreateUser: boolean = false;
  showCenterDropdown: boolean = false;
  centers: string[] = ['Arth-Ayodhya', 'Arth-Delhi', 'Arth-Bachhrawan', 'Arth-Bhiwadi', 'Arth-Lucknow', 'Arth-Madiyaon', 'Arth-Pataudi', 'Arth-Raebareli', 'Arth-Sultanpur', 'Arth-Unchahar', 'Arth-Nagaur', 'Arth-Alwar', 'Arth-Mirzapur', 'Arth-Udaipur', 'Arth-Jaipur'];

  createNewUserForm: FormGroup;
  user: any;
  roles: any;

  constructor(
    public router: Router,
    private roleService: RoleService,
    private activatdRoute: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.roleService.fetchRoles().subscribe((data: any) => {
      this.roles = data;
    });
    this.isCreateUser = window.location.pathname === '/home/users/create-new-user';
    this.activatdRoute.queryParamMap.subscribe(qParam => {
      if (qParam.get('edit') === 'true') {
        this.isCreateUser = false;
        this.edit = true;
        this.user = localStorage.getItem('userDetail');
        this.user = JSON.parse(this.user);
      } else {
        this.edit = false;
      }

      this.createNewUserForm = this.formBuilder.group({
        'name': [this.edit ? this.user.name : null],
        'emp_id': [this.edit ? this.user.emp_id : null],
        'email': [this.edit ? this.user.email : null],
        'password': [this.edit ? this.user.password : null],
        'mobile': [this.edit ? this.user.mobile : null],
        'role': [this.edit ? this.user.role : null],
        'centers': [this.edit ? (this.user.centers || []) : [], this.edit ? null : Validators.required], // Initialize centers as an array
      });

      this.activatdRoute.queryParamMap.subscribe(qParam => {
        const role = this.createNewUserForm.get('role')?.value;
        this.onRoleChange({ target: { value: role } }); // Trigger role change to handle centerDropdown visibility
      });
    });
  }

  onSubmit() {
    if (!this.createNewUserForm.valid) {
      return;
    }
    const formData = this.createNewUserForm.value;
    //console.log(formData);

    if (this.edit) {
      this.userService.editUser(formData).subscribe(res => {
        this.router.navigate(['home/users']);
      });
    } else {
      this.userService.createNewUser(formData).subscribe(res => {
        this.router.navigate(['home/users']);
      });
    }
  }

  goBack() {
    window.history.back();
  }

  onRoleChange(event: any) {
    const selectedRole = event?.target?.value || '';
    this.showCenterDropdown = selectedRole === 'S&D';
    if (this.showCenterDropdown) {
      this.createNewUserForm.get('centers')?.setValidators(Validators.required);
    } else {
      this.createNewUserForm.get('centers')?.clearValidators();
    }
    this.createNewUserForm.get('centers')?.updateValueAndValidity();
  }
}
