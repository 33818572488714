// overlay.component.ts
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  //   setTimeout(() => {
  //     // Navigate to dashboard component after 3 seconds
  //     // Replace 'dashboard' with the appropriate route
  //   //  window.location.href = '/''';
  // }, 0); // 3000 milliseconds = 3 seconds
  }

}
