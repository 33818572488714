import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoanCollectionReportComponent } from '../finance-module/loan-collection-report/loan-collection-report.component';
import { CreditReconciliationReportComponent } from '../finance-module/credit-reconciliation-report/credit-reconciliation-report.component';
import { CreditBusinessPlusReportComponent } from '../insurance-module/credit-business-plus-report/credit-business-plus-report.component';
import { CreditRepaymentOverallReportComponent } from '../insurance-module/credit-repayment-overall-report/credit-repayment-overall-report.component';
import { CreditDueDemandReportComponent } from '../common-module/credit-due-demand-report/credit-due-demand-report.component';
import { CreditBusinessOverallReportComponent } from '../insurance-module/credit-business-overall-report/credit-business-overall-report.component';
import { MasterBussinessOverallReportComponent } from '../finance-module/master-bussiness-overall-report/master-bussiness-overall-report.component';
import { CreditFunnelOverallTrackingReportComponent } from '../finance-module/credit-funnel-overall-tracking-report/credit-funnel-overall-tracking-report.component';
import { CreditRepaymentReportComponent } from '../common-module/credit-repayment-report/credit-repayment-report.component';

@Component({
  selector: 'app-collection-module',
  templateUrl: './collection-module.component.html',
  styleUrls: ['./collection-module.component.scss'],
  entryComponents: [
    CreditBusinessOverallReportComponent,
    CreditDueDemandReportComponent,
    CreditReconciliationReportComponent,
    CreditFunnelOverallTrackingReportComponent,
    CreditBusinessPlusReportComponent,
    CreditRepaymentOverallReportComponent,
    MasterBussinessOverallReportComponent,
    CreditRepaymentReportComponent
  ],
})
export class CollectionModuleComponent {
  reports: any = {
    'Credit Reconciliation Report': {
      name: 'credit_reconciliation_report',
      permission: ['credit_reconciliation_report'],
    },
    'Master Business 365 Plus Report': {
      name: 'credit_business_plus_report',
      permission: ['credit_business_plus_report'],
    },
    'Credit Repayment Report': {
      name: 'credit_repayment_report',
      permission: ['credit_repayment_report'],
    },
    'Credit Repayment 365 Plus Report': {
      name: 'credit_repayment_plus_report',
      permission: ['credit_repayment_plus_report'],
    },
    'Master Business OverAll Report': {
      name: 'master_business_overall_report',
      permission: ['master_business_overall_report'],
    },
    'Credit Funnel OverAll Tracking Report Overall': {
      name: 'credit_funnel_overall_tracking_report',
      permission: ['credit_funnel_overall_tracking_report'],
    },
    'Credit Due Demand Report': {
      name: 'credit_due_demand_report',
      permission: ['credit_due_demand_report'],
    },
  };

  constructor(private router: Router) {}

  reportClicked(data: any) {
    if (this.reports[data].name === 'credit_reconciliation_report')
      this.router.navigate(['/home/mis/CreditReconciliationReport'], {
        queryParams: {
          module: 'Portfolio_Collections',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'loan_collection_report')
      this.router.navigate(['/home/mis/LoanCollectionReport'], {
        queryParams: {
          module: 'Portfolio_Collections',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'credit_business_plus_report')
      this.router.navigate(['/home/mis/CreditBusinessPlusReport'], {
        queryParams: {
          module: 'Portfolio_Collections',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'credit_repayment_report')
      this.router.navigate(['/home/mis/CreditRepaymentReport'], {
        queryParams: {
          module: 'common',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'master_business_overall_report')
      this.router.navigate(['/home/mis/MasterBussinessOverallReport'], {
        queryParams: { module: 'business', report: this.reports[data].name },
      });
    else if (
      this.reports[data].name === 'credit_funnel_overall_tracking_report'
    )
      this.router.navigate(['/home/mis/CreditFunnelOverallTrackingReport'], {
        queryParams: { module: 'business', report: this.reports[data].name },
      });
    else if (this.reports[data].name === 'credit_due_demand_report')
      this.router.navigate(['/home/mis/CreditDueDemandReport'], {
        queryParams: {
          module: 'Portfolio_Collections',
          report: this.reports[data].name,
        },
      });
  }

  goBack() {
    window.history.back();
  }
}
