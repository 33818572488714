import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './view/pages/auth/auth.component';
import { HomeComponent } from './view/pages/home/home.component';
import { CollectionModuleComponent } from './view/pages/mis/collection-module/collection-module.component';
import { CustomerModuleComponent } from './view/pages/mis/customer-module/customer-module.component';
import { DashboardComponent } from './view/pages/dashboard/dashboard.component';
import { ReportsComponent } from './view/pages/mis/reports/reports.component';
import { FinanceModuleComponent } from './view/pages/mis/finance-module/finance-module.component';
import { IceModuleComponent } from './view/pages/mis/ice-module/ice-module.component';
import { CommonModuleComponent } from './view/pages/mis/common-module/common-module/common-module.component';
import { SAndDModuleComponent } from './view/pages/mis/s-and-d-module/s-and-d-module.component';
import { InsuranceModuleComponent } from './view/pages/mis/insurance-module/insurance-module.component';
import { MisComponent } from './view/pages/mis/mis/mis.component';
import { ReportFilterComponent } from './view/pages/mis/report-filter/report-filter.component';
import { WelfareModuleComponent } from './view/pages/mis/welfare-module/welfare-module.component';
import { CreateNewRoleComponent } from './view/pages/roles/create-new-role/create-new-role.component';
import { RolesComponent } from './view/pages/roles/roles.component';
import { CreateNewUserComponent } from './view/pages/users/create-new-user/create-new-user.component';
import { UsersComponent } from './view/pages/users/users.component';
import { ArchiveReportComponent } from './view/pages/archive-report/archive-report.component';

import { InsuranceReportComponent } from './view/pages/mis/insurance-module/insurance-report/insurance-report.component';
import { PreDemandMonthlyReportComponent } from './view/pages/mis/finance-module/pre-demand-monthly-report/pre-demand-monthly-report.component';
import { LoanCollectionReportComponent } from './view/pages/mis/finance-module/loan-collection-report/loan-collection-report.component';
import { CreditReconciliationReportComponent } from './view/pages/mis/finance-module/credit-reconciliation-report/credit-reconciliation-report.component';
import { CreditDueDemandReportComponent } from './view/pages/mis/common-module/credit-due-demand-report/credit-due-demand-report.component';
import { GoatTrustReportComponent } from './view/pages/mis/insurance-module/goat-trust-report/goat-trust-report.component';
import { CollectionReportComponent } from './view/pages/mis/collection-module/collection-report/collection-report.component';
import { WelfareReportComponent } from './view/pages/mis/welfare-module/welfare-report/welfare-report.component';
import { DemandSheetReportComponent } from './view/pages/mis/collection-module/demand-sheet-report/demand-sheet-report.component';
import { CustomerDetailReportComponent } from './view/pages/mis/customer-module/customer-detail-report/customer-detail-report.component';
import { EligibilityCheckFailedCustomerReportComponent } from './view/pages/mis/customer-module/eligibility-check-failed-customer-report/eligibility-check-failed-customer-report.component';
import { IceResponseReportComponent } from './view/pages/mis/ice-module/ice-response-report/ice-response-report.component';
import { IceRejectionReportComponent } from './view/pages/mis/ice-module/ice-rejection-report/ice-rejection-report.component';
import { LtdReportComponent } from './view/pages/mis/customer-module/lead_to_loan_disbursment_report/ltd-report.component';
import { AuthGuard } from './view/pages/auth/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { PartnerModuleComponent } from './view/pages/mis/partner-module/partner-module.component';
import { HlpHotleadReportComponent } from './view/pages/mis/partner-module/hlp-hotlead-report/hlp-hotlead-report.component';
import { InsuranceBankDetailsComponent } from './view/pages/mis/finance-module/insurance-bank-details/insurance-bank-details.component';
import { CkycReportComponent } from './view/pages/mis/customer-module/ckyc-report/ckyc-report.component';
import { TestDisbursementReportComponent } from './view/pages/mis/customer-module/test-disbursement-report/test-disbursement-report.component';
import { TestCollectionReportComponent } from './view/pages/mis/customer-module/test-collection-report/test-collection-report.component';
import { TestHlpReportComponent } from './view/pages/mis/customer-module/test-hlp-report/test-hlp-report.component';
import { AssignmentReportComponent } from './view/pages/mis/ice-module/assignment-report/assignment-report.component';
import { CreditEvaluationReportComponent } from './view/pages/mis/ice-module/credit-evaluation-report/credit-evaluation-report.component';
import { CustomerJourneyDropReportComponent } from './view/pages/mis/customer-module/customer-journey-drop-report/customer-journey-drop-report.component';
import { ArthStaticLmsReportComponent } from './view/pages/mis/finance-module/arth-static-lms-report/arth-static-lms-report.component';
import { KinaraBusinessDetailsReportComponent } from './view/pages/mis/finance-module/kinara-business-details-report/kinara-business-details-report.component';
import { KinaraCustomerDetailsReportComponent } from './view/pages/mis/finance-module/kinara-customer-details-report/kinara-customer-details-report.component';
import { KinaraDisbursementDetailsReportComponent } from './view/pages/mis/finance-module/kinara-disbursement-details-report/kinara-disbursement-details-report.component';
import { OverlayComponent } from './view/pages/mis/overlay/overlay.component';
import { ClusterComponent } from './view/pages/home/cluster/cluster.component';
import { CreditBusinessOverallReportComponent } from './view/pages/mis/insurance-module/credit-business-overall-report/credit-business-overall-report.component';
import { CreditBusinessPlusReportComponent } from './view/pages/mis/insurance-module/credit-business-plus-report/credit-business-plus-report.component';
import { CreditRepaymentOverallReportComponent } from './view/pages/mis/insurance-module/credit-repayment-overall-report/credit-repayment-overall-report.component';
import { HlpInvoicingReportComponent } from './view/pages/mis/customer-module/hlp-invoicing-report/hlp-invoicing-report.component';
import { PaymintSettlementReportComponent } from './view/pages/mis/insurance-module/paymint-settlement-report/paymint-settlement-report.component';
import { PaymintSubmerchantReportComponent } from './view/pages/mis/insurance-module/paymint-submerchant-report/paymint-submerchant-report.component';
import { PaymintTransactionReportComponent } from './view/pages/mis/insurance-module/paymint-transaction-report/paymint-transaction-report.component';
import { BusinessPrimeSalesReportComponent } from './view/pages/mis/s-and-d-module/business-prime-sales-report/business-prime-sales-report.component';
import { MasterBussinessReportComponent } from './view/pages/mis/common-module/master-bussiness-report/master-bussiness-report.component';
import { MasterBussinessOverallReportComponent } from './view/pages/mis/finance-module/master-bussiness-overall-report/master-bussiness-overall-report.component';
import { CreditFunnelTrackingReportComponent } from './view/pages/mis/common-module/credit-funnel-tracking-report/credit-funnel-tracking-report.component';
import { CreditFunnelOverallTrackingReportComponent } from './view/pages/mis/finance-module/credit-funnel-overall-tracking-report/credit-funnel-overall-tracking-report.component';
import { HumanResourcesModuleComponent } from './view/pages/mis/human-resources-module/human-resources-module.component';
import { EmployeeAttendanceReportComponent } from './view/pages/mis/human-resources-module/employee-attendance-report/employee-attendance-report.component';
import { HlpStatusReportComponent } from './view/pages/mis/partner-module/hlp-status-report/hlp-status-report.component';
import { CreditPreDisbursementReportComponent } from './view/pages/mis/finance-module/credit-pre-disbursement-report/credit-pre-disbursement-report.component';
import { CreditRepaymentReportComponent } from './view/pages/mis/common-module/credit-repayment-report/credit-repayment-report.component';

const routes: Routes = [
  {path:'',component:AuthComponent},
  {path:'home/cluster', component: ClusterComponent},
  {path:'home', canActivate:[AuthGuard], component:HomeComponent,children:[
    {path :'', component:HomeComponent},
    {path:'users',component:UsersComponent},
    {path:'archive',component:ArchiveReportComponent},
    {path:'roles',component:RolesComponent},
    {path:'roles/create-new-role',component:CreateNewRoleComponent},
    {path:'users/create-new-user',component:CreateNewUserComponent},
    {path:'mis',component:MisComponent,children:[
    {path: 'home', component: OverlayComponent },
      {path:'',component:ReportsComponent},
      {path:'FinanceModule',component:FinanceModuleComponent},
      {path:'InsuranceModule',component:InsuranceModuleComponent},
      {path:'CollectionModule',component:CollectionModuleComponent},
      {path:'CustomerModule',component:CustomerModuleComponent},
      {path:'IceModule',component:IceModuleComponent},
      {path:'HumanResourseModule',component:HumanResourcesModuleComponent},
      {path:'CommonModule',component:CommonModuleComponent},
      {path: 'SAndDModule',component:SAndDModuleComponent},
      {path:'WelfareModule',component:WelfareModuleComponent},
      {path:'PartnerModule',component:PartnerModuleComponent},
      {path:'FetchReport',component:ReportFilterComponent},
      {path:'insurance_report' ,canActivate:[NgxPermissionsGuard],data: {permissions:{only:["insurance_report"],redirectTo:'home'}},component:InsuranceReportComponent},
      {path:'LoanCollectionReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["loan_collection_report"],redirectTo:'home'}},component:LoanCollectionReportComponent },
      {path:'CreditReconciliationReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_reconciliation_report"],redirectTo:'home'}},component:CreditReconciliationReportComponent},
      {path:'CreditDueDemandReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_due_demand_report"],redirectTo:'home'}},component:CreditDueDemandReportComponent},
      {path:'PreDemandMonthlyReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["pre_demand_monthly_report"],redirectTo:'home'}},component:PreDemandMonthlyReportComponent},
      {path:'InsuranceBankDetailsReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["insurance_bank_details_report"],redirectTo:'home'}},component:InsuranceBankDetailsComponent},
      {path:'goat_trust_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["goat_trust_report"],redirectTo:'home'}},component:GoatTrustReportComponent},
      {path:'collection_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["collection_report"],redirectTo:'home'}},component:CollectionReportComponent},
      {path:'welfare_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["welfare_report"],redirectTo:'home'}},component:WelfareReportComponent},
      {path:'demand_sheet_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["demand_sheet_report"],redirectTo:'home'}},component:DemandSheetReportComponent},
      {path:'eligibility_check_failed_customer_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["eligibility_check_failed_customer_report"],redirectTo:'home'}},component:EligibilityCheckFailedCustomerReportComponent},
      {path:'customer_journey_drop_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["customer_journey_drop_report"],redirectTo:'home'}},component:CustomerJourneyDropReportComponent},
      {path:'customer_details_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["customer_details_report"],redirectTo:'home'}},component:CustomerDetailReportComponent},
      {path:'ice_response_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["ice_response_report"],redirectTo:'home'}},component:IceResponseReportComponent},
      {path:'rejection_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["rejection_report"],redirectTo:'home'}},component:IceRejectionReportComponent},
      {path:'lead_to_loan_disbursment_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["lead_to_loan_disbursment_report"],redirectTo:'home'}},component:LtdReportComponent},
      {path:'hlp_hotlead_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["hlp_hotlead_report"],redirectTo:'home'}},component:HlpHotleadReportComponent},
      {path:'ckyc_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["ckyc_report"],redirectTo:'home'}},component:CkycReportComponent},
      {path:'test_collection_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["test_collection_report"],redirectTo:'home'}},component:TestCollectionReportComponent},
      {path:'test_disbursement_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["test_disbursement_report"],redirectTo:'home'}},component:TestDisbursementReportComponent},
      {path:'test_hlp_report',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["test_hlp_report"],redirectTo:'home'}},component:TestHlpReportComponent},
      {path:'CreditEvaluationReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_evaluation_report"],redirectTo:'home'}},component:CreditEvaluationReportComponent},
      {path:'KinaraCustomerDetailsReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["kinara_customer_details_report"],redirectTo:'home'}},component:KinaraCustomerDetailsReportComponent},
      {path:'KinaraBusinessDetailsReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["kinara_business_details_report"],redirectTo:'home'}},component:KinaraBusinessDetailsReportComponent},
      {path:'KinaraDisbursementDetailsReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["kinara_disbursement_details_report"],redirectTo:'home'}},component:KinaraDisbursementDetailsReportComponent},
      {path:'ArthStaticLmsReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["arth_static_lms_report"],redirectTo:'home'}},component:ArthStaticLmsReportComponent},
      {path:'CreditBusinessPlusReport', canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_business_plus_report"],redirectTo:'home'}},component:CreditBusinessPlusReportComponent},
      {path:'CreditBusinessOverallReport', canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_business_overall_report"],redirectTo:'home'}},component:CreditBusinessOverallReportComponent},
      {path:'CreditRepaymentOverallReport', canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_repayment_overall_report"],redirectTo:'home'}},component:CreditRepaymentOverallReportComponent},
      {path:'AssignmentReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["assignment_report"],redirectTo:'home'}},component:AssignmentReportComponent},
      {path:'HlpInvoicingReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["hlp_invoicing_report"],redirectTo:'home'}},component:HlpInvoicingReportComponent},
      {path:'PaymintSettlementReportComponent',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["paymint_settlement_report"],redirectTo:'home'}},component:PaymintSettlementReportComponent},
      {path:'PaymintSubmerchantReportComponent',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["paymint_submerchant_report"],redirectTo:'home'}},component:PaymintSubmerchantReportComponent},
      {path:'PaymintTransactionReportComponent',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["paymint_transaction_report"],redirectTo:'home'}},component:PaymintTransactionReportComponent},
      {path:'BusinessPrimeSalesReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["business_prime_sales_report"],redirectTo:'home'}},component:BusinessPrimeSalesReportComponent},
      {path:'MasterBusinessReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["master_business_report"],redirectTo:'home'}},component:MasterBussinessReportComponent},
      {path:'MasterBussinessOverallReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["master_business_overall_report"],redirectTo:'home'}},component:MasterBussinessOverallReportComponent},
      {path:'CreditFunnelOverallTrackingReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_funnel_overall_tracking_report"],redirectTo:'home'}},component:CreditFunnelOverallTrackingReportComponent},
      {path:'CreditFunnelTrackingReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_funnel_tracking_report"],redirectTo:'home'}},component:CreditFunnelTrackingReportComponent},
      {path:'EmployeeAttendanceReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["employee_attendance_report"],redirectTo:'home'}},component:EmployeeAttendanceReportComponent},
      {path:'HlpStatusReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["hlp_status_report"],redirectTo:'home'}},component:HlpStatusReportComponent},
      {path:'CreditPreDisbursementReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_pre_disbursement_report"],redirectTo:'home'}},component:CreditPreDisbursementReportComponent},
      {path:'CreditRepaymentReport',canActivate:[NgxPermissionsGuard],data: {permissions:{only:["credit_repayment_report"],redirectTo:'home'}},component:CreditRepaymentReportComponent}
    ]},
  ] },
  {path: 'dashboard',canActivate:[AuthGuard], component: DashboardComponent},
  // {path: '', component:HomeComponent}
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
