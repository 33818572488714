import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-business-plus-report',
  templateUrl: './credit-business-plus-report.component.html',
  styleUrls: ['./credit-business-plus-report.component.scss']
})
export class CreditBusinessPlusReportComponent implements OnInit {

  config:any
  name='Master Business 365 Plus Report'

  ngOnInit(): void {
    this.config = [
      { header: 'Sr No', field: 'sno' },
      { header: 'Loan UID', field: 'loan_uid' },
      { header: 'Lead ID', field: 'lead_id' },
      { header: 'Customer UCIC', field: 'customer_ucic' },
      { header: 'Customer Name', field: 'customer_name' },
      { header: 'Customer DOB', field: 'customer_dob' },
      { header: 'Customer Gender', field: 'customer_gender' },
      { header: 'Customer Mobile', field: 'customer_mobile' },
      { header: 'Partner', field: 'partner' },
      { header: 'Sub Partner', field: 'sub_partner' },
      { header: 'Product Type', field: 'product_type' },
      { header: 'Product Code', field: 'product_code' },
      { header: 'Lender Type', field: 'lender_type' },
      { header: 'Loan Amount', field: 'loan_amount' },
      { header: 'Net Disbursed', field: 'net_disbursed' },
      { header: 'Disbursement Date', field: 'disbursement_date' },
      { header: 'Installment Amount', field: 'installment_amount' },
      { header: 'Tenure', field: 'tenure' },
      { header: 'Total Installment', field: 'total_installment' },
      { header: 'Total Penalty Due', field: 'total_penalty_due' },
      { header: 'Total Interest Due', field: 'total_interest_due' },
      { header: 'Total Principal Due', field: 'total_principal_due' },
      { header: 'Total Amount Due', field: 'total_amount_due' },
      { header: 'Installment Due', field: 'installment_due' },
      { header: 'Last Paid', field: 'last_paid' },
      { header: 'Penalty Paid', field: 'penalty_paid' },
      { header: 'Interest Paid', field: 'interest_paid' },
      { header: 'Principal Paid', field: 'principal_paid' },
      { header: 'Amount Paid', field: 'amount_paid' },
      { header: 'Installment Paid', field: 'installment_paid' },
      { header: 'Payment Mode', field: 'payment_mode' },
      { header: 'Penalty Overdue', field: 'penalty_overdue' },
      { header: 'Interest Overdue', field: 'interest_overdue' },
      { header: 'Principal Overdue', field: 'principal_overdue' },
      { header: 'Total Overdue', field: 'total_overdue' },
      { header: 'Status', field: 'status' },
      { header: 'Center', field: 'center' },
      { header: 'Cluster', field: 'cluster' },
      { header: 'Due Date', field: 'due_date' },
      { header: 'DPD Days', field: 'dpd_days' },
      { header: 'DPD Bucket', field: 'dpd_bucket' },
      { header: 'Loan Status', field: 'loan_status' },
      { header: 'Report Date', field: 'report_date' }
    ];

  }



}






