import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-archive-report',
  templateUrl: './archive-report.component.html',
  styleUrls: ['./archive-report.component.scss'],

})
export class ArchiveReportComponent implements OnInit{
  whitelistedReports: { [key: string]: string } = {};
  fetchedList: any[] = [];
  currentPage: number = 1;
  totalPages: number = 10; // Assume you know the total number of pages from the API response
  pageNumbers: number[] = [];
  selectedReport: string = '';
  selectedDate: string = ''; // Initialize selectedDate as an empty string


  constructor(private http: HttpClient) { }
  private apiBaseUrl = environment.apiUrl;

  ngOnInit(): void {
    this.getWhitelistedReports();
  }

  getWhitelistedReports(): void {
    this.http.get<any>(this.apiBaseUrl + 'archive_whitelisted_reports_list', this.esHeaders() )
      .subscribe(
        (data: { response: { [key: string]: string } }) => {
          if (data && data.response && Object.keys(data.response).length > 0) {
            this.whitelistedReports = data.response;
          }
          else {
            console.error('Invalid API response format.');
          }
        },
        (error) => {
          console.error('Error fetching whitelisted reports:', error);
          // Handle error here, e.g., display an error message to the user
        }
      );
  }

  calculatePageNumbers(): void {
    this.pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getArchiveReports(this.selectedReport, this.currentPage, this.selectedDate);
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getArchiveReports(this.selectedReport, this.currentPage, this.selectedDate);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages && this.fetchedList.length >= 10) {
      this.currentPage++;
      this.getArchiveReports(this.selectedReport, this.currentPage, this.selectedDate);
    }
  }

  onSelectionChange(event: any) {
    //console.log('eventttt', event)
    //console.log('value', event.value)
    this.selectedReport = event.value;
    this.getArchiveReports(this.selectedReport, this.currentPage, this.selectedDate);
  }



  getArchiveReports(selectedReport: string, currentPage: number, selectedDate: string): void {
    const url = `${this.apiBaseUrl}archive_list/${selectedReport}?page_number=${currentPage}&report_date=${selectedDate}`;

    this.http.get<any[]>(url, this.esHeaders())
      .subscribe(
        (data: any) => {
          if (data && data.response && Array.isArray(data.response)) {
            this.fetchedList = data.response;
          } else {
            console.error('Invalid API response format.');
          }
        },
        (error) => {
          console.error('Error fetching archive reports:', error);
        }
      );
  }

  esHeaders() {
    let headers = new HttpHeaders();
    let accessToken: any = localStorage.getItem('refereshToken'); // Assuming refreshToken is the correct token name
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return {headers};
  }
  onDateSelected(event: MatDatepickerInputEvent<Date>): void {
    //console.log(event.value)
    if (event.value) {
      //console.log(event.value)
      const selectedDate: string = event.value.toISOString().split('T')[0]; // Format the selected date
      //console.log(selectedDate)
      this.getArchiveReports(this.selectedReport, this.currentPage, selectedDate);
    }
  }

  downloadReport(downloadUrl: string) {
    window.open(downloadUrl, '_blank');
  }
}
