import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-bank-details',
  templateUrl: './insurance-bank-details.component.html',
  styleUrls: ['./insurance-bank-details.component.scss']
})
export class InsuranceBankDetailsComponent implements OnInit{

  config:any
  name='Insurance Bank Details Report'
  ngOnInit(): void {
    this.config=[
      {
        header:'Loan UID',
        field:'id'
      },
      {
        header:'Form Type',
        field:'form_type'
      },
      {
        header:'Form Type',
        field:'form_type'
      },
      {
        header:'Customer UCIC',
        field:'customer_ucic'
      },
      {
        header:'Customer Type',
        field:'customer_type'
      },
      {
        header:'Insurance Product',
        field:'insurance_product'
      },
      {
        header:'Lead Date',
        field:'lead_date'
      },
      {
        header:'Employee Id',
        field:'employee_id'
      },
      {
        header:'Employee Name',
        field:'loanofficername'
      },
      {
        header:'Center',
        field:'center'
      },
      {
        header:'Cluster',
        field:'cluster'
      },
      {
        header:'Customer Name',
        field:'customer_name'
      },
      {
        header:'Mobile',
        field:'mobile'
      },
      {
        header:'DOB',
        field:'date_of_birth'
      },
      {
        header:'Age',
        field:'age'
      },
      {
        header:'Gender',
        field:'gender'
      },
      {
        header:'Premium Amount',
        field:'premium_amount'
      },
      {
        header:'PA Mode',
        field:'pa_mode'
      },
      {
        header:'Payment Date',
        field:'payment_date'
      },
      {
        header:'Payment Receipt No',
        field:'payment_receipt_no'
      },
      {
        header:'Payment Receipt',
        field:'payment_receipt'
      },
      {
        header:'Pincode',
        field:'pincode'
      },
      {
        header:'Policy Expiry Date',
        field:'policy_expiry_date'
      },
      {
        header:'Referred By',
        field:'referred_by_arth_hlp'
      },
      {
        header:'HLP Id',
        field:'cp_id'
      },
      {
        header:'HLP Name',
        field:'hlp_name'
      },
      {
        header:'Assigned Employee',
        field:'assinged_employee'
      },
      {
        header:'HLP referred Manager',
        field:'hlp_referred_manager'
      },
      {
        header:'ID Number',
        field:'id_number'
      },
      {
        header:'Mother Name',
        field:'mother_name'
      },
      {
        header:'Address Line 1',
        field:'address_line_1'
      },
      {
        header:'Address line 2',
        field:'address_line_2'
      },
      {
        header:'Home Sub-District',
        field:'home_sub_district'
      },
      {
        header:'Home District',
        field:'home_district'
      },
      {
        header:'home State',
        field:'home_state'
      },
      {
        header:'Tenure',
        field:'tenure'
      },
      {
        header:'Occupation',
        field:'occupation'
      },
      {
        header:'Health Family Type',
        field:'health_family_type'
      },
      {
        header:'Nominee Name',
        field:'nominee_name'
      },
      {
        header:'Nominee Mobile',
        field:'nominee_mobile'
      },
      {
        header:'Nominee DOB',
        field:'nominee_dob'
      },
      {
        header:'Nominee Relation',
        field:'nominee_relation'
      },
      {
        header:'Email',
        field:'email'
      },
      {
        header:'payment Status',
        field:'payment_status'
      },
      {
        header:'IFSC',
        field:'ifsc'
      },
      {
        header:'Bank Name',
        field:'bank_name'
      },
      {
        header:'Account Number',
        field:'saving_account_number'
      },
      {
        header:'Bank Account Name',
        field:'bank_account_name'
      },
      {
        header:'Partner',
        field:'partner_type'
      },
       {
        header:'Product Type',
        field:'product_type'
      },
      {
        header:'Lender Type',
        field:'lender_type'
      }
    ]}

}

