import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-funnel-tracking-report',
  templateUrl: './credit-funnel-tracking-report.component.html',
  styleUrls: ['./credit-funnel-tracking-report.component.scss']
})
export class CreditFunnelTrackingReportComponent {
  name='Credit Funnel Tracking Report'
  reportInformation  = 'Provide the Monthly funnel data'
}
