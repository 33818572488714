import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-reconciliation-report',
  templateUrl: './credit-reconciliation-report.component.html',
  styleUrls: ['./credit-reconciliation-report.component.scss']
})

export class CreditReconciliationReportComponent {
  name='Credit Reconciliation Report'
  reportInformation  = 'Report provides repayment information of customers';


}





