import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demand-sheet-report',
  templateUrl: './demand-sheet-report.component.html',
  styleUrls: ['./demand-sheet-report.component.scss']
})
export class DemandSheetReportComponent implements OnInit {
  config:any
  name='Demand Sheet Report'
  
  ngOnInit(): void {

    this.config=[{
      header:'Loan Uid',
      field:'loan_uid'
    },
    {
      header:'Customer Ucic',
      field:'customer_code'
    },
    {
      header:'Lead ID',
      field:'lead_id'
    },
    {
      header:'Customer Name',
      field:'name'
    },
    {
      header:'DOB',
      field:'dob'
    },
    {
      header:'Gender',
      field:'gender'
    },
    {
      header:'Mobile',
      field:'mobile'
    },
    {
      header:'Due Date',
      field:'due_date'
    },
    // {
    //   header:'Employee Name',
    //   field:'clc_name'
    // },
    {
      header:'Loan Amount',
      field:'loan_amount'
    },
    {
      header:'Product Purpose',
      field:'product_purpose'
    },
    {
      header:'Product Code',
      field:'product_code'
    },
    // {
    //   header:'Installment No',
    //   field:'installment_no'
    // },
    {
      header:'EMI',
      field:'emi'
    },
    {
      header:'Installment Paid',
      field:'installment_paid'
    },
    {
      header:'Installment Number',
      field:'instalment_number'
    },
    {
      header:'Interest Due',
      field:'interest_due'
    },
    {
      header:'Last Paid',
      field:'last_paid'
    },
    {
      header:'Net Disbursed',
      field:'net_disbursed'
    },
    // {
    //   header:'Interest Share',
    //   field:'interest_share'
    // },
    // {
    //   header:'Principal Share',
    //   field:'principal_share'
    // },
    // {
    //   header:'Penalty Charges',
    //   field:'penalty_charges'
    // },
    {
      header:'Total Due',
      field:'total_due'
    },
    {
      header:'Disbursement Date',
      field:'disbursement_date'
    },
    // {
    //   header:'Enach Date',
    //   field:'enach_date'
    // },
    // {
    //   header:'Customer Id',
    //   field:'customer_id'
    // },
    // {
    //   header:'Token Id',
    //   field:'token_id'
    // },
    // {
    //   header:'Paid Date',
    //   field:'paid_date'
    // },
    // {
    //   header:'Status',
    //   field:'status'
    // },
    {
      header:'DPD Days',
      field:'dpd_days'
    },
    {
      header:'DPD Bucket',
      field:'dpd_bucket'
    },
    {
      header:'Center',
      field:'center'
    },
    {
      header:'Cluster',
      field:'cluster'
    },
    {
      header:'Loan Status',
      field:'loan_status'
    },
    {
      header:'Report Date',
      field:'report_date'
    }, 
    {
      header:'Principal Due',
      field:'principal_due'
    },
    {
      header:'Penalty Due',
      field:'penalty_due'
    },
    {
      header:'Partner ',
      field:'partner_type'
    },
    {
      header:'Product Type',
      field:'product_type'
    },
    {
      header:'Lender Type',
      field:'lender_type'
    }
  ]
    
  }

}
