import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FetchReport } from 'src/app/services/reports.service';
import { CreditDueDemandReportComponent } from '../common-module/credit-due-demand-report/credit-due-demand-report.component';
import { CustomerDetailReportComponent } from '../customer-module/customer-detail-report/customer-detail-report.component';
import { MasterBussinessOverallReportComponent } from './master-bussiness-overall-report/master-bussiness-overall-report.component';
import { CreditFunnelOverallTrackingReportComponent } from './credit-funnel-overall-tracking-report/credit-funnel-overall-tracking-report.component';


@Component({
  selector: 'app-finance-module',
  templateUrl: './finance-module.component.html',
  styleUrls: ['./finance-module.component.scss'],
  entryComponents: [CustomerDetailReportComponent,CreditDueDemandReportComponent,CreditFunnelOverallTrackingReportComponent,MasterBussinessOverallReportComponent]
})
export class FinanceModuleComponent implements OnInit {

  reports: any = {
    'LTD MIS': { name: 'ltd_mis', permission: ['hide'] },
    'Credit Repayment Report': { name: 'loan_collection_report', permission: ['loan_collection_report'] },
     'Master Business OverAll Report':{name: 'master_business_overall_report',permission:['master_data_report']},
     'Credit Funnel OverAll Tracking Report':{name: 'credit_funnel_overall_tracking_report',permission:['credit_funnel_overall_tracking_report']},
     'Customer Detail Master Report':{name:'customer_details_report',permission:['customer_details_report']},
  };

  constructor(private router: Router,
    private activatdRoute: ActivatedRoute,
    private fetchReport: FetchReport) {

  }

  ngOnInit(): void {

  }

  reportClicked(data: any) {

    // this.router.navigate(['/home/mis/FetchReport'],{ queryParams: {module:'Finance_Module',report:this.reports[data].name}})
    if (this.reports[data].name === 'disbursement_details_report')
      this.router.navigate(['/home/mis/DisbursementDetailsReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'insurance_bank_details_report')
      this.router.navigate(['/home/mis/InsuranceBankDetailsReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'loan_collection_report')
      this.router.navigate(['/home/mis/LoanCollectionReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'pre_demand_monthly_report')
      this.router.navigate(['/home/mis/PreDemandMonthlyReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_reconciliation_report')
      this.router.navigate(['/home/mis/CreditReconciliationReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'arth_static_lms_report')
      this.router.navigate(['/home/mis/ArthStaticLmsReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'kinara_business_details_report')
      this.router.navigate(['/home/mis/KinaraBusinessDetailsReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'kinara_customer_details_report')
      this.router.navigate(['/home/mis/KinaraCustomerDetailsReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'kinara_disbursement_details_report')
      this.router.navigate(['/home/mis/KinaraDisbursementDetailsReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
    else if (this.reports[data].name === 'master_business_overall_report')
      this.router.navigate(['/home/mis/MasterBussinessOverallReport'], { queryParams: { module: 'business', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_funnel_overall_tracking_report')
      this.router.navigate(['/home/mis/CreditFunnelOverallTrackingReport'], { queryParams: { module: 'business', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_due_demand_report')
      this.router.navigate(['/home/mis/CreditDueDemandReport'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })
      else if (this.reports[data].name === 'customer_details_report')
      this.router.navigate(['/home/mis/customer_details_report'], { queryParams: { module: 'Corporate_Finance', report: this.reports[data].name } })



    else
      this.fetchReport.downloadLTD().subscribe(
        (response) => {
          const a = document.createElement("a");
          a.href = "data:text/xlsx," + response;
          let filename = "LTD_Report";
          a.setAttribute("download", filename + ".xlsx");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
    {

    }



    // this.router.navigate(['/home/roles/create-new-role'],{ queryParams: {edit:true}})

  }
  goBack() {

    window.history.back()
  }
}
