import { Component } from '@angular/core';

@Component({
  selector: 'app-paymint-transaction-report',
  templateUrl: './paymint-transaction-report.component.html',
  styleUrls: ['./paymint-transaction-report.component.scss']
})
export class PaymintTransactionReportComponent {
  name='Paymint Transaction Report'
  reportInformation  = 'Transaction Details'
}
