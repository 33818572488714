import { Component } from '@angular/core';

@Component({
  selector: 'app-employee-attendance-report',
  templateUrl: './employee-attendance-report.component.html',
  styleUrls: ['./employee-attendance-report.component.scss']
})
export class EmployeeAttendanceReportComponent {
  name='Employee Attendance Report'
  reportInformation  = 'Attendance of Employee.'
}
