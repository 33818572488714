import { Component } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-partner-module',
  templateUrl: './partner-module.component.html',
  styleUrls: ['./partner-module.component.scss']
})
export class PartnerModuleComponent {
  reports:any={'HLP Onboarding':{name:'hlp_report'},'HLP Hotlead View' :{name:'hlp_hotlead_report'}}

  constructor(private router:Router){
  
  }
  reportClicked(data:any){
  this.router.navigate([`home/mis/${this.reports[data].name}`],{ queryParams: {module:'Partner_Module',report:this.reports[data].name}})

  }

  goBack(){
    window.history.back()
  }
}
