import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FetchReport } from 'src/app/services/reports.service';
import { LoadPermissionService } from 'src/app/services/load-permission.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table'; // Import MatTableDataSource

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit, OnDestroy {
  @Input() reportSelected: string | null = null;
  @Input() reportSelected_tem: string | null = null;
  @Input() reportInformation: string | null = null;
  reportLastGeneratedAt: Date;
  repotLength: number;

  isGeneratedToday(date: Date): boolean {
    const parsedDate = new Date(date);

    const today = new Date();
    return parsedDate.getDate() === today.getDate() &&
           parsedDate.getMonth() === today.getMonth() &&
           parsedDate.getFullYear() === today.getFullYear();
    }


  convertToIST(dateString: string): string {
    const parsedDate = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    const timeInIST = parsedDate.toLocaleString('en-IN', options);

    return `${timeInIST} IST`;
}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  currentReport: string = '';
  currentModule: string = '';
  totalRecords: number = 100;
  reportTime:string  ='';
  reports: any[] = [];
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  private unsubscribe$ = new Subject<void>();


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fetchReport: FetchReport,
    private loadPermission: LoadPermissionService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(qParam => {
        const reportParam = qParam.get('report');
        this.reportSelected_tem = reportParam;

        this.currentReport = reportParam
          ? reportParam.split('_').map(a => a.charAt(0).toUpperCase() + a.slice(1)).join(" ")
          : '';

        const moduleParam = qParam.get('module');
        this.currentModule = moduleParam
          ? moduleParam.split('_').join(' ')
          : '';

        if (this.reportSelected_tem) {
          this.loadReportData(this.reportSelected_tem);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  formatReportName(report: string | null): string {
    if (!report) return '';
    return report.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  goBack() {
    window.history.back();
  }

  downloadReport() {
    if (!this.reportSelected_tem) {
      this.snackBar.open('No report selected!', 'Close', {
        duration: 4000,
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        panelClass: ['snack-bar']
      });
      return;
    }
    let filters: any = {}
    let data: any = {}
    let report = this.reportSelected_tem
    filters[report] = data

    let module = this.currentModule.split(" ")[0].toLowerCase()

    this.fetchReport.downloadXlsx(module, this.reportSelected_tem, {})
      .subscribe((response: any) => {
        try {
          const responseData = this.parseJsonResponse(response);
          const s3Link = responseData?.response;

          if (s3Link) {
            this.downloadFileFromS3(s3Link, `${this.reportSelected_tem}.xlsx`);
            this.showSnackBar(`Downloading: ${this.formatReportName(this.reportSelected_tem)}`, 'success');
          } else {
            console.error('S3 link not found in the response');
            this.showSnackBar('Failed to get the download link', 'error');
          }
        } catch (e) {
          console.error('Error parsing response data:', e);
          this.showSnackBar('Error parsing download data', 'error');
        }
        console.log("Download process completed.", response);
      },
      error => {
        console.error("Error downloading report:", error);
        this.showSnackBar('Error downloading report!', 'error');
      }
    );
  }


private loadReportData(report: string,page: number = 1, pageSize: number = 5) {
    const params = {
      page: page,
      page_size: pageSize
  };

    this.fetchReport.getReportData(this.reportSelected_tem,this.currentModule.split(" ")[0].toLowerCase(),params).subscribe(data => {
        this.reports = data as any[];
        this.dataSource.data = this.reports;
        if (this.reports.length > 0) {
            this.displayedColumns = Object.keys(this.reports[0]);
        }
    }, error => {
        this.snackBar.open('Error loading report data!', 'Close', {
            duration: 4000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: ['snack-bar']
        });
        console.error("Error loading report data: ", error);
    });

    this.fetchReport.getReportLastDate(this.reportSelected_tem).subscribe(data => {
      this.reportLastGeneratedAt = Object.values(data)[0][0];
      this.reportTime= this.convertToIST(Object.values(data)[0])
    })


    this.fetchReport.getReportLength(this.reportSelected_tem).subscribe(data => {
      this.totalRecords = data;
    })


}

private showSnackBar(message: string, type: 'success' | 'error') {
  this.snackBar.open(message, 'Close', {
    duration: 4000,
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    panelClass: type === 'success' ? ['snack-bar'] : ['snack-bar-error']
  });
}

private parseJsonResponse(response: any): any {
  try {
    return JSON.parse(response);
  } catch (e) {
    console.error('Invalid JSON response:', response);
    throw new Error('Failed to parse JSON response');
  }
}


//  Temporary download function
downloadFileFromS3(s3Link: string, filename: string){
  fetch(s3Link)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}


onPageChange(event: any) {
  this.loadReportData(this.reportSelected_tem || '', event.pageIndex + 1, event.pageSize);
}

}
