<div *ngIf="isLoading" class="loading-container">
  <img src="assets/icons/ARTHKOSH1.gif" alt="Loading" class="loading-gif">
</div>

<div *ngIf="!isLoading && !errorMessage" class="dashboard-container">
  <div class="cards">
    <div class="wave">
      <div class="wave wave2"></div>
    </div>

    <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(0) }">
      <mat-card-header class="card-header">
        <h3><strong><mat-card-title> {{
            getCardByIndex(0).title
          }}</mat-card-title></strong></h3>
      </mat-card-header>
      <mat-card-content>
        <div class="chart-container">
          <p class="total-value">{{ getValueByLabel(0, "Total") }}</p>
        </div>
        <div class="value-list">
          <div *ngFor="let item of getCardByIndex(0)?.values">
            <strong>{{ item.label }}:</strong> {{ item.value }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(1) }">
      <mat-card-header class="card-header">
        <strong><mat-card-title>{{
            getCardByIndex(1).title
          }}</mat-card-title></strong
        >
      </mat-card-header>
      <mat-card-content>
        <div class="chart-container">
          <div class="loan_form_chart" id = "loan_form_chart"></div>
          <p class="total-value">{{ getValueByLabel(1, "Complete") }}</p>
        </div>
        <div class="value-list">
          <div *ngFor="let item of getCardByIndex(1)?.values?.slice(1)">
            <strong>{{ item.label }}:</strong> {{ item.value }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(2) }">
      <mat-card-header class="card-header">
        <strong
          ><mat-card-title>{{
            getCardByIndex(2).title
          }}</mat-card-title></strong
        >
      </mat-card-header>
      <mat-card-content>
        <div class="chart-container">
          <p class="total-value">{{ getValueByLabel(2, "Total") }}</p>
        </div>
        <div class="value-list">
          <div *ngFor="let item of getCardByIndex(2)?.values">
            <strong>{{ item.label }}:</strong> {{ item.value }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(3) }">
      <mat-card-header class="card-header">
        <strong
          ><mat-card-title>{{
            getCardByIndex(3).title
          }}</mat-card-title></strong
        >
      </mat-card-header>
      <mat-card-content>
        <div class="chart-container">
          <div class="loan_acceptance_chart"></div>
          <p class="total-value">{{ getValueByLabel(3, "Total") }}</p>
        </div>
        <div class="value-list">
          <div *ngFor="let item of getCardByIndex(3)?.values?.slice(1)">
            <strong>{{ item.label }}:</strong>  {{ item.value }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(4) }">
      <mat-card-header class="card-header">
        <strong
          ><mat-card-title>{{
            getCardByIndex(4).title
          }}</mat-card-title></strong
        >
      </mat-card-header>
      <mat-card-content>
        <div class="chart-container">
          <p class="total-value">{{ getValueByLabel(4, "Total") }}</p>
        </div>
        <div class="value-list">
          <div *ngFor="let item of getCardByIndex(4)?.values">
            <strong>{{ item.label }}:</strong> {{ item.value }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" [ngStyle]="{ 'background-color': getCardColor(5) }">
      <mat-card-header class="card-header">
        <strong
          ><mat-card-title>{{
            getCardByIndex(5).title
          }}</mat-card-title></strong
        >
      </mat-card-header>
      <mat-card-content>
        <div class="chart-container">
          <p class="total-value">{{ getValueByLabel(5, "Total") }}</p>
        </div>
        <div class="value-list">
          <div *ngFor="let item of getCardByIndex(5)?.values">
            <strong>{{ item.label }}:</strong> {{ item.value }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="monthly_chart">
    <div class="area_chart"></div>
    <div class="total_card">
      <div *ngFor="let item of summedData; let i = index">
        <mat-card
          class="card"
          [ngStyle]="{ 'background-color': getCardColor(i) }"
        >
          <mat-card-content>
            <h4>{{ item.name }}: <b> {{ item.sum }}</b></h4>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage" class="error-container">
    <p>{{ errorMessage }}</p>
  </div>
</div>
