import { Component } from '@angular/core';

@Component({
  selector: 'app-paymint-settlement-report',
  templateUrl: './paymint-settlement-report.component.html',
  styleUrls: ['./paymint-settlement-report.component.scss']
})
export class PaymintSettlementReportComponent {
  name='Paymint Settlement Report'
  reportInformation  = 'Settlement Data of payments'
}
