import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-detail-report',
  templateUrl: './customer-detail-report.component.html',
  styleUrls: ['./customer-detail-report.component.scss']
})
export class CustomerDetailReportComponent implements OnInit{

  config:any
   
  name='Customer Detail Master Report'

  ngOnInit(): void {

    this.config=[
    {
        header: 'Sno',
        field: 'sno'
    },
    {
        header: 'Lead ID',
        field: 'lead_id'
    },
    {
        header: 'Customer UCIC',
        field: 'customer_ucic'
    },
    {
        header: 'Customer Name',
        field: 'customer_name'
    },
    {
        header: 'Customer Gender',
        field: 'customer_gender'
    },
    {
        header: 'Customer Mobile',
        field: 'customer_mobile'
    },
    {
        header: 'Customer Alternate Mobile',
        field: 'customer_alternate_mobile'
    },
    {
        header: 'Branch',
        field: 'branch'
    },
    {
        header: 'KYC POA Type',
        field: 'kyc_poa_type'
    },
    {
        header: 'KYC POA ID',
        field: 'kyc_poa_id'
    },
    {
        header: 'Customer Mother Name',
        field: 'customer_mother_name'
    },
    {
        header: 'Cluster',
        field: 'cluster'
    },
    {
        header: 'Customer Email',
        field: 'customer_email'
    },
    {
        header: 'Customer Address Line 1',
        field: 'customer_address_line_1'
    },
    {
        header: 'Customer Address Line 2',
        field: 'customer_address_line_2'
    },
    {
        header: 'Customer Home Pincode',
        field: 'customer_home_pincode'
    },
    {
        header: 'Customer Home Sub City',
        field: 'customer_home_sub_city'
    },
    {
        header: 'Customer Home City',
        field: 'customer_home_city'
    },
    {
        header: 'Customer Home State',
        field: 'customer_home_state'
    },
    {
        header: 'Customer Shop Name',
        field: 'customer_shop_name'
    },
    {
        header: 'Is Similar Address',
        field: 'is_similar_address'
    },
    {
        header: 'Customer Shop Address 1',
        field: 'customer_shop_address_1'
    },
    {
        header: 'Customer Shop Address 2',
        field: 'customer_shop_address_2'
    },
    {
        header: 'Customer Shop Pincode',
        field: 'customer_shop_pincode'
    },
    {
        header: 'Customer Shop Sub City',
        field: 'customer_shop_sub_city'
    },
    {
        header: 'Customer Shop City',
        field: 'customer_shop_city'
    },
    {
        header: 'Customer Shop State',
        field: 'customer_shop_state'
    },
    {
        header: 'Customer Type',
        field: 'customer_type'
    },
    {
        header: 'Customer Home Type',
        field: 'customer_home_type'
    },
    {
        header: 'Current Residence Since',
        field: 'current_residence_since'
    },
    {
        header: 'Distance Home to Shop',
        field: 'distance_home_to_shop'
    },
    {
        header: 'Shop Road Type',
        field: 'shop_road_type'
    },
    {
        header: 'Education Level',
        field: 'education_level'
    },
    {
        header: 'Skill Certificates',
        field: 'skill_certificates'
    },
    {
        header: 'Toilet at Home',
        field: 'toilet_at_home'
    },
    {
        header: 'Phone Usage',
        field: 'phone_usage'
    },
    {
        header: 'Marital Status',
        field: 'marital_status'
    },
    {
        header: 'Vehicle Type',
        field: 'vehicle_type'
    },
    {
        header: 'Self WhatsApp Usage',
        field: 'self_whatsapp_usage'
    },
    {
        header: 'Criminal Legal Status',
        field: 'criminal_legal_status'
    },
    {
        header: 'Health Concern',
        field: 'health_concern'
    },
    {
        header: 'Co-Borrower Name',
        field: 'co_borrower_name'
    },
    {
        header: 'Relation with Co-borrow',
        field: 'relation_with_coborrow'
    },
    {
        header: 'Co-borrower Occupation',
        field: 'coborrower_occupation'
    },
    {
        header: 'Relative Name',
        field: 'relative_name'
    },
    {
        header: 'Relative Mobile',
        field: 'relative_mobile'
    },
    {
        header: 'Relative Address',
        field: 'relative_address'
    },
    {
        header: 'Customer Business Name',
        field: 'customer_business_name'
    },
    {
        header: 'Customer Business Type',
        field: 'customer_business_type'
    },
    {
        header: 'Customer Business Environment',
        field: 'customer_business_environment'
    },
    {
        header: 'Sub Occupation',
        field: 'sub_occupation'
    },
    {
        header: 'Customer Sub Occupation',
        field: 'customer_sub_occupation'
    },
    {
        header: 'Customer Business Origin',
        field: 'customer_business_origin'
    },
    {
        header: 'Customer Business Since',
        field: 'customer_business_since'
    },
    {
        header: 'Purpose of Loan',
        field: 'purpose_of_loan'
    },
    {
        header: 'Monthly Business Income',
        field: 'monthly_business_income'
    },
    {
        header: 'Monthly Income from Other Source',
        field: 'monthly_income_from_other_source'
    },
    {
        header: 'Family Total Income',
        field: 'family_total_income'
    },
    {
        header: 'Education Monthly Expense',
        field: 'education_monthly_expense'
    },
    {
        header: 'Medical Monthly Expense',
        field: 'medical_monthly_expense'
    },
    {
        header: 'Existing Insurance',
        field: 'existing_insurance'
    },
    {
        header: 'Insurance Frequency',
        field: 'insurance_frequency'
    },
    {
        header: 'Insurance Premium',
        field: 'insurance_premium'
    },
    {
        header: 'Existing Loan',
        field: 'existing_loan'
    },
    {
        header: 'Shop Type',
        field: 'shop_type'
    },
    {
        header: 'Monthly Recharge',
        field: 'monthly_recharge'
    },
    {
        header: 'Monthly Electricity Bill',
        field: 'monthly_electricity_bill'
    },
    {
        header: 'Pensioner at Home',
        field: 'pensioner_at_home'
    },
    {
        header: 'Monthly Pension',
        field: 'monthly_pension'
    },
    {
        header: 'Monthly Saving',
        field: 'monthly_saving'
    },
    {
        header: 'Informal Borrowings',
        field: 'informal_borrowings'
    },
    {
        header: 'Current Bank Balance',
        field: 'current_bank_balance'
    },
    {
        header: 'IFSC',
        field: 'ifsc'
    },
    {
        header: 'Bank Name',
        field: 'bank_name'
    },
    {
        header: 'Beneficiary Account Number',
        field: 'beneficiary_account_number'
    },
    {
        header: 'Beneficiary Account Name',
        field: 'beneficiary_account_name'
    },
    {
        header: 'Update Timestamp',
        field: 'update_ts'
    }
    
    //   {
    //     header:'Lead ID',
    //     field:'lead_id'
    //   },
    //   {
    //     header:'UCIC',
    //     field:'ucic'
    //   },
    //   {
    //     header:'Customer Onboard Form Type',
    //     field:'customer_onboard_form_type'
    //   },
    // {
    //   header:'Mobile',
    //   field:'mobile'
    // },
    // {
    //   header:'Alternate Mobile',
    //   field:'alternate_mobile'
    // },
    // {
    //   header:'Center',
    //   field:'branch'
    // },
    // {
    //   header:'KYC Poa Type',
    //   field:'kyc_poa_type'
    // },
    // {
    //   header:'Address Proof',
    //   field:'address_proof'
    // },
    // {
    //   header:'Cluster',
    //   field:'cluster'
    // },
   
  
    // {
    //   header:'Customer Name',
    //   field:'customer_name'
    // },
    // {
    //   header:'Mother Name',
    //   field:'mother_name'
    // },
    // {
    //   header:'Gender',
    //   field:'gender'
    // },
    // {
    //   header:'Email',
    //   field:'email'
    // },
    // {
    //   header:'Address Line 1',
    //   field:'address_line_1'
    // },
    // {
    //   header:'Address Line 2',
    //   field:'address_line_2'
    // },
    // {
    //   header:'Pincode',
    //   field:'home_pincode'
    // },
    // {
    //   header:'Home Sub District',
    //   field:'home_sub_district'
    // },
    // {
    //   header:'Home State',
    //   field:'home_state'
    // },
    // {
    //   header:'Shop Name',
    //   field:'shop_name'
    // },
    // {
    //   header:'Is Address Same',
    //   field:'is_similar_address'
    // },
    // {
    //   header:'Shop Address 1',
    //   field:'shop_address_1'
    // },
    // {
    //   header:'Shop Address 2',
    //   field:'shop_address_2'
    // },
    // {
    //   header:'Shop Pincode',
    //   field:'shop_pincode'
    // },
    // {
    //   header:'Shop Sub District',
    //   field:'shop_sub_district'
    // },
    // {
    //   header:'Shop District',
    //   field:'shop_district'
    // },
    // {
    //   header:'Shop State',
    //   field:'shop_state'
    // },
    // {
    //   header:'Customer type',
    //   field:'customer_type'
    // },
    // {
    //   header:'Home Type',
    //   field:'home_type'
    // },
    // {
    //   header:'Current Residence Since',
    //   field:'current_residence_since'
    // },
    // {
    //   header:'Distance(Home To Shop)',
    //   field:'distance_home_to_shop'
    // },
    // {
    //   header:'Shop Road Type',
    //   field:'shop_road_type'
    // },
    // {
    //   header:'Education level',
    //   field:'education_level'
    // },
    // {
    //   header:'Skill Certificates',
    //   field:'skill_certificates'
    // },
    // {
    //   header:'Toilet at Home',
    //   field:'toilet_at_home'
    // },
    // {
    //   header:'Phone Usage',
    //   field:'phone_usage'
    // },
    // {
    //   header:'Marital Status',
    //   field:'marital_status'
    // },
    // {
    //   header:'Vehicle Type',
    //   field:'vehicle_type'
    // },
    // {
    //   header:'Self Whatsapp Usage',
    //   field:'self_whatsapp_usage'
    // },
    // {
    //   header:'Criminal Legal Status',
    //   field:'criminal_legal_status'
    // },
    // {
    //   header:'Health Concern',
    //   field:'health_concern'
    // },
    // {
    //   header:'Coborrower Name',
    //   field:'co_borrower_name'
    // },
    // {
    //   header:'Relation with Coborrower',
    //   field:'relation_with_coborrow'
    // },
    // {
    //   header:'Coborrower Occupation',
    //   field:'coborrower_occupation'
    // },
    // {
    //   header:'Relative Name',
    //   field:'relative_name'
    // },
    // {
    //   header:'Relative Mobile',
    //   field:'relative_mobile'
    // },
    // {
    //   header:'Relative Address',
    //   field:'relative_address'
    // },
    // {
    //   header:'Business Name',
    //   field:'bussiness_name'
    // },
    // {
    //   header:'Business Type',
    //   field:'business_type'
    // },
    // {
    //   header:'Business Environment',
    //   field:'business_environment'
    // },
    // {
    //   header:'Sub Occupation',
    //   field:'sub_occupation'
    // },
    // {
    //   header:'Other sub Occupation',
    //   field:'other_sub_occupation'
    // },
    // {
    //   header:'Busines origin',
    //   field:'business_origin'
    // },
    // {
    //   header:'Since(Business)',
    //   field:'business_since'
    // },
    // {
    //   header:'Purpose Of Loan',
    //   field:'purpose_of_loan'
    // },
    // {
    //   header:'Monthly Business income',
    //   field:'monthly_business_income'
    // },
    // {
    //   header:'Monthly Income(Other Sources)',
    //   field:'monthly_income_from_other_source'
    // },
    // {
    //   header:'Family Total Income',
    //   field:'family_total_income'
    // },
    // {
    //   header:'Education Monthly expense',
    //   field:'education_monthly_expense'
    // },
    // {
    //   header:'Medical Monthly Expense',
    //   field:'medical_monthly_expense'
    // },
    // {
    //   header:'Existing Insurance',
    //   field:'existing_insurance'
    // },
    // {
    //   header:'insurance Frequency',
    //   field:'insurance_frequency'
    // },
    // {
    //   header:'Insurance premium',
    //   field:'insurance_premium'
    // },
    // {
    //   header:'Existing Loan',
    //   field:'existing_loan'
    // },
    // {
    //   header:'Shop Type',
    //   field:'shop_type'
    // },
    // {
    //   header:'Monthly Recharge',
    //   field:'monthly_recharge'
    // },
    // {
    //   header:'Monthly Electricity Bill',
    //   field:'monthly_electricity_bill'
    // },
    // {
    //   header:'Pension At home',
    //   field:'pensioner_at_home'
    // },
    // {
    //   header:'Monthly Pension',
    //   field:'monthly_pension'
    // },
    // {
    //   header:'monthly Saving',
    //   field:'monthly_saving'
    // },
    // {
    //   header:'Informal Borrowing',
    //   field:'informal_borrowings'
    // },
    // {
    //   header:'Current Bank Balance',
    //   field:'current_bank_balance'
    // },
    // {
    //   header:'IFSC',
    //   field:'ifsc'
    // }
    // ,
    // {
    //   header:'Bank Name',
    //   field:'bank_name'
    // },
    // {
    //   header:'Account Number',
    //   field:'saving_account_number'
    // },
    // {
    //   header:'Account Holder Name',
    //   field:'bank_account_name'
    // },
    // {
    //   header:'Update',
    //   field:'update_ts'
    // },
    // {
    //   header:'Product Type',
    //   field:'product_type'
    // },
    // {
    //   header:'Lender Type',
    //   field:'lender_type'
    // }
    // {
    //   header:"Lead Id",
    //   field:"lead_id"
    // },
    // {
    //   header:"Amount Paid",
    //   field:"amount_paid"
    // },
    // {
    //   header:"Customer Code",
    //   field:"customer_code"
    // },
    // {
    //   header:"Customer Name",
    //   field:"name"
    // }, 
    // {
    //   header:"Mobile",
    //   field:"mobile"
    // },
    // {
    //   header:"Disbursement Date",
    //   field:"disb_date"
    // },
    // {
    //   header:"Center",
    //   field:"center"
    // },
    // {
    //   header:"Kyc Poa Type",
    //   field:"kyc_poa_type"
    // },
    // {
    //   header:"Dob",
    //   field:"dob"
    // },
    // {
    //   header:"Cluster",
    //   field:"cluster"
    // },
    // {
    //   header:"DPD Bucket",
    //   field:"dpd_bucket"
    // },
    // {
    //   header:"Dpd Days",
    //   field:"dpd_days"
    // },
    // {
    //   header:"Lead Id",
    //   field:"due_date"
    // },
    // {
    //   header:"Emi",
    //   field:"emi"
    // },
    // {
    //   header:"Installment Paid",
    //   field:"installment_paid"
    // },{
    //   header:"Interest Outstanding",
    //   field:"interest_outstanding"
    // },
    // {
    //   header:"Interest Paid",
    //   field:"interest_paid"
    // },{
    //   header:"Last Paid",
    //   field:"last_paid"
    // },
    // {
    //   header:"Lender Type",
    //   field:"lender_type"
    // },
    // {
    //   header:"Loan Amount",
    //   field:"loan_amount"
    // },
    // {
    //   header:"Loan Status",
    //   field:"loan_status"
    // },
    
    // {
    //   header:"Loan Uid",
    //   field:"loan_uid"
    // },
    // {
    //   header:"Max Installment",
    //   field:"max_installment"
    // }, 
   
    // {
    //   header:"Net Disbursed",
    //   field:"net_disbursed"
    // }, {
    //   header:"Partner",
    //   field:"partner_type"
    // }, {
    //   header:"Penalty Outstanding",
    //   field:"penalty_outstanding"
    // }, {
    //   header:"Penalty Paid",
    //   field:"penalty_paid"
    // }, {
    //   header:"Principal Outstanding",
    //   field:"principal_outstanding"
    // }, {
    //   header:"Principal Paid",
    //   field:"principal_paid"
    // }, {
    //   header:"Product Type",
    //   field:"product_type"
    // }, {
    //   header:"Rank",
    //   field:"rank"
    // }, {
    //   header:"Status",
    //   field:"status"
    // }, {
    //   header:"Total Amopunt",
    //   field:"total_amnount"
    // },
    // {
    //   header:"Total Interest",
    //   field:"total_interest"
    // },
    // {
    //   header:"Total Outstanding",
    //   field:"total_outstanding"
    // },
    // {
    //   header:"Total Penalty",
    //   field:"total_penalty"
    // },
    // {
    //   header:"Total Principal",
    //   field:"total_principal"
    // },
  ]
    
  }

}


// "customer_onboard_form_type",                                                 
// "mobile",                                                                     
// "alternate_mobile",                                                           
// "branch",                                                                     
// "kyc_poa_type",                                                               
// "address_proof",                                                              
// "cluster",                                                                    
// "customer_name",                                                              
// "mother_name",                                                                
// "gender",                                                                     
// "email",
// "address_line_1",
// "address_line_2",
// "home_pincode",
// "home_sub_district",
// "home_district",
// "home_state",
// "shop_name",
// "is_similar_address",
// "shop_address_1",
// "shop_address_2",
// "shop_pincode",
// "shop_sub_district",
// "shop_district",
// "shop_state",
// "customer_type",
// "home_type",
// "current_residence_since",
// "distance_home_to_shop",
// "shop_road_type",
// "education_level",
// "skill_certificates",
// "toilet_at_home",
// "phone_usage",
// "marital_status",
// "vehicle_type",
// "self_whatsapp_usage",
// "criminal_legal_status",
// "health_concern",
// "co_borrower_name",
// "relation_with_coborrow",
// "coborrower_occupation",
// "relative_name",
// "relative_mobile",
// "relative_address",
// "bussiness_name",
// "business_type",
// "business_environment",
// "sub_occupation",
// "other_sub_occupation",
// "business_origin",
// "business_since",
// "purpose_of_loan",
// "monthly_business_income",
// "monthly_income_from_other_source",
// "family_total_income",
// "education_monthly_expense",
// "medical_monthly_expense",
// "existing_insurance",
// "insurance_frequency",
// "insurance_premium",
// "existing_loan",
// "shop_type",
// "monthly_recharge",
// "monthly_electricity_bill",
// "pensioner_at_home",
// "monthly_pension",
// "monthly_saving",
// "informal_borrowings",
// "current_bank_balance",
// "ifsc",
// "bank_name",
// "saving_account_number",
// "bank_account_name",
// "update_ts"]


// amount_paid
// : 
// "0"
// center
// : 
// "Bachhrawan"
// cluster
// : 
// "Raebareli"
// customer_code
// : 
// "ADLNGVFM7"
// disb_date
// : 
// "17-03-2023"
// dob
// : 
// "01-03-1991"
// dpd_bucket
// : 
// "OD31to60"
// dpd_days
// : 
// "31"
// due_date
// : 
// "15-04-2023"
// emi
// : 
// "2925"
// gender
// : 
// "male"
// installment_paid
// : 
// "0"
// interest_outstanding
// : 
// "5096"
// interest_paid
// : 
// "0"
// last_paid
// : 
// "NULL"
// lead_id
// : 
// "3101"
// lender_type
// : 
// "ADL"
// loan_amount
// : 
// "30000"
// loan_status
// : 
// "Active"
// loan_uid
// : 
// "6306099271-88"
// max_installment
// : 
// "12"
// mobile
// : 
// "6306099271"
// name
// : 
// "Shyamu"
// net_disbursed
// : 
// "28723.83"
// partner_type
// : 
// "Arth_HLP"
// penalty_outstanding
// : 
// "250"
// penalty_paid
// : 
// "0"
// principal_outstanding
// : 
// "30001"
// principal_paid
// : 
// "0"
// product_type
// : 
// "Business_Loan"
// rank
// : 
// 1
// status
// : 
// "due"
// total_amnount
// : 
// "35350"
// total_interest
// : 
// "5096"
// total_outstanding
// : 
// "35350"
// total_penalty
// : 
// "250"
// total_principal
// : 
// "30001"