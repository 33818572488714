<!-- <div class="box">
<div class="row-align right-content button-container">
    <button class="center-content role-user-button" routerLink="/home/roles/create-new-role">
        <mat-icon>add</mat-icon>
        <mat-label>Roles</mat-label>
    </button>
</div>

<div>
    <div *ngFor="let i of loopArray" class="margin-layout row-align" >
    <div *ngFor="let itr of [1,2,3,4]"  class="margin-layout row-align role-outside"    >
        
        <span *ngIf="roles[itr+i]" class="role-container" (click)="check(roles[itr+i].name)" >{{roles[itr+i].name}}</span> -->
        
    <!-- <div class="margin-layout">
        <span *ngFor="let i of roles | slice:0:4" routerLink="/home/roles/create-new-role" class="role-container" >{{i}}</span>
    </div> -->
<!-- </div>
</div> -->
    <!-- <div class="margin-layout">
        <span *ngFor="let i of roles | slice:4:8" routerLink="/home/roles/create-new-role" class="role-container" >{{i}}</span>

    </div> -->
<!-- 
</div>
</div> -->


<div class="container-fluid outer-padding">
    <div class="row below-content justify-content-center">
        <div class="d-flex flex-row">
        <label class="heading">Roles</label>
        <button class="center-content role-user-button" routerLink="/home/roles/create-new-role">
            <mat-icon>add</mat-icon>
            <mat-label>Roles</mat-label>
        </button>
    </div>
        <div *ngFor="let role of roles" style="cursor: pointer;" class="col-md-3 p-5 m-3 border module" (click)="check(role.name)">{{role.name | uppercase}}
        </div>
    </div>
</div>