import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, retry,throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })

export class DashboardService {

  private apiBaseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getFunnelDataDashboad2(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'daily_funnel_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getMonthlyFunnelDataDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'monthly_funnel_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }
  getCenterDashboard(): Observable<any>{
    return this.http.get(this.apiBaseUrl+'center_dashboard', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getCustomerProjectionDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'customer_projection_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getRepCustomerProjectionDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'customer_projection_repeate_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getPartnerPerformanceDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'partner_performance_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getNpRpPartnerPerformanceDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'np_rp_partner_performance_dashboard', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getPrimeProjectionDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'prime_projection_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getPrimePlusProjectionDashboad(): Observable<any>{
    return this.http.get<any>(this.apiBaseUrl+'prime_plus_projection_dashboard?funnel=true', this.esHeaders())
    .pipe(
      retry(2), catchError(this.handleError)
    );
  }

  getFunnelDataDashboard() {
    return this.http.get(this.apiBaseUrl + 'funnel_data_dashboard?funnel=true', this.esHeaders());
  }
  getDailyHotspotDashboard() {
    return this.http.get(this.apiBaseUrl + 'daily_funnel_data_dashboard?funnel=true', this.esHeaders());
  }

  getClusterWiseDashbord(params: any){
    return this.http.get(this.apiBaseUrl + `cluster_wise_dashboard?${params}`, this.esHeaders());
  }

  getProductivityFunnelDashboard() {
    return this.http.get(this.apiBaseUrl + 'productivity_funnel_dashbord?funnel=true', this.esHeaders());
  }

  getDailyLeadsSalesFunnelDashboard() {
    return this.http.get(this.apiBaseUrl + 'daily_lead_sales_ops_dashboard?funnel=true', this.esHeaders());

  }
  esHeaders() {
    let headers = new HttpHeaders();
    let accessToken: any = localStorage.getItem('refereshToken'); // Assuming refreshToken is the correct token name
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return {headers};
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    return throwError(() => new Error('Something went wrong; please try again later.'));
  }

}
