import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ice-response-report',
  templateUrl: './ice-response-report.component.html',
  styleUrls: ['./ice-response-report.component.scss']
})
export class IceResponseReportComponent implements OnInit{

  config:any
  name='Ice Response Report'
  
  ngOnInit(): void {

    this.config=[
    {
      header:'Customer UCIC',
      field:'customer_ucic'
    },
    {
      header:'Lead Date',
      field:'lead_date'
    },
    {
      header:'Decision Date',
      field:'decision_date'
    },
    {
      header:'Customer Name',
      field:'customer_name'
    },
    {
      header:'Center',
      field:'center'
    },
    {
      header:'Employee Name',
      field:'employee_name'
    },
    {
      header:'Risk Score',
      field:'risk_score'
    },
    {
      header:'Credit Score',
      field:'credit_score'
    },
    {
      header:'Status',
      field:'status'
    },
    {
      header:'Product Code',
      field:'product_code'
    },
    {
      header:'Loan Amount',
      field:'loan_amount'
    },
    {
      header:'Logic Product',
      field:'logic_product'
    },
    {
      header:'Logic Amount',
      field:'logic_amount'
    },
    {
      header:'Remark Amount',
      field:'remark_amount'
    }, 
    {
      header:'Partner',
      field:'partner_type'
    },
    {
      header:'Product Type',
      field:'product_type'
    },
    {
      header:'Lender Type',
      field:'lender_type'
    }
  ]
    
  }

}
