import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arth-static-lms-report',
  templateUrl: './arth-static-lms-report.component.html',
  styleUrls: ['./arth-static-lms-report.component.scss']
})
export class ArthStaticLmsReportComponent {

  name='Credit Old Customer Repayment Data'
  reportInformation  = 'Find all the  credit old customer repayment data';

}
  