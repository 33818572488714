import { Component } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';

import { AssignmentReportComponent } from './assignment-report/assignment-report.component';
import { MasterBussinessReportComponent } from '../common-module/master-bussiness-report/master-bussiness-report.component';
import { CreditFunnelOverallTrackingReportComponent } from '../finance-module/credit-funnel-overall-tracking-report/credit-funnel-overall-tracking-report.component';

@Component({
  selector: 'app-ice-module',
  templateUrl: './ice-module.component.html',
  styleUrls: ['./ice-module.component.scss'],
  entryComponents: [CreditFunnelOverallTrackingReportComponent,AssignmentReportComponent,MasterBussinessReportComponent]
})
export class IceModuleComponent {
  reports:any={
  'Credit Evaluation Report':{name:'credit_evaluation_report',permission:['credit_evaluation_report']},
  'Master Business Report':{name: 'master_business_report',permission:['master_business_report']},
  'Credit Funnel OverAll Tracking Report':{name: 'credit_funnel_overall_tracking_report',permission:['credit_funnel_overall_tracking_report']},
  'Assignment Report':{name:'assignment_report',permission:['assignment_report']},
  }

  constructor(private router:Router){

  }


  reportClicked(data:any){
  if (this.reports[data].name === 'credit_evaluation_report')
  this.router.navigate(['/home/mis/CreditEvaluationReport'], { queryParams: { module: 'Risk', report: this.reports[data].name } })
  else if (this.reports[data].name === 'master_business_report')
  this.router.navigate(['/home/mis/MasterBusinessReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
  else if (this.reports[data].name === 'credit_funnel_overall_tracking_report')
  this.router.navigate(['/home/mis/CreditFunnelOverallTrackingReport'], { queryParams: { module: 'business', report: this.reports[data].name } })
  else if (this.reports[data].name === 'assignment_report')
  this.router.navigate(['/home/mis/AssignmentReport'], { queryParams: { module: 'Central', report: this.reports[data].name } })
  }
  goBack(){
    window.history.back()
  }

}
