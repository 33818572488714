import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freshworks-widget',
  templateUrl: './freshworks-widget.component.html',
  styleUrls: ['./freshworks-widget.component.scss']
})
export class FreshworksWidgetComponent implements OnInit {
  freshDeskToken:any
  ngOnInit(): void {
    this.freshDeskToken=localStorage.getItem('freshDeskToken');
    (window as any).FreshworksWidget('authenticate', {token: this.freshDeskToken});
    (window as any).FreshworksWidget('show');
  }

    openWidget(): void { 
    };
}