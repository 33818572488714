<div *ngIf="isLoading" class="loading-container">
  <iframe src="https://lottie.host/embed/3a024223-432e-4360-bc86-d5488b6b2c41/qTvNOnZBAo.json"
  class="large-iframe"                     ></iframe>
</div>

<div *ngIf="!isLoading && !errorMessage" class="dashboard-container">
<div class="dashboard-container">
  <div class = 'title_p'>
    <p><b>New Customer Projection {{currentMonth}}  {{currentYear}}</b></p>
  </div>
  <div class = 'new_custoemr_projection'>
    <div class="stack_hor_chart">
      <div id = "stack_chart"></div>
    </div>
    <div class="stack_ver_chart">
      <div  id = "collumn_chart"></div>
    </div>
  </div>
  <div class = 'title_p'>
    <p><b>Repeat Customer Projection {{currentMonth}}  {{currentYear}}</b></p>
  </div>
  <div class="repeate_customer_projection">
    <div class="stack_hor_chart">
      <div id = "rep_stack_chart"></div>
    </div>
    <div class="stack_ver_chart">
      <div  id = "rep_collumn_chart"></div>
    </div>
  </div>
</div>
</div>
