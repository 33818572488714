import { Component} from '@angular/core';


@Component({
  selector: 'app-hlp-hotlead-report',
  templateUrl: './hlp-hotlead-report.component.html',
  styleUrls: ['./hlp-hotlead-report.component.scss']
})
export class HlpHotleadReportComponent{
      config:any
      name='HLP Hotlead Report'
      reportInformation  = 'This report provides detail information about leads provided by different HLP'
  }
   