import { Component } from '@angular/core';
  
@Component({
    selector: 'app-eligibility-check-failed-customer-report',
    templateUrl: './eligibility-check-failed-customer-report.component.html',
    styleUrls: ['./eligibility-check-failed-customer-report.component.scss']
  })
export class EligibilityCheckFailedCustomerReportComponent {
    name='Credit Eligibility Status Report'
    reportInformation  = 'Provides Customer Credit Eligibility Status ';
  }
  
  