import { Component } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { CreditEvaluationReportComponent } from '../../ice-module/credit-evaluation-report/credit-evaluation-report.component';
import { LoanCollectionReportComponent } from '../../finance-module/loan-collection-report/loan-collection-report.component';
import { CreditDueDemandReportComponent } from '../credit-due-demand-report/credit-due-demand-report.component';
import { MasterBussinessReportComponent } from '../master-bussiness-report/master-bussiness-report.component';
import { CreditFunnelTrackingReportComponent } from '../credit-funnel-tracking-report/credit-funnel-tracking-report.component';

@Component({
  selector: 'app-common-module',
  templateUrl: './common-module.component.html',
  styleUrls: ['./common-module.component.scss'],
  entryComponents: [CreditFunnelTrackingReportComponent,CreditEvaluationReportComponent,LoanCollectionReportComponent,CreditDueDemandReportComponent,MasterBussinessReportComponent]

})
export class CommonModuleComponent {
    reports:any={
    'Credit Funnel Tracking Report':{name:'credit_funnel_tracking_report',permission:['credit_funnel_tracking_report']},
    'Master Business Report':{name:'master_business_report',permission:['master_business_report']},
    'Credit Due Demand Report':{name:'credit_due_demand_report',permission:['credit_due_demand_report']}
  }

    constructor(private router:Router){

    }
    reportClicked(data:any){
      if (this.reports[data].name === 'loan_collection_report')
      this.router.navigate(['/home/mis/LoanCollectionReport'], { queryParams: { module: 'Common_Module', report: this.reports[data].name } })
      else if (this.reports[data].name === 'credit_evaluation_report')
      this.router.navigate(['/home/mis/CreditEvaluationReport'], { queryParams: { module: 'Common_Module', report: this.reports[data].name } })
      else if (this.reports[data].name === 'master_business_report')
      this.router.navigate(['/home/mis/MasterBusinessReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
      else if (this.reports[data].name === 'credit_funnel_tracking_report')
      this.router.navigate(['/home/mis/CreditFunnelTrackingReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
      else if (this.reports[data].name === 'credit_due_demand_report')
      this.router.navigate(['/home/mis/CreditDueDemandReport'], { queryParams: { module: 'Common_Module', report: this.reports[data].name } })





      // this.router.navigate(['home/mis/FetchReport'],{ queryParams: {module:'ICE_Module',report:this.reports[data].name}})
      //this.router.navigate([`home/mis/${this.reports[data].name}`],{ queryParams: {module:'Common_Module',report:this.reports[data].name}})

      }

      goBack(){
        window.history.back()
      }

    }


