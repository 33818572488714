<p class="text-center" style="font-family: 'Poppins', sans-serif; font-size: 24px; font-weight: 500;">View Report</p>

<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap" rel="stylesheet">

<div class="container mt-4">
    <div class="d-flex align-items-center">
        <nav>
            <ol class="breadcrumb justify-content-center ps-0">
                <mat-icon class="chevron ms-5" (click)="goBack()" style="cursor: pointer;">chevron_left</mat-icon>
                <li class="d-flex align-items-center" style="color:#B5ADD3;">
                    <span class="current-module">{{ currentModule === 'ICE Module' ? 'Risk' : currentModule }}</span>
                    <mat-icon class="mx-2" style="color:#6e4af0;">double_arrow</mat-icon>
                    <b style="font-family: 'Poppins', sans-serif; color: #3E3E3E; font-size: 20px;">
                      {{formatReportName(reportSelected)}}
                    </b>
                </li>
            </ol>
        </nav>
    </div>
    <div class="report-details mt-4 p-3" style="border: 1px solid #ddd; border-radius: 8px; background-color: #f9f9f9;">
        <h3 class="text-center" style="font-family: 'Poppins', sans-serif; color: #333;">{{ formatReportName(reportSelected) }}</h3>
        <p class="text-muted text-center">Detail for {{ formatReportName(reportSelected) }}: <b>: {{reportInformation}}</b>.</p>
        <div class="text-center mt-3">
            <button class="btn btn-primary" (click)="downloadReport()">
                Download Report
            </button>
        </div>
        <p class="mt-5 text-right"
        [ngClass]="{'text-success': isGeneratedToday(reportLastGeneratedAt), 'text-danger': !isGeneratedToday(reportLastGeneratedAt)}">
         <small><b>Report Last Generated AT:  </b><strong>{{ reportLastGeneratedAt | date:'dd-MM-yyyy' }}  {{reportTime}} </strong></small>
        </p>
        <mat-form-field appearance="outline" class="search-field">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Search">
      </mat-form-field>
    </div>

    <div class="mt-4">
      <div style="overflow-x: auto;">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 custom-table">
              <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ column | titlecase }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                      {{ element[column] }}
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>
      </div>
      <mat-paginator
          [length]="totalRecords"
          [pageSize]="5"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="onPageChange($event)">
      </mat-paginator>
  </div>

</div>
