import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-repayment-report',
  templateUrl: './credit-repayment-report.component.html',
  styleUrls: ['./credit-repayment-report.component.scss']
})
export class CreditRepaymentReportComponent {
  name='Credit Repayment Report'
  reportInformation  = 'Provide installment wise loan repayment.'
}
