// loop.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoopService {
  private loopRunning: boolean = true;

  constructor() { }

  stopLoop(): void {
    //console.log('LoopService: stopLoop() called');
    this.loopRunning = false;


  }

  isLoopRunning(): boolean {
    return this.loopRunning;
  }
}
