import { Component } from '@angular/core';

@Component({
  selector: 'app-assignment-report',
  templateUrl: './assignment-report.component.html',
  styleUrls: ['./assignment-report.component.scss']
})
export class AssignmentReportComponent {
  config:any
  name='Assignment Report'
  reportInformation  = 'Daily Employee Assignments.'
}

