import { Component } from '@angular/core';

@Component({
  selector: 'app-business-prime-sales-report',
  templateUrl: './business-prime-sales-report.component.html',
  styleUrls: ['./business-prime-sales-report.component.scss']
})
export class BusinessPrimeSalesReportComponent {
  name='Business Prime Sales Report'
  reportInformation  = 'Business Prime Sales Information'
}
