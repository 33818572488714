import { Component } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { HlpStatusReportComponent } from '../partner-module/hlp-status-report/hlp-status-report.component';
import { HlpHotleadReportComponent } from '../partner-module/hlp-hotlead-report/hlp-hotlead-report.component';
import { InsuranceReportComponent } from '../insurance-module/insurance-report/insurance-report.component';
import { AssignmentReportComponent } from '../ice-module/assignment-report/assignment-report.component';
import { MasterBussinessReportComponent } from '../common-module/master-bussiness-report/master-bussiness-report.component';
import { CreditFunnelTrackingReportComponent } from '../common-module/credit-funnel-tracking-report/credit-funnel-tracking-report.component';

@Component({
  selector: 'app-customer-module',
  templateUrl: './customer-module.component.html',
  styleUrls: ['./customer-module.component.scss'],
  entryComponents: [InsuranceReportComponent,HlpStatusReportComponent,HlpHotleadReportComponent,CreditFunnelTrackingReportComponent,MasterBussinessReportComponent, AssignmentReportComponent]
})
export class CustomerModuleComponent {
  reports:any={
              'CKYC Report':{name:'ckyc_report',permission:['ckyc_report']},
              'HLP Status Report':{name:'hlp_status_report',permission: ['hlp_status_report']},
              'HLP Hotlead Report':{name:'hlp_hotlead_report',permission: ['hlp_hotlead_report']},
              'Credit Eligibilibilty Status Report':{name:'credit_eligibility_status_report',permission:['eligibility_check_failed_customer_report']},'Customer Journey Drop Report':{name:'customer_journey_drop_report',permission:['customer_journey_drop_report_off']},
              'Master Business Report':{name: 'master_business_report',permission:['master_business_report']},
              'Credit Funnel Tracking Report':{name: 'credit_funnel_tracking_report',permission:['credit_funnel_tracking_report']},
              'HLP Invoicing Report':{name:'hlp_invoicing_report',permission:['hlp_invoicing_report']},
              'Assignment Report':{name:'assignment_report',permission:['assignment_report']},
            }

  constructor(private router:Router){
  }
  reportClicked(data:any){
    if (this.reports[data].name === 'hlp_status_report')
    this.router.navigate(['/home/mis/HlpStatusReport'], { queryParams: { module: 'Central Ops', report: this.reports[data].name } })
    else if (this.reports[data].name === 'hlp_hotlead_report')
    this.router.navigate(['/home/mis/hlp_hotlead_report'], { queryParams: { module: 'Central Ops', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_eligibility_status_report')
    this.router.navigate(['/home/mis/eligibility_check_failed_customer_report'], { queryParams: { module: 'Central Ops', report: this.reports[data].name } })
    else if (this.reports[data].name === 'master_business_report')
    this.router.navigate(['/home/mis/MasterBusinessReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_funnel_tracking_report')
    this.router.navigate(['/home/mis/CreditFunnelTrackingReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
    else if (this.reports[data].name === 'ckyc_report')
    this.router.navigate(['/home/mis/ckyc_report'], { queryParams: { module: 'Central Ops', report: this.reports[data].name } })
    else if (this.reports[data].name === 'hlp_invoicing_report')
    this.router.navigate(['/home/mis/HlpInvoicingReport'], { queryParams: { module: 'Central Ops', report: this.reports[data].name } })
    else if (this.reports[data].name === 'assignment_report')
    this.router.navigate(['/home/mis/AssignmentReport'], { queryParams: { module: 'Central', report: this.reports[data].name } })
  }
  goBack(){
    window.history.back()
  }
}
