export const environment = {
    production: false,

  //  apiUrl: 'http://192.168.10.25:4000/'

    apiUrl: 'https://ovs-api.staging.arthapp.in/'
    // apiUrl: 'http://192.168.10.249:4000/'
    // Add other environment variables here
    // apiUrl: 'http://localhost:4000/'

    // apiUrl: 'https://ovs-api.staging.arthapp.in/',
  };




