import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthComponent } from './view/pages/auth/auth.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'
import { HomeComponent } from './view/pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { UsersComponent } from './view/pages/users/users.component';
import { ArchiveReportComponent } from './view/pages/archive-report/archive-report.component';

import { RolesComponent } from './view/pages/roles/roles.component';
import { CreateNewUserComponent } from './view/pages/users/create-new-user/create-new-user.component';
import { CreateNewRoleComponent } from './view/pages/roles/create-new-role/create-new-role.component';
import { FinanceModuleComponent } from './view/pages/mis/finance-module/finance-module.component';
import { MisComponent } from './view/pages/mis/mis/mis.component';
import { ReportFilterComponent } from './view/pages/mis/report-filter/report-filter.component';
import { MatNativeDateModule } from '@angular/material/core';
import { InsuranceModuleComponent } from './view/pages/mis/insurance-module/insurance-module.component';
import { CollectionModuleComponent } from './view/pages/mis/collection-module/collection-module.component';
import { CustomerModuleComponent } from './view/pages/mis/customer-module/customer-module.component';
import { IceModuleComponent } from './view/pages/mis/ice-module/ice-module.component';
import { WelfareModuleComponent } from './view/pages/mis/welfare-module/welfare-module.component';
import { InsuranceReportComponent } from './view/pages/mis/insurance-module/insurance-report/insurance-report.component';
import { GoatTrustReportComponent } from './view/pages/mis/insurance-module/goat-trust-report/goat-trust-report.component';
import { CollectionReportComponent } from './view/pages/mis/collection-module/collection-report/collection-report.component';
import { WelfareReportComponent } from './view/pages/mis/welfare-module/welfare-report/welfare-report.component';
import { DemandSheetReportComponent } from './view/pages/mis/collection-module/demand-sheet-report/demand-sheet-report.component';
import { CustomerDetailReportComponent } from './view/pages/mis/customer-module/customer-detail-report/customer-detail-report.component';
import { IceRejectionReportComponent } from './view/pages/mis/ice-module/ice-rejection-report/ice-rejection-report.component';
import { IceResponseReportComponent } from './view/pages/mis/ice-module/ice-response-report/ice-response-report.component';
import { LtdReportComponent } from './view/pages/mis/customer-module/lead_to_loan_disbursment_report/ltd-report.component';
import {MAT_DATE_FORMATS,DateAdapter,MAT_DATE_LOCALE } from '@angular/material/core';
import {MY_DATE_FORMATS} from './shared/dateFormat'
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxPermissionsGuard, NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { LoadPermissionService } from './services/load-permission.service';
import { PartnerModuleComponent } from './view/pages/mis/partner-module/partner-module.component';
import { InsuranceBankDetailsComponent } from './view/pages/mis/finance-module/insurance-bank-details/insurance-bank-details.component';
import { FreshworksWidgetComponent } from './freshworks-widget/freshworks-widget.component';
import { CkycReportComponent } from './view/pages/mis/customer-module/ckyc-report/ckyc-report.component';
import { AuthService } from './services/auth.service';
import { TestDisbursementReportComponent } from './view/pages/mis/customer-module/test-disbursement-report/test-disbursement-report.component';
import { TestCollectionReportComponent } from './view/pages/mis/customer-module/test-collection-report/test-collection-report.component';
import { TestHlpReportComponent } from './view/pages/mis/customer-module/test-hlp-report/test-hlp-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HlpHotleadReportComponent } from './view/pages/mis/partner-module/hlp-hotlead-report/hlp-hotlead-report.component';
import { LoanCollectionReportComponent } from './view/pages/mis/finance-module/loan-collection-report/loan-collection-report.component';
import { CreditEvaluationReportComponent } from './view/pages/mis/ice-module/credit-evaluation-report/credit-evaluation-report.component';
import { PreDemandMonthlyReportComponent } from './view/pages/mis/finance-module/pre-demand-monthly-report/pre-demand-monthly-report.component';
import { CreditReconciliationReportComponent } from './view/pages/mis/finance-module/credit-reconciliation-report/credit-reconciliation-report.component';
import { EligibilityCheckFailedCustomerReportComponent } from './view/pages/mis/customer-module/eligibility-check-failed-customer-report/eligibility-check-failed-customer-report.component';
import { CustomerJourneyDropReportComponent } from './view/pages/mis/customer-module/customer-journey-drop-report/customer-journey-drop-report.component';
import { ArthStaticLmsReportComponent } from './view/pages/mis/finance-module/arth-static-lms-report/arth-static-lms-report.component';
import { KinaraBusinessDetailsReportComponent } from './view/pages/mis/finance-module/kinara-business-details-report/kinara-business-details-report.component';
import { KinaraCustomerDetailsReportComponent } from './view/pages/mis/finance-module/kinara-customer-details-report/kinara-customer-details-report.component';
import { KinaraDisbursementDetailsReportComponent } from './view/pages/mis/finance-module/kinara-disbursement-details-report/kinara-disbursement-details-report.component';
import { CommonModuleComponent } from './view/pages/mis/common-module/common-module/common-module.component';
import { CreditDueDemandReportComponent } from './view/pages/mis/common-module/credit-due-demand-report/credit-due-demand-report.component';
import { SpinnerOverlayComponent } from './view/pages/mis/spinner-overlay/spinner-overlay.component';
import { OverlayComponent } from './view/pages/mis/overlay/overlay.component';
import { ClusterComponent } from './view/pages/home/cluster/cluster.component';
import { ProductivityComponent } from './view/pages/home/productivity/productivity.component';
import { CreditBusinessOverallReportComponent } from './view/pages/mis/insurance-module/credit-business-overall-report/credit-business-overall-report.component';
import { CreditBusinessPlusReportComponent } from './view/pages/mis/insurance-module/credit-business-plus-report/credit-business-plus-report.component';
import { CreditRepaymentOverallReportComponent } from './view/pages/mis/insurance-module/credit-repayment-overall-report/credit-repayment-overall-report.component';
import { SAndDModuleComponent } from './view/pages/mis/s-and-d-module/s-and-d-module.component';
import { DailyFunnelComponent } from './view/pages/home/daily-funnel/daily-funnel.component';
import { ReportsComponent } from './view/pages/mis/reports/reports.component';
import { DashboardModule } from './view/pages/dashboard/dashboard.module';
import { AssignmentReportComponent } from './view/pages/mis/ice-module/assignment-report/assignment-report.component';
import { ViewReportComponent } from './view/pages/mis/view-report/view-report.component';
import { HlpInvoicingReportComponent } from './view/pages/mis/customer-module/hlp-invoicing-report/hlp-invoicing-report.component';
import { PaymintTransactionReportComponent } from './view/pages/mis/insurance-module/paymint-transaction-report/paymint-transaction-report.component';
import { PaymintSettlementReportComponent } from './view/pages/mis/insurance-module/paymint-settlement-report/paymint-settlement-report.component';
import { PaymintSubmerchantReportComponent } from './view/pages/mis/insurance-module/paymint-submerchant-report/paymint-submerchant-report.component';
import { BusinessPrimeSalesReportComponent } from './view/pages/mis/s-and-d-module/business-prime-sales-report/business-prime-sales-report.component';
import { MasterBussinessReportComponent } from './view/pages/mis/common-module/master-bussiness-report/master-bussiness-report.component';
import { MasterBussinessOverallReportComponent } from './view/pages/mis/finance-module/master-bussiness-overall-report/master-bussiness-overall-report.component';
import { CreditFunnelTrackingReportComponent } from './view/pages/mis/common-module/credit-funnel-tracking-report/credit-funnel-tracking-report.component';
import { CreditFunnelOverallTrackingReportComponent } from './view/pages/mis/finance-module/credit-funnel-overall-tracking-report/credit-funnel-overall-tracking-report.component';
import { HumanResourcesModuleComponent } from './view/pages/mis/human-resources-module/human-resources-module.component';
import { EmployeeAttendanceReportComponent } from './view/pages/mis/human-resources-module/employee-attendance-report/employee-attendance-report.component';
import { HlpStatusReportComponent } from './view/pages/mis/partner-module/hlp-status-report/hlp-status-report.component';
import { CreditPreDisbursementReportComponent } from './view/pages/mis/finance-module/credit-pre-disbursement-report/credit-pre-disbursement-report.component';
import { CreditRepaymentReportComponent } from './view/pages/mis/common-module/credit-repayment-report/credit-repayment-report.component';


export function permissionsFactory(LoadPermissionService:LoadPermissionService,ngxPermissionsService:NgxPermissionsService){
  return ()=>{
    return LoadPermissionService.loadPermissions().subscribe((data:any)=>{
      // //console.log(data.permissions)
      ngxPermissionsService.addPermission(data.permissions)
    }
    )
  }

}

export function fetchFreshdeskToken(AuthService:AuthService){
  return()=>{
    return AuthService.fetchToken().subscribe((res:any)=>{
      localStorage.setItem('freshDeskToken',res.freshdesk_token);
      (window as any).FreshworksWidget('authenticate', {token: res.freshdesk_token});
    })
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    UsersComponent,
    RolesComponent,
    CreateNewUserComponent,
    CreateNewRoleComponent,

    FinanceModuleComponent,
    MisComponent,
    ReportFilterComponent,
    InsuranceModuleComponent,
    CollectionModuleComponent,
    CustomerModuleComponent,
    IceModuleComponent,
    WelfareModuleComponent,
    InsuranceReportComponent,
    GoatTrustReportComponent,
    CollectionReportComponent,
    WelfareReportComponent,
    DemandSheetReportComponent,
    CustomerDetailReportComponent,
    IceResponseReportComponent,
    IceRejectionReportComponent,
    LtdReportComponent,
    PartnerModuleComponent,
    InsuranceBankDetailsComponent,
    FreshworksWidgetComponent,
    CkycReportComponent,
    TestDisbursementReportComponent,
    TestCollectionReportComponent,
    TestHlpReportComponent,
    HlpHotleadReportComponent,
    LoanCollectionReportComponent,
    CreditEvaluationReportComponent,
    PreDemandMonthlyReportComponent,
    CreditReconciliationReportComponent,
    EligibilityCheckFailedCustomerReportComponent,
    CustomerJourneyDropReportComponent,
    ArthStaticLmsReportComponent,
    KinaraBusinessDetailsReportComponent,
    KinaraCustomerDetailsReportComponent,
    KinaraDisbursementDetailsReportComponent,
    CommonModuleComponent,
    CreditDueDemandReportComponent,
    SpinnerOverlayComponent,
    OverlayComponent,
    ClusterComponent,
    ProductivityComponent,
    CreditBusinessOverallReportComponent,
    CreditBusinessPlusReportComponent,
    CreditRepaymentOverallReportComponent,

    SAndDModuleComponent,
     DailyFunnelComponent,
     ArchiveReportComponent,
     ReportsComponent,
     AssignmentReportComponent,
     AssignmentReportComponent,
     ViewReportComponent,
     HlpInvoicingReportComponent,
     PaymintTransactionReportComponent,
     PaymintSettlementReportComponent,
     PaymintSubmerchantReportComponent,
     BusinessPrimeSalesReportComponent,
     MasterBussinessReportComponent,
     MasterBussinessOverallReportComponent,
     CreditFunnelTrackingReportComponent,
     CreditFunnelOverallTrackingReportComponent,
     HumanResourcesModuleComponent,
     EmployeeAttendanceReportComponent,
     HlpStatusReportComponent,
     CreditPreDisbursementReportComponent,
     CreditRepaymentReportComponent
  ],
  imports: [
    DashboardModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    HttpClientModule,
    CommonModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPermissionsModule.forRoot(),
    NgxPaginationModule,
  ],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
   {provide: NgxPermissionsGuard},
   { provide:MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
   {provide: APP_INITIALIZER, useFactory:permissionsFactory,deps:[LoadPermissionService,NgxPermissionsService],multi:true},
   {provide: APP_INITIALIZER, useFactory:fetchFreshdeskToken,deps:[AuthService],multi:true},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
