import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { FetchReport } from 'src/app/services/reports.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-create-new-role',
  templateUrl: './create-new-role.component.html',
  styleUrls: ['./create-new-role.component.scss']
})
export class CreateNewRoleComponent implements OnInit {
  edit: boolean = false
  currentRole: any
  rolename: string
  isCreateRole: boolean = false
  branches = []
  branchForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private roleService: RoleService,
    private reportService: FetchReport) {
    this.branchForm = this.fb.group({});
    this.permissionForm = new FormGroup({
      'user_management_view': new FormControl(false),
      'user_management_create': new FormControl(false),
      'user_management_update': new FormControl(false),

      'role_management_view': new FormControl(false),
      'role_management_create': new FormControl(false),
      'role_management_update': new FormControl(false),

      'customer_view': new FormControl(false),
      'customer_create': new FormControl(false),
      'customer_update': new FormControl(false),

      'communication_setup_view': new FormControl(false),
      'communication_setup_create': new FormControl(false),
      'communication_setup_update': new FormControl(false),

      'strategy_builder_view': new FormControl(false),
      'strategy_builder_create': new FormControl(false),
      'strategy_builder_update': new FormControl(false),


      'campaign_view': new FormControl(false),
      'campaign_create': new FormControl(false),
      'campaign_update': new FormControl(false),

      'create_campaign_view': new FormControl(false),
      'create_campaign_create': new FormControl(false),
      'create_campaign_update': new FormControl(false),

      'create_ticket_view': new FormControl(false),
      'create_ticket_create': new FormControl(false),
      'create_ticket_update': new FormControl(false),

      'dashboard_view': new FormControl(false),
      'dashboard_create': new FormControl(false),
      'dashboard_update': new FormControl(false),

      'telecom_view': new FormControl(false),
      'telecom_create': new FormControl(false),
      'telecom_update': new FormControl(false),


      'reports_view': new FormControl(false),
      'reports_create': new FormControl(false),
      'reports_update': new FormControl(false),

      'create_disposition_view': new FormControl(false),
      'create_disposition_create': new FormControl(false),
      'create_disposition_update': new FormControl(false),

      'disbursement_details_report': new FormControl(false),
      'loan_collection_report': new FormControl(false),
      'pre_demand_monthly_report': new FormControl(false),
      'credit_reconciliation_report': new FormControl(false),
      'ltd_mis_report': new FormControl(false),
      'insurance_report': new FormControl(false),
      'goat_trust_report': new FormControl(false),
      'welfare_report': new FormControl(false),
      'collection_report': new FormControl(false),
      'demand_sheet_report': new FormControl(false),
      'customer_details_report': new FormControl(false),
      'lead_to_loan_disbursment_report': new FormControl(false),
      'ice_response_report': new FormControl(false),
      'rejection_report': new FormControl(false),
      'credit_funel_tracking_report': new FormControl(false),
      'credit_evaluation_report': new FormControl(false),
      'credit_due_demand_report' : new FormControl(false),
      'hlp_report':new FormControl(false),
      'hlp_hotlead_report':new FormControl(false),
      'insurance_bank_details_report':new FormControl(false),
      'master_data_report':new FormControl(false),
      'ckyc_report':new FormControl(false),
      'test_collection_report':new FormControl(false),
      'test_disbursement_report':new FormControl(false),
      'test_hlp_report':new FormControl(false),
      'eligibility_check_failed_customer_report':new FormControl(false),
      'customer_journey_drop_report':new FormControl(false),
      'arth_static_lms_report':new FormControl(false),
      'kinara_business_details_report':new FormControl(false),
      'kinara_customer_details_report':new FormControl(false),
      'kinara_disbursement_details_report':new FormControl(false),
      'credit_business_overall_report':new FormControl(false),
      'credit_business_plus_report':new FormControl(false),
      'credit_repayment_overall_report':new FormControl(false),
      'credit_repayment_plus_report':new FormControl(false)



    })
  }

  permissionForm: FormGroup;

  ngOnInit(): void {
    this.reportService.getFilters('roles').subscribe((res: any) => {
      this.branches = res.centers
      this.createForm();

    })

    this.isCreateRole = window.location.pathname === '/home/roles/create-new-role'
    this.activatedRoute.queryParamMap.subscribe(qParam => {
      this.currentRole = qParam.get('role')
      if (qParam.get('edit') === 'true') {
        this.isCreateRole = false
        this.edit = true
        this.roleService.store = localStorage.getItem('roleDetail')
        this.roleService.store = JSON.parse(this.roleService.store)
        this.rolename = this.roleService.store.name
        //console.log(this.roleService.store)
        //console.log("role selected", this.roleService.store.permissions)
        this.roleService.store.permissions.map((obj: any) => {
          //console.log(obj)
          this.permissionForm.controls[obj].setValue(true)
        }
        )

      }
    })
  }

  options = ['view', 'create', 'update']



  onSubmit() {
    //console.log(this.branchForm)
    if (!this.permissionForm.valid) {
      //console.log("hi");
      return;
    }
    let permissions: any = []
    for (const obj in this.permissionForm.value) {
      if (this.permissionForm.value[obj]) {
        permissions.push(obj)
      }
    }
    let selectedBranches: any = []
    for (const obj in this.branchForm.value) {
      if (this.branchForm.value[obj]) {
        selectedBranches.push(obj)
      }
    }

    let data: any = {}
    data.role_name = this.rolename
    data.permission_params = permissions
    data.branches = selectedBranches

    if (this.edit) {

      this.roleService.updateRole(data).subscribe(res => {
        //console.log(res)
        this.router.navigate(['/home/roles'])
        setTimeout(() => {
          window.location.reload();
           }, 1000);
      })
    }
    else {
      this.roleService.createNewRole(data).subscribe(res => {
        //console.log(res)
        this.router.navigate(['/home/roles'])
         setTimeout(() => {
        window.location.reload();

         }, 1000);
      })
    }

  }

  goBack() {
    window.history.back()
  }

  createForm() {
    const formGroup: any = {};
    //console.log(this.branches)

    this.branches.map(control => {
      formGroup[control] = false;
    });

    this.branchForm = this.fb.group(formGroup);
    if(this.edit){
    this.roleService.store.branches.map((obj: any) => {
      //console.log(obj)
      this.branchForm.controls[obj].setValue(true)
    })
  }

    //console.log(formGroup, this.branchForm)
  }
}

