<div class="header row-align animated-background">
  <div class="back-button">
      <mat-icon
          class="back-icon"
          (click)="goBack()">chevron_left</mat-icon>
      <mat-label class="header-title">{{isCreateUser ? 'Create New User' : 'Update User'}}</mat-label>
  </div>
</div>

<form (ngSubmit)="onSubmit()" name="createNewUserForm" [formGroup]="createNewUserForm" class="user-form">
  <div class="form-group">
      <mat-label>Employee Name</mat-label>
      <input class="input-box" formControlName="name" placeholder="Enter employee name" />
  </div>

  <div class="form-group">
      <mat-label>Employee ID</mat-label>
      <input class="input-box" formControlName="emp_id" placeholder="Enter employee ID" />
  </div>

  <div class="form-group">
      <mat-label>Email ID</mat-label>
      <input class="input-box" formControlName="email" placeholder="Enter email ID" />
  </div>

  <div class="form-group">
      <mat-label>Password</mat-label>
      <input type="password" class="input-box" formControlName="password" placeholder="Enter password" />
  </div>

  <div class="form-group">
      <mat-label>Mobile Number</mat-label>
      <input class="input-box" formControlName="mobile" placeholder="Enter mobile number" />
  </div>

  <div class="form-group">
      <mat-label>Role</mat-label>
      <select class="input-box" formControlName="role" (change)="onRoleChange($event)">
          <option value="">Select Role</option>
          <option *ngFor="let role of roles" [value]="role.name.toUpperCase()">{{role.name}}</option>
      </select>
  </div>

  <div *ngIf="showCenterDropdown" class="form-group">
      <mat-label>Select Center</mat-label>
      <mat-select class="input-box" formControlName="centers" multiple>
          <mat-option *ngFor="let center of centers" [value]="center">{{center}}</mat-option>
      </mat-select>
  </div>

  <div class="form-group">
      <mat-label>Campaigns</mat-label>
      <select class="input-box" formControlName="campaign">
          <!-- Campaign options can be added here -->
      </select>
  </div>

  <div class="button-container">
      <button class="submit-button" type="submit">
          <mat-label>{{ edit ? 'Update' : 'Create' }}</mat-label>
      </button>
  </div>
</form>
