import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-demand-monthly-report',
  templateUrl: './pre-demand-monthly-report.component.html',
  styleUrls: ['./pre-demand-monthly-report.component.scss']
})
export class PreDemandMonthlyReportComponent implements OnInit{
  config:any
  name='Pre Demand Monthly Report'
  ngOnInit(): void {
    this.config=[
      {
        header:'Loan UID',
        field:'loan_uid'
      },
      {
        header:'Lead ID',
        field:'lead_id'
      },
      {
        header:'Customer Code',
        field:'customer_code'
      },
      {
        header:'RZP ID',
        field:'rzp_id'
      },
      {
        header:'Customer ID',
        field:'customer_id'
      },
      {
        header:'Name',
        field:'name'
      },
      {
        header:'Date of Birth',
        field:'dob'
      },
      {
        header:'Gender',
        field:'gender'
      },
      {
        header:'Mobile',
        field:'mobile'
      },
      {
        header:'Partner Type',
        field:'partner_type'
      },
      {
        header:'Product Type',
        field:'product_type'
      },
      {
        header:'Product Purpose',
        field:'product_purpose'
      },
      {
        header:'Product Code',
        field:'product_code'
      },
      {
        header:'Lender Type',
        field:'lender_type'
      },
      {
        header:'Loan Amount',
        field:'loan_amount'
      },
      {
        header:'Net Disbursed',
        field:'net_disbursed'
      },
      {
        header:'Disbursement Date',
        field:'disbursement_date'
      },
      {
        header:'EMI',
        field:'emi'
      },
      {
        header:'Instalment Number',
        field:'instalment_number'
      },
      {
        header:'Due Date',
        field:'due_date'
      },
      {
        header:'Penalty Due',
        field:'penalty_due'
      },
      {
        header:'Interest Due',
        field:'interest_due'
      },
      {
        header:'Principal Due',
        field:'principal_due'
      },
      {
        header:'Total Due',
        field:'total_due'
      },
      {
        header:'Installment Paid',
        field:'installment_paid'
      },
      {
        header:'Last Paid',
        field:'last_paid'
      },
      {
        header:'Extra Paid',
        field:'extra_paid'
      },
      {
        header:'Pending Due',
        field:'pending_due'
      },
      {
        header:'Center',
        field:'center'
      },
      {
        header:'Cluster',
        field:'cluster'
      },
      {
        header:'DPD Days',
        field:'dpd_days'
      },
      {
        header:'DPD Bucket',
        field:'dpd_bucket'
      },
      {
        header:'Loan Status',
        field:'loan_status'
      },
      {
        header:'Report Date',
        field:'report_date'
      }
    ]
    
    }
}