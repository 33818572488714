import { NgModule,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { NgApexchartsModule } from 'ng-apexcharts';

import {MatIconModule} from '@angular/material/icon';
import { DashboardComponent } from './dashboard.component';
import { Dashboard1Component } from './dashboard1/dashboard1.component';
import { Dashboard2Component } from './dashboard2/dashboard2.component';
import { Dashboard3Component } from './dashboard3/dashboard3.component';
import { Dashboard4Component } from './dashboard4/dashboard4.component';
// import { ReportsComponent } from '../mis/reports/reports.component';
import { RouterModule } from '@angular/router';
import { Dashboard5Component } from './dashboard5/dashboard5.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ImplicitReceiver } from '@angular/compiler';

@NgModule({
  declarations: [
    DashboardComponent,
    Dashboard1Component,
    Dashboard2Component,
    Dashboard3Component,
    Dashboard4Component,
    Dashboard5Component,
    // ReportsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCardModule,
    NgApexchartsModule,
    RouterModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [DashboardComponent]
})

export class DashboardModule{}
