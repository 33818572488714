import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, Input,ChangeDetectorRef } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { UserService } from 'src/app/services/user.service';
import { DashboardService } from 'src/app/services/dashboard.service';

interface user_details {
  series: string;
  [key: string]: any;
}
interface CenterDetails {
  bank_branches: string;
  total_assignment: number;
  total_visits: number;
  total_caf_attempted: number;
  total_caf_done: number;
  credits: number;
  insurance: number;
  paymint:number;
  on_time :number;
  days_0_10 :number;
  days_11_30 :number;
  days_31_60 :number;
  days_61_90 :number;
  days_91_180 :number;
  days_180_plus :number;
  occupation: string;
  visits: string;
  loans: string;
  gender: string;

}


@Component({
  selector: 'app-dashboard5',
  templateUrl: './dashboard5.component.html',
  styleUrls: ['./dashboard5.component.scss']
})
export class Dashboard5Component implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  errorMessage: string = '';
  isLoading: boolean = true;
  funnelData: any[] = [];
  customerData: any[] = [];
  pqData: any[] = [];
  customerDetailData: any[] = [];
  PQmatrixData:any[] = [];
  funnelIndex: number = 0;
  customerIndex: number = 0;
  pqIndex: number = 0;
  private donutCharts: ApexCharts[] = [];
  private raderCharts: ApexCharts[] = [];
  previousSelectedCentersLength: number = 0;

  allCenters: string[] = ['Arth-Ayodhya', 'Arth-Bachhrawan', 'Arth-Bhiwadi', 'Arth-Delhi', 'Arth-Jaipur', 'Arth-Lucknow', 'Arth-Pataudi', 'Arth-Raebareli', 'Arth-Sultanpur', 'Arth-Unchahar'];


  @Input() selectedCenters: string[] = ['Arth-Lucknow','Arth-Ayodhya'];

  constructor(
    private cdr: ChangeDetectorRef,
    private UserService: UserService,
    private DashboardService: DashboardService
    ) {}

  user_details: user_details[] = [];
  customer_details: CenterDetails[] = [];




  ngOnInit() {

    this.UserService.get_location().subscribe({
      next: (data) => {
        localStorage.setItem('user_location', JSON.stringify(data))
        setTimeout(() => {
          const stored_location = JSON.parse(localStorage.getItem('user_location') || '{}');
          if (stored_location.location && stored_location.location != 'Arth-Gurugram'){
          if (stored_location.location){
            this.allCenters = [stored_location.location];
            this.selectedCenters = this.allCenters;
          }
          else {
             this.allCenters = [];
             this.selectedCenters = this.allCenters;
            }
          }
        }, 100);
      },
      error: (error) => {
        console.error('Error fetching user data:');
      }
    });

    this.DashboardService.getCenterDashboard().subscribe({
      next: (data) => {
        setTimeout(() => {
          this.customer_details = data[0]
          // console.log('user_details',this.customer_details);
          this.isLoading = false;
          this.renderDonutChart2();
          this.renderRaderChart()
        }, 20);
      },
      error: (error) => {
        console.error('Error fetching user data:');
      }
    });

    this.loadStaticData();
    this.previousSelectedCentersLength = this.selectedCenters.length;
  }

  ngAfterViewInit() {
    if (this.isLoading){
    this.renderDonutChart();
    this.renderRaderChart();
    // this.renderDonutChart2();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCenters'] && !changes['selectedCenters'].isFirstChange()) {
      const currentLength = this.selectedCenters.length;
      if (currentLength !== this.previousSelectedCentersLength) {
        this.renderDonutChart2();
        console.log("renderDonutCenters_23");
        this.previousSelectedCentersLength = currentLength;
      }
    }
  }

  ngOnDestroy(): void {
    this.donutCharts.forEach(chart => chart.destroy());
    this.raderCharts.forEach(chart => chart.destroy());
  }

  loadStaticData() {
    this.isLoading = true;
    // console.log('Customer Data Loaded:', this.customerData);

    // Funnel Data
    this.funnelData = [
      { bank_branches: 'Arth-Alwar', total_assigned: 20, total_visits: 0, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Ayodhya', total_assigned: 131, total_visits: 60, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Bachhrawan', total_assigned: 43, total_visits: 11, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Barabanki', total_assigned: 5, total_visits: 0, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Bhiwadi', total_assigned: 10, total_visits: 0, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Delhi', total_assigned: 128, total_visits: 26, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Jaipur', total_assigned: 128, total_visits: 40, total_caf_attempted: 4, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Lucknow', total_assigned: 98, total_visits: 63, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Pataudi', total_assigned: 59, total_visits: 14, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Raebareli', total_assigned: 129, total_visits: 64, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Sultanpur', total_assigned: 98, total_visits: 42, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Unchahar', total_assigned: 31, total_visits: 2, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
      { bank_branches: 'Arth-Unnao', total_assigned: 20, total_visits: 0, total_caf_attempted: 0, total_caf_done: 0, ftm: 0 },
    ];

    // Customer Data
    this.customerData = [
      { center: 'Arth-Ayodhya', credits: 15, insurance: 0, payment: 4, business_account: 4, demat_account: 4 },
      { center: 'Arth-Bachhrawan', credits: 1, insurance: 0, payment: null, business_account: null, demat_account: null },
      { center: 'Arth-Bhiwadi', credits: 9, insurance: 0, payment: null, business_account: null, demat_account: null },
      { center: 'Arth-Delhi', credits: 2, insurance: 0, payment: null, business_account: null, demat_account: null },
      { center: 'Arth-Jaipur', credits: 11, insurance: 0, payment: null, business_account: null, demat_account: null },
      { center: 'Arth-Lucknow', credits: 12, insurance: 0, payment: 3, business_account: 3, demat_account: 3 },
      { center: 'Arth-Pataudi', credits: 1, insurance: 0, payment: null, business_account: null, demat_account: null },
      { center: 'Arth-Raebareli', credits: 14, insurance: 0, payment: 3, business_account: 3, demat_account: 3 },
      { center: 'Arth-Sultanpur', credits: 9, insurance: 0, payment: 1, business_account: 1, demat_account: 1 },
      { center: 'Arth-Unchahar', credits: 3, insurance: 0, payment: null, business_account: null, demat_account: null },
      { center: 'Outside Center', credits: 0, insurance: 0, payment: 8, business_account: 8, demat_account: 8 },
    ];

    // PQ Data with random values
    this.pqData = [
      { bank_branches: 'Arth-Alwar', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Ayodhya', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Bachhrawan', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Delhi', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Jaipur', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Lucknow', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Pataudi', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Raebareli', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Sultanpur', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Unchahar', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
      { bank_branches: 'Arth-Unnao', on_time: Math.floor(Math.random() * 100), days_0_10: Math.floor(Math.random() * 30), days_11_30: Math.floor(Math.random() * 20), days_31_60: Math.floor(Math.random() * 15), days_61_90: Math.floor(Math.random() * 10), days_91_180: Math.floor(Math.random() * 5), days_180_plus: Math.floor(Math.random() * 3) },
    ];

    //custoemr_details
    this.customerDetailData = [
      { bank_branches: 'Arth-Alwar', occupation: { garments: 12, grocery: 23, other: 33 }, visits: { STL: 23, Repeat: 33, Business_prime_plus: 34 }, loans: { New: 23, Repeat: 33 }, gender: { male: 23, female: 34 } },
      { bank_branches: 'Arth-Ayodhya', occupation: { garments: 15, grocery: 25, other: 35 }, visits: { STL: 25, Repeat: 35, Business_prime_plus: 37 }, loans: { New: 25, Repeat: 35 }, gender: { male: 25, female: 30 } },
      { bank_branches: 'Arth-Bachhrawan', occupation: { garments: 18, grocery: 28, other: 40 }, visits: { STL: 28, Repeat: 35, Business_prime_plus: 38 }, loans: { New: 20, Repeat: 38 }, gender: { male: 24, female: 36 } },
      { bank_branches: 'Arth-Bhiwadi', occupation: { garments: 10, grocery: 20, other: 25 }, visits: { STL: 20, Repeat: 25, Business_prime_plus: 27 }, loans: { New: 22, Repeat: 27 }, gender: { male: 21, female: 30 } },
      { bank_branches: 'Arth-Delhi', occupation: { garments: 22, grocery: 30, other: 35 }, visits: { STL: 32, Repeat: 30, Business_prime_plus: 40 }, loans: { New: 30, Repeat: 40 }, gender: { male: 28, female: 32 } },
      { bank_branches: 'Arth-Jaipur', occupation: { garments: 25, grocery: 35, other: 40 }, visits: { STL: 30, Repeat: 40, Business_prime_plus: 43 }, loans: { New: 35, Repeat: 45 }, gender: { male: 35, female: 40 } },
      { bank_branches: 'Arth-Lucknow', occupation: { garments: 20, grocery: 25, other: 30 }, visits: { STL: 28, Repeat: 32, Business_prime_plus: 36 }, loans: { New: 30, Repeat: 38 }, gender: { male: 33, female: 36 } },
      { bank_branches: 'Arth-Pataudi', occupation: { garments: 18, grocery: 23, other: 28 }, visits: { STL: 22, Repeat: 30, Business_prime_plus: 35 }, loans: { New: 25, Repeat: 30 }, gender: { male: 24, female: 29 } },
      { bank_branches: 'Arth-Raebareli', occupation: { garments: 28, grocery: 35, other: 40 }, visits: { STL: 38, Repeat: 42, Business_prime_plus: 45 }, loans: { New: 35, Repeat: 45 }, gender: { male: 40, female: 43 } },
      { bank_branches: 'Arth-Sultanpur', occupation: { garments: 23, grocery: 27, other: 32 }, visits: { STL: 28, Repeat: 35, Business_prime_plus: 40 }, loans: { New: 32, Repeat: 38 }, gender: { male: 35, female: 37 } },
      { bank_branches: 'Arth-Unchahar', occupation: { garments: 10, grocery: 20, other: 22 }, visits: { STL: 22, Repeat: 28, Business_prime_plus: 30 }, loans: { New: 25, Repeat: 28 }, gender: { male: 20, female: 25 } }
    ];
    //customer_pq_matrix
    this.PQmatrixData = [
      { bank_branches: 'Arth-Alwar', 'Bad Behaviour RED CATEGORY': 195, 'Inconsistent Behaviour RED CATEGORY': 195, 'Non Starter': 7, 'High Risk': 223, 'Fast Track': 134 },
      { bank_branches: 'Arth-Ayodhya', 'Bad Behaviour RED CATEGORY': 22, 'Inconsistent Behaviour RED CATEGORY': 37, 'Non Starter': 0, 'High Risk': 27, 'Fast Track': 14 },
      { bank_branches: 'Arth-Sultanpur', 'Bad Behaviour RED CATEGORY': 27, 'Inconsistent Behaviour RED CATEGORY': 32, 'Non Starter': 0, 'High Risk': 28, 'Fast Track': 23 },
      { bank_branches: 'Arth-Bhiwadi', 'Bad Behaviour RED CATEGORY': 25, 'Inconsistent Behaviour RED CATEGORY': 18, 'Non Starter': 0, 'High Risk': 18, 'Fast Track': 20 },
      { bank_branches: 'Arth-Pataudi', 'Bad Behaviour RED CATEGORY': 6, 'Inconsistent Behaviour RED CATEGORY': 6, 'Non Starter': 1, 'High Risk': 4, 'Fast Track': 1 },
      { bank_branches: 'Arth-Lucknow', 'Bad Behaviour RED CATEGORY': 38, 'Inconsistent Behaviour RED CATEGORY': 37, 'Non Starter': 2, 'High Risk': 21, 'Fast Track': 20 },
      { bank_branches: 'Arth-Delhi', 'Bad Behaviour RED CATEGORY': 5, 'Inconsistent Behaviour RED CATEGORY': 1, 'Non Starter': 1, 'High Risk': 0, 'Fast Track': 1 },
      { bank_branches: 'Arth-Raebareli', 'Bad Behaviour RED CATEGORY': 35, 'Inconsistent Behaviour RED CATEGORY': 19, 'Non Starter': 1, 'High Risk': 32, 'Fast Track': 21 },
      { bank_branches: 'Arth-Unchahar', 'Bad Behaviour RED CATEGORY': 14, 'Inconsistent Behaviour RED CATEGORY': 11, 'Non Starter': 0, 'High Risk': 2, 'Fast Track': 0 },
      { bank_branches: 'Arth-Bachhrawan', 'Bad Behaviour RED CATEGORY': 9, 'Inconsistent Behaviour RED CATEGORY': 10, 'Non Starter': 0, 'High Risk': 13, 'Fast Track': 9 },
      { bank_branches: 'Arth-Jaipur', 'Bad Behaviour RED CATEGORY': 1, 'Inconsistent Behaviour RED CATEGORY': 0, 'Non Starter': 0, 'High Risk': 0, 'Fast Track': 0 }
    ];

    this.isLoading = false;
  }


renderRaderChart() {
  // Destroy previous charts
  this.raderCharts.forEach(chart => chart.destroy());
  this.raderCharts = [];

  setTimeout(() => {
    const colorPalettes = [
      ['#FF4560', '#008FFB', '#00E396', '#FEB019', '#775DD0'],
      ['#FF66C3', '#26A69A', '#FFCC00', '#546E7A', '#F4511E'],
      ['#C2185B', '#E91E63', '#9C27B0', '#3F51B5', '#2196F3'],
      ['#FFB74D', '#4CAF50', '#FF5722', '#607D8B', '#009688']
    ];

    const categories = Object.keys(this.PQmatrixData[0]).filter(key => key !== 'bank_branches');

    this.PQmatrixData.forEach((branch, index) => {
      if (this.selectedCenters.includes(branch.bank_branches)) {
        const dataValues = categories.map(category => branch[category]);

        const chartElementId = `rader-chart-center-${index}-0`;

        const chartInfo = {
          data: dataValues,
          labels: categories,
          name: branch.bank_branches,
          colors: colorPalettes[index % colorPalettes.length]
        };

        const options = {
          chart: {
            type: 'radar',
            height: 350,
            background: 'transparent',
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800
            }
          },
          series: [{
            name: chartInfo.name,
            data: chartInfo.data
          }],
          plotOptions: {
            radar: {
              size: 140,
              polygons: {
                strokeColor: '#e9e9e9',
                fill: {
                  colors: ['#f8f8f8', '#fff']
                }
              }
            }
          },
          title: {
            text: `PQ Matrix Radar for ${chartInfo.name}`
          },
          colors: chartInfo.colors,
          markers: {
            size: 6,
            colors: categories.map((_, i) => chartInfo.colors[i % chartInfo.colors.length]),
            strokeColors: chartInfo.colors,
            strokeWidth: 4
          },
          tooltip: {
            y: {
              formatter: (val: number) => `${val}`
            }
          },
          xaxis: {
            categories: chartInfo.labels,
            labels: {
              style: {
                fontSize: '14px',
                fontWeight: 'bold', // Bold font
                colors: chartInfo.colors // Category labels in respective marker colors
              }
            }
          },
          yaxis: {
            tickAmount: 7,
            labels: {
              formatter: (val: number, i: number) => {
                return i % 2 === 0 ? `${val}` : '';
              }
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#000'],
              fontSize: '12px'
            }
          }
        };

        const chartElement = document.querySelector(`#${chartElementId}`);

        if (chartElement) {
          const chart = new ApexCharts(chartElement, options);
          chart.render();
          this.raderCharts.push(chart);
        }
      }
    });
  }, 2000);
}



renderDonutChart() {
  const existingChart = document.querySelector('#raderchart');
  if (existingChart) {
    existingChart.innerHTML = '';
  }

  const options2 = {
    chart: {
      type: 'radar',
      height: 250,
    },
    series: [
      {
        name: "Team A", // Example series name
        data: [44, 55, 41, 17, 15], // Example data values
      },
      {
        name: "Team B", // Example series name
        data: [26, 21, 20, 6, 8], // Example data values
      }
    ],
    labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'], // Example labels for axes
    fill: {
      opacity: 0.5,
      colors: ['#FF4560', '#008FFB'], // Colors for filling
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['#e8e8e8'], // Stroke color
    },
    markers: {
      size: 5,
      hover: {
        size: 10, // Size of marker on hover
      }
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColor: '#e8e8e8',
          fill: {
            colors: ['#f8f8f8', '#fff'] // Colors for polygon fill
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      background: {
        enabled: true,
        borderRadius: 2,
      }
    }
  };

  const chart22 = new ApexCharts(existingChart, options2);
  chart22.render();
}

renderDonutChart2() {
  this.donutCharts.forEach(chart => chart.destroy());
  this.donutCharts = [];

  setTimeout(() => {
    const colorPalettes = ['#FF4560', '#008FFB', '#00E396', '#FEB019', '#775DD0'];

    this.customer_details.forEach((center, index) => {
      // if (this.selectedCenters.includes(center.bank_branches)){
        const occupation = JSON.parse(center.occupation);
        const visits = JSON.parse(center.visits);
        const loans = JSON.parse(center.loans);
        const gender = JSON.parse(center.gender);
        const chartData = [
          { data: Object.values(occupation), labels: Object.keys(occupation), name: 'Occupation' , colors: colorPalettes},
          { data: Object.values(visits), labels: Object.keys(visits), name: 'Visits', colors: colorPalettes},
          { data: Object.values(loans), labels: Object.keys(loans), name: 'Loans', colors: colorPalettes },
          { data: Object.values(gender), labels: Object.keys(gender), name: 'Gender', colors: colorPalettes}
        ];

        chartData.forEach((chartInfo, chartIndex) => {
          const options = {
            chart: {
              type: 'pie',
              height: 220,
            },
            series: chartInfo.data,
            labels: chartInfo.labels,
            colors: chartInfo.colors,
          };

          const chartElementId = `chart-center-${index}-${chartIndex}`;
          const chartElement = document.querySelector(`#${chartElementId}`);

          if (chartElement) {
            const chart = new ApexCharts(chartElement, options);
            chart.render();
            this.donutCharts.push(chart);
          }
        });
      // }
    });
  }, 200);
}



  filterDataByCenters() {
    if (this.selectedCenters.length === 0) {
      return this.funnelData;
    }
    return this.funnelData.filter(item => this.selectedCenters.includes(item.bank_branches));
  }

  formatBranchName(branchName: string): string {
    return branchName.replace(/\s+/g, '-');
  }


  getCardColor(index: number): string {
    const colorPalettes = [
      '#dcfce7', '#fadde7', '#fff4de', '#f3e8ff', '#edf6f9', '#ffc9b9',
       '#F479A5','#71C1F0','#79D5D4','#FBB467'
    ];
    return colorPalettes[index % colorPalettes.length] || '#000000';
  }
  onCenterChange() {
    this.cdr.detectChanges();
    this.renderDonutChart2();
    this.renderRaderChart();
    // console.log('chart_reset');
  }
}

