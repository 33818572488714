import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-business-overall-report',
  templateUrl: './credit-business-overall-report.component.html',
  styleUrls: ['./credit-business-overall-report.component.scss']
})

export class CreditBusinessOverallReportComponent {
    name='Master Business Overall Report'
    reportInformation  = 'Find all overdue , collection, outstanding data of all business in one place.';
  }


