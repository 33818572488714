import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-report',
  templateUrl: './insurance-report.component.html',
  styleUrls: ['./insurance-report.component.scss']
})
export class InsuranceReportComponent implements OnInit {

  config:any
  name='Credit Insurance Report'

  ngOnInit(): void {
    this.config=[
      {
        header:'Sr. No',
        field:'sr_no'
      },
      {
        header:'Lead ID',
        field:'lead_id'
      },
      {
        header:'Loan Type',
        field:'loan_type'
      },
      {
        header:'Customer UCIC',
        field:'customer_ucic'
      },
      {
        header:'Loan Tenure',
        field:'loan_tenure'
      },
      {
        header:'Loan UID',
        field:'loan_uid'
      },
      {
        header:'Loan Officer Name',
        field:'loan_officer_name'
      },
      {
        header:'Disbursement Date',
        field:'disbursement_date'
      },
      {
        header:'Loan Expiry Date',
        field:'loan_expiry_date'
      },
      {
        header:'ARTH Life Charge',
        field:'arth_life_charge'
      },
      {
        header:'ARTH Life GST',
        field:'arth_life_gst'
      },
      {
        header:'ARTH Credit Shield Charge',
        field:'arth_creditshield_charge'
      },
      {
        header:'ARTH Credit Shield GST',
        field:'arth_creditshield_gst'
      },
      {
        header:'Insurance Amount GST',
        field:'insurance_amount_gst'
      },
      {
        header:'Loan Amount',
        field:'loan_amount'
      },
      {
        header:'Purpose',
        field:'purpose'
      },
      {
        header:'Name',
        field:'name'
      },
      {
        header:'Date of Birth',
        field:'dob'
      },
      {
        header:'Age',
        field:'age'
      },
      {
        header:'Marital Status',
        field:'marital_status'
      },
      {
        header:'Gender',
        field:'gender'
      },
      {
        header:'Partner Type',
        field:'partner_type'
      },
      {
        header:'Lender Type',
        field:'lender_type'
      },
      {
        header:'Name of Nominee',
        field:'name_of_nominee'
      },
      {
        header:'Co-Borrower Name',
        field:'co_borrower_name'
      },
      {
        header:'Nominee Age',
        field:'nominee_age'
      },
      {
        header:'Mobile Number',
        field:'mobilenumber'
      },
      {
        header:'Address',
        field:'address'
      },
      {
        header:'Pincode',
        field:'pincode'
      },
      {
        header:'State',
        field:'state'
      },
      {
        header:'District',
        field:'district'
      },
      {
        header:'Branch',
        field:'branch'
      },
      {
        header:'Alternate Mobile',
        field:'alternate_mobile'
      },
      {
        header:'Status',
        field:'status'
      }
  ]

  }



}
