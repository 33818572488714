import { Component } from '@angular/core';

@Component({
  selector: 'app-kinara-customer-details-report',
  templateUrl: './kinara-customer-details-report.component.html',
  styleUrls: ['./kinara-customer-details-report.component.scss']
})
export class KinaraCustomerDetailsReportComponent {
  config:any
  name='Credit-Co-Lending KC  Customer Detail Report'
  ngOnInit(): void {
    this.config=[
      {
          header: 'Loan UID',
          field: 'loan_uid'
      },
      {
          header: 'Customer Branch ID',
          field: 'CUSTOMER_BRANCH_ID'
      },
      {
          header: 'Centre ID',
          field: 'CENTRE_ID'
      },
      {
          header: 'Centre Name',
          field: 'CENTRE_NAME'
      },
      {
          header: 'Date',
          field: 'DATE'
      },
      {
          header: 'Name of RO',
          field: 'NAMEOFRO'
      },
      {
          header: 'Identity Proof',
          field: 'IDENTITY_PROOF'
      },
      {
          header: 'Address Proof',
          field: 'ADDRESS_PROOF'
      },
      {
          header: 'Customer Type',
          field: 'CUSTOMER_TYPE'
      },
      {
          header: 'Email',
          field: 'EMAIL'
      },
      {
          header: 'Mobile No',
          field: 'MOBILE_NO'
      },
      {
          header: 'Gender',
          field: 'GENDER'
      },
      {
          header: 'Name',
          field: 'NAME'
      },
      {
          header: 'Door No',
          field: 'DOORNO'
      },
      {
          header: 'Street',
          field: 'STREET'
      },
      {
          header: 'Pincode',
          field: 'PINCODE'
      },
      {
          header: 'District',
          field: 'DISTRICT'
      },
      {
          header: 'State',
          field: 'STATE'
      },
      {
          header: 'Locality',
          field: 'LOCALITY'
      },
      {
          header: 'Village Name',
          field: 'VILLAGENAME'
      },
      {
          header: 'Date of Birth',
          field: 'DATE_OF_BIRTH'
      },
      {
          header: 'Post Office',
          field: 'POSTOFFICE'
      },
      {
          header: 'Address Proof Same as ID Proof',
          field: 'ADDRESS_PROOF_SAME_AS_ID_PROOF'
      },
      {
          header: 'Enrolled As',
          field: 'ENROLLED_AS'
      },
      {
          header: 'Mail Same as Residence',
          field: 'MAIL_SAME_AS_RESIDENCE'
      },
      {
          header: 'Identity Proof No',
          field: 'IDENTITY_PROOF_NO'
      },
      {
          header: 'Address Proof No',
          field: 'ADDRESS_PROOF_NO'
      },
      {
          header: 'Mailing Door No',
          field: 'MAILINGDOORNO'
      },
      {
          header: 'Mailing Locality',
          field: 'MAILING_LOCALITY'
      },
      {
          header: 'Mailing District',
          field: 'MAILING_DISTRICT'
      },
      {
          header: 'Mailing Pincode',
          field: 'MAILING_PINCODE'
      },
      {
          header: 'Mailing State',
          field: 'MAILINGSTATE'
      },
      {
          header: 'Verified',
          field: 'VERIFIED'
      },
      {
          header: 'PAN No',
          field: 'PAN_NO'
      },
      {
          header: 'Relation',
          field: 'RELATION'
      },
      {
          header: 'Education Status',
          field: 'EDUCATIONSTATUS'
      }
  ]
  }
}  