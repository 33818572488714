import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.scss']
})
export class CollectionReportComponent implements OnInit{
  config:any
  name= "Collection Report"
  
  ngOnInit(): void {
    this.config=[
      {
        header:'Loan Uid',
        field:'loan_uid'
      },
      {
        header:'Customer Code',
        field:'customer_code'
      },
      {
        header:'Customer Name',
        field:'name'
      },
      {
        header:'DOB',
        field:'dob'
      },

      {
        header:'Dpd Bucket',
        field:'dpd_bucket'
      },

      {
        header:'Dpd Days',
        field:'dpd_days'
      },
      {
        header:'Product Type',
        field:'product_type'
      },
      {
        header:'Paid Date',
        field:'paid_date'
      },
      // {
      //   header:'EMI Amount',
      //   field:'emi_amount'
      // },
      {
        header:'Due Date',
        field:'due_date'
      },
      {
        header:'Emi',
        field:'emi'
      },
      {
        header:'Gender',
        field:'gender'
      },
      {
        header:'Lead Id',
        field:'lead_id'
      },
      {
        header:'Amount Paid',
        field:'amount_paid_unchanged'
      },
      {
        header:'Principal Paid',
        field:'principal_paid'
      },
      {
        header:'Installment Paid',
        field:'installment_paid'
      },
      {
        header:'Interest Paid',
        field:'interest_paid'
      },
      {
        header:'Penalty Paid',
        field:'penalty_paid'
      },
      // {
      //   header:'Total Paid',
      //   field:'total_paid'
      // },
      {
        header:'Disbursement Date',
        field:'disbursement_date'
      },
      // {
      //   header:'Settle Mode',
      //   field:'settle_mode'
      // },
      {
        header:'Payment Mode',
        field:'payment_mode'
      },
      {
        header:'Loan Amount',
        field:'loan_amount'
      },
      {
        header:'Loan Status',
        field:'loan_status'
      },
      {
        header:'Net Disbursed',
        field:'net_disbursed'
      },
      {
        header:'Center',
        field:'center'
      },
      {
        header:'Cluster',
        field:'cluster'
      },
      // {
      //   header:'Enach Date',
      //   field:'enach_date'
      // },
      // {
      //   header:'Enach Token',
      //   field:'enach_token'
      // },
      {
        header:'Mobile',
        field:'mobile'
      },
      {
        header:'Partner',
        field:'partner_type'
      },
      {
        header:'Product Code',
        field:'product_code'
      },
      {
        header:'Lender Type',
        field:'lender_type'
      }
      ,
      {
        header:'Report Date',
        field:'report_date'
      }

    ]
  }

}