import { Component } from '@angular/core';

@Component({
  selector: 'app-kinara-disbursement-details-report',
  templateUrl: './kinara-disbursement-details-report.component.html',
  styleUrls: ['./kinara-disbursement-details-report.component.scss']
})
export class KinaraDisbursementDetailsReportComponent {
  name='Kinara Disbursement Detail Report'
  reportInformation  = 'This report provides a detailed of the disbursement of funds to Kinara beneficiaries.'

}



  


