import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-hlp-report',
  templateUrl: './test-hlp-report.component.html',
  styleUrls: ['./test-hlp-report.component.scss']
})
export class TestHlpReportComponent implements OnInit {

  config: any
  name = 'HLP Report'

  ngOnInit(): void {

    this.config = [
      {
        header: 'Customer Id',
        field: 'Cust Id'
      },
      {
        header: 'Name',
        field: 'Name'
      },
      {
        header: 'Dob',
        field: 'dob'
      },
      {
        header: 'Gender',
        field: 'gender'
      },
      {
        header: 'Occupation',
        field: 'occupation'
      }, 
      {
        header: 'Lender/Institution Type',
        field: 'Lender/Institution Type'
      }, 
      {
        header: 'Lending Model',
        field: 'Lending Model'
      }, 
      {
        header: 'Partner name',
        field: 'Partner name'
      }, 
      {
        header: 'Product Type',
        field: 'Product Type'
      }, 
      {
        header: 'Amount',
        field: 'amount'
      }, 
      {
        header: 'Date Stamp',
        field: 'Date Stamp'
      }, 
      {
        header: 'Tenure Months',
        field: 'Tenure Months'
      }, 
      {
        header: 'Pos',
        field: 'pos'
      }, 
      {
        header: 'Aum',
        field: 'aum'
      },
      {
        header: 'Center',
        field: 'center'
      },
      {
        header: 'Cluster',
        field: 'Cluster'
      },
      {
        header: 'District',
        field: 'district'
      },
      {
        header: 'State',
        field: 'state'
      },
    ]

  }

}


// ["Cust Id",
//   "Name",
//   "dob",
//   "gender",
//   "occupation",
//   "Lender/Institution Type",
//   "Lending Model",
//   "Partner name",
//   "Product Type",
//   "amount",
//   "Date Stamp",
//   "Tenure Months",
//   "pos",
//   "aum",
//   "center",
//   "Cluster",
//   "district",
//   "state"]