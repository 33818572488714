import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms'
import { AuthService } from 'src/app/services/auth.service';
import { Router} from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit{
  loginForm: FormGroup ;
  result:any=[];
  errorMessage: string = '';

  ngOnInit(): void {
    this.loginForm=new FormGroup({
      'emailId': new FormControl(null),
      'password': new FormControl(null),

    })
  }


 constructor(
  private loginService:AuthService,
  private matIconRegistry: MatIconRegistry,
  private domSanitizer: DomSanitizer,
  private ngxPermissionsService:NgxPermissionsService,
  public router:Router){
    this.matIconRegistry.addSvgIcon(
      'Arth',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Arth.svg')
    )

  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }

    this.loginService.login(this.loginForm.value).subscribe(
      (res: any) => {
        //console.log("result", res);
        this.result = res;
        //console.log(this.result.token);
        localStorage.setItem('refereshToken', this.result.token);
        localStorage.setItem('freshDeskToken', this.result.freshdesk_token);
        localStorage.setItem('role', this.result.role);
        this.ngxPermissionsService.loadPermissions(this.result.permissions);
        if (res.status === 'ok') {
          this.router.navigate(['/dashboard']);
        }
      },
      (error) => {
        if (error.status === 400) { // Unauthorized error
          this.errorMessage = 'Invalid email or password!'; // Set error message
        } else {
          this.errorMessage = 'An error occurred. Please try again later!'; // Generic error message
        }
      }
    );
  }
}
