<div *ngIf="isLoading" class="loading-container">
  <iframe src="https://lottie.host/embed/3a024223-432e-4360-bc86-d5488b6b2c41/qTvNOnZBAo.json"
  class="large-iframe"                     ></iframe>
</div>

<div *ngIf="!isLoading && !errorMessage"  class="dashboard3-container">
  <div class = 'title_p'>
    <p><b>HLP Partner Performance {{currentMonth}}  {{currentYear}}</b></p>
  </div>
  <div class = 'hlp_partner_projection'>
    <div class="stack_hor_chart">
      <div id = "partner_stack_chart"></div>
    </div>
    <div class="stack_ver_chart">
      <div  id = "partner_collumn_chart"></div>
    </div>
  </div>
  <div class = 'title_p'>
    <p><b>NP - RP Customer projection {{currentMonth}}  {{currentYear}}</b></p>
  </div>
  <div class="np_rp_partner_projection">
    <div class="stack_hor_chart">
      <div id = "np_rp_stack_chart"></div>
    </div>
    <div class="stack_ver_chart">
      <div  id = "np_rp_collumn_chart"></div>
    </div>
  </div>
</div>
