<div class="container ms-5">
  <!-- Search Section -->
  <div class="search-container">
    <div class="search-box">
      <input [(ngModel)]="yourModel" class="search-input" placeholder="Search users..." />
      <mat-icon (click)="searchKeyword()" class="icon search-icon">search</mat-icon>
    </div>
    <div class="action-buttons">
      <button mat-raised-button color="primary" class="action-btn" (click)="downloadXlsx()">
        <mat-icon>download</mat-icon> Download
      </button>
      <button mat-raised-button color="accent" class="action-btn" routerLink="/home/users/create-new-user">
        <mat-icon>add</mat-icon> New User
      </button>
    </div>
  </div>

  <!-- Filter Section -->
  <div class="filter-container">
    <button mat-button color="primary" class="filter-btn" (click)="filterAll()">All</button>
    <mat-form-field appearance="outline" class="filter-dropdown">
      <mat-select [(ngModel)]="selectedRole" (selectionChange)="filterByRole()">
        <mat-option value="">Select Role</mat-option>
        <mat-option *ngFor="let role of roles" [value]="role.name">{{ role.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="filter-dropdown">
      <mat-select placeholder="Campaigns">
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign">{{ campaign }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- List Section -->
  <div class="list-container">
    <div class="list-item" *ngFor="let item of list" (click)="editUser(item)">
      <span class="user-name">{{ item.name | uppercase }}</span>
      <span class="user-role">{{ item.role | uppercase }}</span>
    </div>
  </div>
</div>
