<div class="d-flex">
    <nav>
        <ol class="breadcrumb justify-content-center ps-0">
            <mat-icon class="chevron ms-5" (click)="goBack()">chevron_left</mat-icon>
            <li>Welfare Module</li>
        </ol>
    </nav>
</div>
<div class="container-fluid outer-padding">
    <div class="row below-content justify-content-center">
        <label class="heading">Reports</label>
        <div *ngFor="let report of reports | keyvalue" class="col-lg-5 p-5 m-5 border report" style="cursor: pointer;" (click)="reportClicked(report.key)">{{report.key}}

        </div>
    </div>
 </div>