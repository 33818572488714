<!-- <div class="container-fluid h-50 d-flex flex-column">
    <div class="row  h-0 top-content">hi
    </div>
    <div class="row flex-grow-1 ">
        <div class="container below-content">
inside
        </div>
    </div>
</div> -->
<!-- <div class="container-fluid ">
    <div class="container-fluid top-content">Col1
    </div>
    <div class="container-fluid">Col2</div>


</div>

 -->
 <!-- <div class="container-fluid h-75 d-flex flex-column">
    <div class="row h-50 top-content"></div>
    <div class="container row flex-grow-1 ">
        <div class=" below-content">

        </div>
    </div>

 </div> -->

 <!-- <label class="heading">Dashboard</label> -->
 <div class="container-fluid top-content h-25 d-flex flex-column">
</div>

<div class="container-fluid outer-padding">
   <div class="row below-content justify-content-center">
       <label class="heading">Bussiness Categories</label>
       <ng-container *ngFor="let module of modules">
           <div *ngxPermissionsOnly="module.permission" [ngClass]="{'grey-module': module.name === 'HR' || module.name === 'Campaign Management' || module.name === 'Products' || module.name === 'Welfare' || module.name === 'Partnerships'  || module.name === 'Marketing'}" style="cursor: pointer;" class="col-md-3 p-5 m-3 border module" (click)="moduleClicked(module.name)">
               {{module.name}}
           </div>
       </ng-container>
   </div>
</div>
