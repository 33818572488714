import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({providedIn:'root'})

export class RoleService{

    store:any=[]
    private apiBaseUrl = environment.apiUrl

    constructor(private http:HttpClient){

    }



    esHeaders() {
		let headers = new HttpHeaders();
		let accessToken:any = localStorage.getItem('refereshToken');
        // //console.log(accessToken)
		if (accessToken) {
			headers = headers.set('Authorization', `b ${accessToken}`);
		}
		return {headers};
	}

    fetchRoles(){
    return this.http.get(this.apiBaseUrl + 'roles',this.esHeaders())
    }

    createNewRole(data:any){
        //console.log('create new role',data)
        return this.http.post(this.apiBaseUrl+'roles',data,this.esHeaders())
    }

    updateRole(data:any){
        return this.http.patch(this.apiBaseUrl+'roles/'+data.role_name,data,this.esHeaders())
    }

    fetchSpecificRole(data:any){
    return this.http.get(this.apiBaseUrl +'roles'+`/${data}`,this.esHeaders())

    }

}
