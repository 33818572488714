import { Component, OnInit} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isCreateUser:boolean
  isCreateRole:boolean


  ngOnInit(): void {
  }

  // temoprary need to fix the other components , so that it can render it here
  navigateTo(route: string): void {
    this.router.navigate([`/${route}`]);
    //console.log('navigating to :',`/${route}`)
  }
  logout(){
    localStorage.removeItem('refereshToken');
    localStorage.removeItem('freshDeskToken');
    this.router.navigate([''])
  }


  constructor(
    private authService:AuthService,
    private router: Router
  ){}



  currentDashboardIndex = 0;
  dashboards = ['Daily Funnel Board', 'Customer Projection', 'Partner Performance', 'Bussiness Prime Projection', 'Center Performance'];

  previousDashboard() {
    if (this.currentDashboardIndex > 0) {
      this.currentDashboardIndex--;
    }
  }

  nextDashboard() {
    if (this.currentDashboardIndex < this.dashboards.length - 1) {
      this.currentDashboardIndex++;
    }
  }

  onDropdownChange(event: any) {
    this.currentDashboardIndex = +event.target.value;
  }
}
