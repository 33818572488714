import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { FetchReport } from 'src/app/services/reports.service';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { LoadPermissionService } from 'src/app/services/load-permission.service';
// import * as XLSX from 'xlsx';
import { tap } from 'rxjs/operators';
import { SpinnerOverlayService } from '../spinner-overlay-service.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {
  loading = false;
  loadingMessage: string;
  @Input() config: any;
  @Input() report_name: any;
  searchreports = false
  reportSelected: any

  response: any = []

  currentReport: string
  currentModule: string
  filterReport: FormGroup;

  reports: any = {
    disbursement_details_report: false,credit_business_overall_report: false,credit_repayment_overall_report: false,credit_repayment_plus_report: false, customer_journey_drop_report: false,goat_trust_report: false, insurance_report: false,collection_report: false, demand_sheet_report: false,
    customer_details_report: false, lead_to_loan_disbursment_report: false, ice_response_report: false, rejection_response: false,credit_business_plus_report:false, arth_static_lms_report: false,
    credit_funel_tracking_report: false,credit_evaluation_report: false, welfare_report: false, hlp_report: false,hlp_hotlead_report: false,insurance_bank_details_report: false, master_data_report: false,
    test_collection_report: false, test_disbursement_report: false, test_hlp_report: false, ckyc_report: false,credit_reconciliation_report : false,
    kinara_business_details_report: false,kinara_customer_details_report: false,kinara_disbursement_details_report: false,eligibility_check_failed_customer_report :false, loan_collection_report :false,pre_demand_monthly_report : false,credit_due_demand_report : false
  }
  showDate: boolean = false
  showTncDate: boolean = false
  showPaymentDate: boolean = false
  showPaymentUpdateDate: boolean = false
  showProductCode: boolean = false
  showCenter: boolean = false
  showCustomerUcid: boolean = false
  showLoanUid: boolean = false
  showDueDate: boolean = false
  showPaidDate: boolean = false
  showBankName: boolean = false
  showCluster: boolean = false
  showPincode: boolean = false
  showHlp: boolean = false
  showCpId: boolean = false
  showLoanStatus: boolean = false
  showCreditScore: boolean = false
  showSubOccupation: boolean = false
  showPartner: boolean = false
  showClc: boolean = false
  showLeadId: boolean = false
  showEligibilityDate: boolean = false
  showHotleadLeadId: boolean = false
  showSourcingPersonLeadId: boolean = false
  showDpd: boolean = false
  showMobile: boolean = false
  showPaymentMode: boolean = false
  showProductType: boolean = false
  showLenderType: boolean = false
  showClcStatus: boolean = false
  showCafStatus: boolean = false
  showDisbursedDate: boolean = false
  showLatestDueDate: boolean = false
  showLeadDate: boolean = false
  currentPage = 2;
  itemsPerPage = 10;
  p: number = 1;

  clc_names = []
  branches = []
  partners = []
  loan_status = []
  product_code = []
  clusters = []
  product_type = []
  lender_type = []
  caf_status =[]
  clc_status = []
  payment_mode = []
  dpd_bucket = []

  constructor(private router: Router,
    private activatdRoute: ActivatedRoute,
    private fetchReport: FetchReport,
    private spinnerService:SpinnerOverlayService,
    private loadPermission: LoadPermissionService) {

  }
  ngOnInit(): void {
    this.activatdRoute.queryParamMap.subscribe(qParam => {
      this.reportSelected = qParam.get('report')
      // if (this.reportSelected === 'ckyc_report') {
      //   this.searchreports = true
      //   this.fetchReport.fetchCKYCReport(this.reportSelected, 'ckyc').subscribe(res => {
      //     this.response = res
      //     //console.log(res)
      //   })
      // }
      this.getFilters()
      this.currentReport = String(qParam.get('report')).split('_').map(a => { return a.charAt(0).toUpperCase() + a.slice(1) }).join(" ")
      this.currentModule = String(qParam.get('module')).split('_').join(' ')
      for (let report in this.reports) {
        //console.log(report)
        if (report == this.reportSelected) {
          this.reports[report] = true
          //console.log(this.reports)
        }
      }
    })

    this.filterReport = new FormGroup({
      from_date: new FormControl(null, []),
      to_date: new FormControl(null, []),
      due_from_date: new FormControl(null, []),
      due_to_date: new FormControl(null, []),
      disbursed_from_date: new FormControl(null, []),
      disbursed_to_date: new FormControl(null, []),
      paid_from_date: new FormControl(null, []),
      paid_to_date: new FormControl(null, []),
      payment_from_date: new FormControl(null, []),
      payment_to_date: new FormControl(null, []),
      product_code: new FormControl(null, []),
      bank_name: new FormControl(null, []),
      loan_status: new FormControl(null, []),
      cluster: new FormControl(null, []),
      center: new FormControl(null, []),
      customer_ucic: new FormControl(null, []),
      loan_uid: new FormControl(null, []),
      mobile: new FormControl(null, []),
      clc_name: new FormControl(null, []),
      cp_id: new FormControl(null, []),
      lead_id: new FormControl(null, []),
      hotlead_lead_id: new FormControl(null, []),
      sourcing_person_id: new FormControl(null, []),
      sub_occupation: new FormControl(null, []),
      partner: new FormControl(null, []),
      credit_score: new FormControl(null, []),
      pincode: new FormControl(null, []),
      product_type: new FormControl(null, []),
      lender_type: new FormControl(null, []),
      clc_status: new FormControl(null, []),
      caf_status: new FormControl(null, []),
      hlp: new FormControl(null, []),
      dpd_bucket: new FormControl(null, []),
      payment_mode: new FormControl(null, []),
      latest_due_from_date: new FormControl(null, []),
      latest_due_to_date: new FormControl(null, []),
      lead_from_date: new FormControl(null, []),
      lead_to_date: new FormControl(null, []),
      eligibility_check_from_date: new FormControl(null, []),
      eligibility_check_to_date: new FormControl(null, []),
      tnc_accepted_from_date: new FormControl(null, []),
      tnc_accepted_to_date: new FormControl(null, []),
      showLoanStatus: new FormControl(null, []),



    })
    //this.showBankName = !this.reports.disbursement_details_report && !this.reports.insurance_bank_details_report
    this.showBankName = !this.reports.customer_details_report && !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report && !this.reports.customer_journey_drop_report && !this.reports.credit_due_demand_report && !this.reports.disbursement_details_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report && !this.reports.credit_reconciliation_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report
    //this.showProductCode = this.reports.disbursement_details_report || this.reports.pre_demand_monthly_report || this.reports.credit_funel_tracking_report || this.reports.master_data_report || this.reports.lead_to_loan_disbursment_report && !this.reports.credit_funel_tracking_report
    this.showDate = !this.reports.customer_details_report &&  !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.credit_reconciliation_report && !this.reports.loan_collection_report && !this.reports.credit_evaluation_report && !this.reports.master_data_report && !this.reports.credit_funel_tracking_report && !this.reports.credit_due_demand_report &&!this.reports.pre_demand_monthly_report && !this.reports.insurance_report && !this.reports.ckyc_report && !this.reports.disbursement_details_report &&!this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report
    !this.reports.disbursement_details_report && !this.reports.credit_reconciliation_report && !this.reports.loan_collection_report && !this.reports.customer_details_report
    this.showPaidDate = !this.reports.customer_details_report &&  !this.reports.credit_business_plus_report && !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.eligibility_check_failed_customer_report &&  !this.reports.credit_evaluation_report && !this.reports.master_data_report && !this.reports.credit_funel_tracking_report && !this.reports.credit_due_demand_report && !this.reports.pre_demand_monthly_report && !this.reports.credit_reconciliation_report && !this.reports.insurance_report && !this.reports.ckyc_report &&  !this.reports.disbursement_details_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report
    !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report
    !this.reports.disbursement_details_report && !this.reports.credit_reconciliation_report  && !this.reports.customer_details_report

    this.showDisbursedDate = !this.reports.master_data_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report &&!this.reports.eligibility_check_failed_customer_report && !this.reports.credit_evaluation_report && !this.reports.credit_due_demand_report && !this.reports.pre_demand_monthly_report && !this.reports.disbursement_details_report  &&  !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report
    && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report && !this.reports.credit_reconciliation_report && !this.reports.loan_collection_report && !this.reports.customer_details_report

    this.showProductCode = !this.reports.customer_details_report &&  !this.reports.customer_details_report &&  !this.reports.credit_business_plus_report &&!this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&   !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.eligibility_check_failed_customer_report &&  !this.reports.credit_evaluation_report && !this.reports.master_data_report && !this.reports.credit_funel_tracking_report && !this.reports.credit_due_demand_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report && !this.reports.disbursement_details_report && !this.reports.test_collection_report && !this.reports.credit_reconciliation_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report && !this.reports.loan_collection_report
    this.showDueDate =   !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.credit_reconciliation_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.credit_due_demand_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.ckyc_report && !this.reports.disbursement_details_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.disbursement_details_report && !this.reports.credit_reconciliation_report  && !this.reports.customer_details_report

    this.showPaymentDate = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report &&  !this.reports.arth_static_lms_report && !this.reports.disbursement_details_report && this.reports.credit_reconciliation_report
    this.showPaymentUpdateDate =!this.reports.credit_business_plus_report &&  !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.arth_static_lms_report && !this.reports.disbursement_details_report && this.reports.credit_reconciliation_report
    this.showCluster =!this.reports.customer_details_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.credit_reconciliation_report && !this.reports.credit_evaluation_report && !this.reports.insurance_report && !this.reports.ckyc_report &&  !this.reports.disbursement_details_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.ckyc_report
    this.showCustomerUcid =!this.reports.credit_business_plus_report &&  !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&   !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report &&!this.reports.eligibility_check_failed_customer_report && !this.reports.master_data_report && !this.reports.credit_funel_tracking_report && !this.reports.credit_due_demand_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report && !this.reports.ckyc_report &&  !this.reports.disbursement_details_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.hlp_hotlead_report && !this.reports.hlp_report && !this.reports.loan_collection_report
    this.showLoanUid = !this.reports.customer_details_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.eligibility_check_failed_customer_report &&  !this.reports.credit_evaluation_report && !this.reports.ckyc_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report
    this.showClc = !this.reports.credit_business_overall_report && !this.reports.credit_business_plus_report &&   !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&   !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report &&!this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report && !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report
    //this.showCluster = !this.reports.insurance_report && !this.reports.disbursement_details_report && !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    //this.showCustomerUcid = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    //this.showLoanAccountNumber = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    //this.showClc = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    this.showLeadId =!this.reports.customer_details_report &&  !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&!this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.credit_due_demand_report && !this.reports.insurance_report && !this.reports.ckyc_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.master_data_report
    this.showCpId =!this.reports.credit_business_plus_report &&  !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report && !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report

    //this.showCpId = this.reports.goat_trust_report || this.reports.insurance_report || this.reports.lead_to_loan_disbursment_report || this.reports.welfare_report
    //this.showLeadId = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    this.showPartner = !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report &&!this.reports.credit_evaluation_report  && !this.reports.pre_demand_monthly_report && !this.reports.ckyc_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.loan_collection_report && !this.reports.credit_reconciliation_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report
    this.showSubOccupation = !this.reports.credit_business_plus_report &&   !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report &&  !this.reports.customer_journey_drop_report && !this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.insurance_report && !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_bank_details_report && !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report

    this.showMobile =!this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report  && !this.reports.ckyc_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report  && !this.reports.credit_funel_tracking_report

    //this.showPartner = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    this.showCreditScore = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&this.reports.ice_response_report
    this.showPincode = !this.reports.customer_details_report && !this.reports.credit_business_plus_report &&  !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.credit_due_demand_report && !this.reports.ckyc_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report&& !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_bank_details_report && !this.reports.insurance_report && !this.reports.master_data_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report  && !this.reports.hlp_report
    //this.showPincode = this.reports.lead_to_loan_disbursment_report || this.reports.customer_details_report || this.reports.welfare_report || this.reports.hlp_report || !this.reports.insurance_bank_details_report || !this.reports.master_data_report
    this.showHlp = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report &&!this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.insurance_report && !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_bank_details_report && !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report

    //this.showHlp = this.reports.lead_to_loan_disbursment_report || this.reports.insurance_report || this.reports.goat_trust_report || this.reports.hlp_report || this.reports.customer_details_report || this.reports.demand_sheet_report || this.reports.master_data_report
    this.showDpd = !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.loan_collection_report &&  !this.reports.disbursement_details_report && !this.reports.credit_reconciliation_report && !this.reports.insurance_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report
    //this.showDpd = this.reports.master_data_report || this.reports.demand_sheet_report && !this.reports.ckyc_report
    this.showPaymentMode = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && this.reports.collection_report && !this.reports.ckyc_report
    this.showProductType = !this.reports.master_data_report && !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&   !this.reports.credit_business_overall_report && !this.reports.customer_journey_drop_report &&  this.reports.master_data_report || this.reports.lead_to_loan_disbursment_report || this.reports.insurance_bank_details_report
    this.showCenter = !this.reports.customer_details_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.credit_reconciliation_report && !this.reports.credit_evaluation_report && !this.reports.insurance_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report  && !this.reports.disbursement_details_report && !this.reports.ckyc_report

    //this.showCenter = !this.reports.credit_reconciliation_report || !this.reports.test_collection_report || !this.reports.test_disbursement_report || !this.reports.test_hlp_report || !this.reports.ckyc_report
    this.showLenderType =  !this.reports.master_data_report && !this.reports.credit_business_plus_report &&  !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&   !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report && !this.reports.credit_evaluation_report && !this.reports.credit_due_demand_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.loan_collection_report && !this.reports.credit_reconciliation_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.credit_funel_tracking_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report
    //this.showProductType = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    //this.showLenderType = !this.reports.test_collection_report && !this.reports.test_disbursement_report && !this.reports.test_hlp_report && !this.reports.ckyc_report
    this.showSourcingPersonLeadId = !this.reports.credit_business_plus_report &&!this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report &&!this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report
    this.showHotleadLeadId = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report &&!this.reports.credit_due_demand_report &&  !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report
    this.showCafStatus = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&   !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report
    this.showClcStatus =!this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report

    this.showLatestDueDate = !this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report && !this.reports.customer_journey_drop_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report
    this.showLeadDate =!this.reports.credit_business_plus_report && !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report &&  !this.reports.hlp_report &&  !this.reports.customer_journey_drop_report &&!this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report

    this.showEligibilityDate = !this.reports.credit_business_plus_report &&   !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&!this.reports.customer_journey_drop_report && !this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report

    this.showTncDate = !this.reports.credit_business_plus_report &&  !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report &&  !this.reports.credit_business_overall_report &&  !this.reports.hlp_hotlead_report  && !this.reports.hlp_report &&  !this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report &&!this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report

    this.showLoanStatus = !this.reports.credit_repayment_overall_report && !this.reports.credit_repayment_plus_report && !this.reports.credit_business_overall_report && !this.reports.hlp_hotlead_report && !this.reports.hlp_report && !this.reports.customer_journey_drop_report &&!this.reports.credit_due_demand_report && !this.reports.arth_static_lms_report && !this.reports.kinara_business_details_report && !this.reports.kinara_customer_details_report && !this.reports.kinara_disbursement_details_report &&
    !this.reports.credit_reconciliation_report && !this.reports.disbursement_details_report && !this.reports.loan_collection_report && !this.reports.pre_demand_monthly_report && !this.reports.insurance_report &&  !this.reports.master_data_report && !this.reports.ckyc_report && !this.reports.customer_details_report && !this.reports.eligibility_check_failed_customer_report && !this.reports.lead_to_loan_disbursment_report && !this.reports.credit_evaluation_report && !this.reports.credit_funel_tracking_report


  }

  getFilters() {
    this.loading = true; // Show loader while waiting for the response

    this.fetchReport.getFilters(this.reportSelected).subscribe((res: any) => {
      this.clc_names = res.clc_names
      this.partners = res.partners
      this.loan_status = res.loan_status
      this.product_code = res.products
      this.clusters = res.clusters
      this.product_type = res.product_type,
      this.lender_type = res.lender_type
      this.clc_status = res.clc_status
      this.dpd_bucket = res.dpd_bucket
      this.payment_mode = res.payment_mode
      this.branches=res.centers
      this.loading=false
    })
    this.loadPermission.loadPermissions().subscribe((data: any) => {
      // this.branches = data.branches
    })

  }

  onSubmit() {
    const loadingMessage = 'Downloading...';
    this.spinnerService.show(loadingMessage);
    this.searchreports = true
    //console.log(this.filterReport.value)
    let data: any = {}
    for (let obj in this.filterReport.value) {
      if (obj === 'center') {
        data['print_branch_name'] = this.filterReport.value[obj]
        data['branch'] = this.filterReport.value[obj]
      }
      if (obj === 'clc_name') {
        data['loanofficername'] = this.filterReport.value[obj]
        data['employee_name'] = this.filterReport.value[obj]
      }
      if (obj === 'partner') {
        data['partner_type'] = this.filterReport.value[obj]
      }
      if (this.filterReport.value[obj]) {
        if (obj === 'from_date' || obj === 'to_date') {
          data[obj] = moment(this.filterReport.value[obj]).format('YYYY-MM-DD')

        }
        else {
          data[obj] = this.filterReport.value[obj]
        }
      }
    }

    let report = this.reportSelected
    let filters: any = {}
    filters[report] = data
      this.fetchReport.fetchReport(this.reportSelected, filters).subscribe(res => {
        this.response = res
        this.p=1
        this.spinnerService.hide();
      })
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  goBack() {
    window.history.back()
  }



  onDateSelected(value: any, event: any) {
    if(event.value)
    this.filterReport.value[value] = moment(event.value).format('YYYY-MM-DD')
  }

  downloadCkycReport(url: any) {
    if (url) {
      window.location.href = url
    }
  }

  generateCkyc() {
    let data: any = {}
    let filters:any={}
    for (let obj in this.filterReport.value) {
      if (this.filterReport.value[obj]) {
      if (obj === 'from_date' || obj === 'to_date') {
        data[obj] = moment(this.filterReport.value[obj]).format('YYYY-MM-DD')
      }
    }
    }
    filters[this.reportSelected]=data
    this.fetchReport.generateCkyc(filters).subscribe((res) => {
      this.onSubmit()
    },
    (error) => {
      console.error("Error in generateCkyc API call:", error);
      this.onSubmit()
    })
  }

  onPageChange(event: any) {
    //console.log(event)
  }

downloadXlsx() {
  const loadingMessage = 'Downloading...';
  this.spinnerService.show(loadingMessage);


  let filters: any = {}
  let data: any = {}
  for (let obj in this.filterReport.value) {
    if (this.filterReport.value[obj]) {
      //console.log(obj)
      data[obj] = this.filterReport.value[obj]
    }
  }
  let report = this.reportSelected
  filters[report] = data
  this.fetchReport.downloadXlsx(this.currentModule.split(" ")[0].toLowerCase(), this.reportSelected, filters).subscribe(
    (response: any) => {
      try {
        const responseData = JSON.parse(response);
        const s3Link = responseData.response;
        //console.log('s3 link is:', s3Link)
        if (s3Link) {
          // Download the file from the S3 link
          this.downloadFileFromS3(s3Link, `${this.reportSelected}.xlsx`);
        } else {
          console.error('S3 link not found in response');
        }
      } finally {
        // Hide spinner overlay after API response is received
        this.spinnerService.hide();
      }
    },
    (error: any) => {
      console.error('Error fetching report:', error);
      // Hide spinner overlay if there's an error
      this.spinnerService.hide();
    }
  );
}

downloadFileFromS3(s3Link: string, filename: string){
  fetch(s3Link)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // Set the file name
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error downloading file:', error);
      // Handle error
    });
}

}
