import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ckyc-report',
  templateUrl: './ckyc-report.component.html',
  styleUrls: ['./ckyc-report.component.scss']
})
export class CkycReportComponent implements OnInit{
  config:any
   
  name='CKYC Report'

  ngOnInit(): void {

  this.config=[
  {
      header: 'Serial Number',
      field: 'serial_number'
  },
  {
      header: 'Customer Code',
      field: 'customer_code'
  },
  {
      header: 'Partner Code',
      field: 'partner_code'
  },
  {
      header: 'Branch Code',
      field: 'branch_code'
  },
  {
      header: 'Lead ID',
      field: 'lead_id'
  },
  {
      header: 'Name Title',
      field: 'name_title'
  },
  {
      header: 'Customer Name',
      field: 'customer_name'
  },
  {
      header: 'Father Name',
      field: 'father_name'
  },
  {
      header: 'Mother Name',
      field: 'mother_name'
  },
  {
      header: 'DOB',
      field: 'dob'
  },
  {
      header: 'Gender',
      field: 'gender'
  },
  {
      header: 'Marital Status',
      field: 'marital_status'
  },
  {
      header: 'Nationality',
      field: 'nationality'
  },
  {
      header: 'Customer Residence Type',
      field: 'customer_residence_type'
  },
  {
      header: 'Occupation',
      field: 'occupation'
  },
  {
      header: 'Home Address',
      field: 'home_address'
  },
  {
      header: 'Address-2',
      field: 'add2'
  },
  {
      header: 'Address-3',
      field: 'add3'
  },
  {
      header: 'City',
      field: 'city'
  },
  {
      header: 'Pincode',
      field: 'pincode'
  },
  {
      header: 'Mobile No',
      field: 'mobile_no'
  },
  {
      header: 'Alternate No',
      field: 'alternate_no'
  },
  {
      header: 'Fax',
      field: 'fax'
  },
  {
      header: 'Email ID',
      field: 'email_id'
  },
  {
      header: 'Aadhar',
      field: 'aadhar'
  },
  {
      header: 'PAN',
      field: 'pan'
  },
  {
      header: 'Driving Licence',
      field: 'driving_licence'
  },
  {
      header: 'Voter ID',
      field: 'voter_id'
  },
  {
      header: 'Passport',
      field: 'passport'
  },
  {
      header: 'Other ID',
      field: 'other_id'
  },
  {
      header: 'Annual Income',
      field: 'annual_income'
  },
  {
      header: 'CAF PDF Link',
      field: 'caf_pdf_link'
  },
  {
      header: 'NACH Mandate Link',
      field: 'nach_mandate_link'
  },
  {
      header: 'Bank Passbook',
      field: 'bank_passbook'
  },
  {
      header: 'POA Link',
      field: 'poa_link'
  },
  {
      header: 'Financial Literacy',
      field: 'financial_literacy'
  },
  {
      header: 'Kalpataru Form',
      field: 'kalpataru_form'
  },
  {
      header: 'Other Doc',
      field: 'other_doc'
  },
  {
      header: 'POA Front Link',
      field: 'poa_front_link'
  },
  {
      header: 'POA Back Link',
      field: 'poa_back_link'
  },
  {
      header: 'POI Front Link',
      field: 'poi_front_link'
  },
  {
      header: 'POI Back Link',
      field: 'poi_back_link'
  },
  {
      header: 'KYC Verification Emp Code',
      field: 'kycverificationempcode'
  },
  {
      header: 'KYC Verification Emp Name',
      field: 'kycverificationempname'
  },
  {
      header: 'KYC Verification Place',
      field: 'kycverificationplace'
  },
  {
      header: 'Designation',
      field: 'designation'
  }  
    
    // {
    //   header:'ID',
    //   field:'id'
    // },
    // {
    //   header:'ckyc_name',
    //   field:'ckyc_name'
    // },
    // {
    //   header:'Created At',
    //   field:'created_at'
    // },
    // {
    //   header:'Download Report',
    //   field:'url'
    // }
  ]
  }

}
// id, url, created_at