import { NgModule } from '@angular/core';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CreditReconciliationReportComponent } from '../finance-module/credit-reconciliation-report/credit-reconciliation-report.component';
import { LoanCollectionReportComponent } from '../finance-module/loan-collection-report/loan-collection-report.component';
import { PreDemandMonthlyReportComponent } from '../finance-module/pre-demand-monthly-report/pre-demand-monthly-report.component';
import { CkycReportComponent } from '../customer-module/ckyc-report/ckyc-report.component';
import { ArthStaticLmsReportComponent } from '../finance-module/arth-static-lms-report/arth-static-lms-report.component';
import { KinaraBusinessDetailsReportComponent } from '../finance-module/kinara-business-details-report/kinara-business-details-report.component';
import { KinaraCustomerDetailsReportComponent } from '../finance-module/kinara-customer-details-report/kinara-customer-details-report.component';
import { KinaraDisbursementDetailsReportComponent } from '../finance-module/kinara-disbursement-details-report/kinara-disbursement-details-report.component';
import { CreditDueDemandReportComponent } from '../common-module/credit-due-demand-report/credit-due-demand-report.component';
import { HlpStatusReportComponent } from '../partner-module/hlp-status-report/hlp-status-report.component';
import { MasterBussinessOverallReportComponent } from '../finance-module/master-bussiness-overall-report/master-bussiness-overall-report.component';
import { CreditFunnelOverallTrackingReportComponent } from '../finance-module/credit-funnel-overall-tracking-report/credit-funnel-overall-tracking-report.component';
import { CreditPreDisbursementReportComponent } from '../finance-module/credit-pre-disbursement-report/credit-pre-disbursement-report.component';

@Component({
  selector: 'app-insurance-module',
  templateUrl: './insurance-module.component.html',
  styleUrls: ['./insurance-module.component.scss'],
  entryComponents: [
    HlpStatusReportComponent,
    CreditDueDemandReportComponent,
    MasterBussinessOverallReportComponent,
    CreditFunnelOverallTrackingReportComponent,
    CreditReconciliationReportComponent,
    LoanCollectionReportComponent,
    PreDemandMonthlyReportComponent,
    CkycReportComponent,
    CreditPreDisbursementReportComponent,
    ArthStaticLmsReportComponent,
    KinaraBusinessDetailsReportComponent,
    KinaraCustomerDetailsReportComponent,
    KinaraDisbursementDetailsReportComponent,
  ],
})
export class InsuranceModuleComponent {
  reports: any = {
    'Master Business Overall Report': {
      name: 'master_business_overall_report',
      permission: ['master_business_overall_report'],
    },
    'Credit Repayment Overall Report': {
      name: 'credit_repayment_overall_report',
      permission: ['credit_repayment_overall_report'],
    },
    'Credit Reconciliation Report': {
      name: 'credit_reconciliation_report',
      permission: ['credit_reconciliation_report'],
    },
    'Credit Repayment Report': {
      name: 'loan_collection_report',
      permission: ['loan_collection_report'],
    },
    'Pre Demand Monthly Report View': {
      name: 'pre_demand_monthly_report',
      permission: ['pre_demand_monthly_report'],
    },
    'CKYC Report': { name: 'ckyc_report', permission: ['ckyc_report'] },
    'Credit Funnel Tracking OverAll Report ': {
      name: 'credit_funnel_overall_tracking_report',
      permission: ['credit_funnel_overall_tracking_report'],
    },
    'Credit Pre-Disbursement Report': {
      name: 'credit_pre_disbursement_report',
      permission: ['credit_pre_disbursement_report'],
    },
    'Credit Old Customer Repayment Report': {
      name: 'arth_static_lms_report',
      permission: ['arth_static_lms_report'],
    },
    'Credit-Co-Lending  KC Business Detail Report': {
      name: 'kinara_business_details_report',
      permission: ['kinara_business_details_report'],
    },
    'Credit-Co-Lending KC  Customer Detail Report': {
      name: 'kinara_customer_details_report',
      permission: ['kinara_customer_details_report'],
    },
    'Credit-Co-Lending KC Disbursement  Detail Report': {
      name: 'kinara_disbursement_details_report',
      permission: ['kinara_disbursement_details_report'],
    },
    'Credit Due Demand Report': {
      name: 'credit_due_demand_report',
      permission: ['credit_due_demand_report'],
    },
    'HLP Status Report': {
      name: 'hlp_status_report',
      permission: ['hlp_status_report'],
    },
    'Paymint Settlement Report': {
      name: 'paymint_settlement_report',
      permission: ['paymint_settlement_report'],
    },
    'Paymint Submerchant Report': {
      name: 'paymint_submerchant_report',
      permission: ['paymint_submerchant_report'],
    },
    'Paymint Transaction Report': {
      name: 'paymint_transaction_report',
      permission: ['paymint_transaction_report'],
    },
  };

  constructor(private router: Router) {}

  reportClicked(data: any) {
    if (this.reports[data].name === 'credit_reconciliation_report')
      this.router.navigate(['/home/mis/CreditReconciliationReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'loan_collection_report')
      this.router.navigate(['/home/mis/LoanCollectionReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'pre_demand_monthly_report')
      this.router.navigate(['/home/mis/PreDemandMonthlyReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'ckyc_report')
      this.router.navigate(['/home/mis/ckyc_report'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'insurance_report')
      this.router.navigate(['/home/mis/insurance_report'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'credit_pre_disbursement_report')
      this.router.navigate(['/home/mis/CreditPreDisbursementReport'], {
        queryParams: { module: 'business', report: this.reports[data].name },
      });
    else if (this.reports[data].name === 'arth_static_lms_report')
      this.router.navigate(['/home/mis/ArthStaticLmsReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'kinara_business_details_report')
      this.router.navigate(['/home/mis/KinaraBusinessDetailsReport'], {
        queryParams: {
          module: 'Corporate_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'kinara_customer_details_report')
      this.router.navigate(['/home/mis/KinaraCustomerDetailsReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'kinara_disbursement_details_report')
      this.router.navigate(['/home/mis/KinaraDisbursementDetailsReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'master_business_overall_report')
      this.router.navigate(['/home/mis/MasterBussinessOverallReport'], {
        queryParams: { module: 'business', report: this.reports[data].name },
      });
    else if (this.reports[data].name === 'credit_repayment_overall_report')
      this.router.navigate(['/home/mis/CreditRepaymentOverallReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (
      this.reports[data].name === 'credit_funnel_overall_tracking_report'
    )
      this.router.navigate(['/home/mis/CreditFunnelOverallTrackingReport'], {
        queryParams: { module: 'business', report: this.reports[data].name },
      });
    else if (this.reports[data].name === 'credit_due_demand_report')
      this.router.navigate(['/home/mis/CreditDueDemandReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'hlp_status_report')
      this.router.navigate(['/home/mis/HlpStatusReport'], {
        queryParams: {
          module: 'Business_Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'paymint_submerchant_report')
      this.router.navigate(['/home/mis/PaymintSubmerchantReportComponent'], {
        queryParams: {
          module: 'Business Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'paymint_settlement_report')
      this.router.navigate(['/home/mis/PaymintSettlementReportComponent'], {
        queryParams: {
          module: 'Business Finance',
          report: this.reports[data].name,
        },
      });
    else if (this.reports[data].name === 'paymint_transaction_report')
      this.router.navigate(['/home/mis/PaymintTransactionReportComponent'], {
        queryParams: {
          module: 'Business Finance',
          report: this.reports[data].name,
        },
      });
  }

  goBack() {
    window.history.back();
  }
}
