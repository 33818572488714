

<div class="welcome-message">
<h2>
    <span>ARCHIVE</span> 
    <span> REPORTS</span>
</h2>
</div>
<div class="drop-down">
    <!-- <span><label>Select Report</label></span> -->
    <mat-select placeholder="Select Report" class="input-box" formControlName="archiveReport" (selectionChange)="onSelectionChange($event)">
        <mat-option *ngFor="let pair of whitelistedReports | keyvalue" [value]="pair.value">{{ pair.key }}</mat-option>
    </mat-select>
</div>

<div class="cacalendar">
    <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Select Date" (dateInput)="onDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>



<div class="data-container">
    <!-- <h2>Fetched Data</h2> -->
    <table>
      <thead>
        <tr>
          <th>Sr. No</th>
          <th>Report Name</th>
          <th>Date of Report</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of fetchedList; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.report_name }}</td>
          <td>{{ item.date_of_report }}</td>
          <td>
            <!-- <a href="{{ item.download_url }}" target="_blank">Download</a> -->
            <button class="download-button" (click)="downloadReport(item.download_url)">Download</button>

          </td>
        </tr>
      </tbody>
    </table>
  </div>


  <div  *ngIf="fetchedList.length > 0" class="container">
    <ul class="pagination">
      <li class="icon" (click)="previousPage()">
        <a href="javascript:void(0)"><span class="fas fa-angle-left"></span>Previous</a>
      </li>
      <li *ngFor="let pageNumber of pageNumbers" [class.active]="pageNumber === currentPage">
        <a href="javascript:void(0)" (click)="changePage(pageNumber)">{{ pageNumber }}</a>
    </li>
      <li class="icon" (click)="nextPage()">
        <a href="javascript:void(0)">Next<span class="fas fa-angle-right"></span></a>
      </li>
    </ul>
  </div>
  
  