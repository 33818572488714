import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-evaluation-report',
  templateUrl: './credit-evaluation-report.component.html',
  styleUrls: ['./credit-evaluation-report.component.scss']
})
export class CreditEvaluationReportComponent {
  config:any
  name='Credit Evaluation Report'
  reportInformation  = 'Find all the rejected cases reason here to evaluate'
}
