<!-- <div class="spinner-wrapper">
    <div class="spinner"></div>
  </div>
   -->

   <div class="spinner-wrapper">
    <div class="spinner"></div>
    <!-- <div class="message">{{ message }}</div>  -->
    <div class="message" *ngIf="message">{{ message }}</div>
  </div>
  
