import { Component } from '@angular/core';

@Component({
  selector: 'app-master-bussiness-overall-report',
  templateUrl: './master-bussiness-overall-report.component.html',
  styleUrls: ['./master-bussiness-overall-report.component.scss']
})
export class MasterBussinessOverallReportComponent {
  name='Master Business OverAll Report'
  reportInformation  = 'Master Bussiness Overall Report.'
}
