<div class="router">
    <router-outlet ></router-outlet>
    </div>
    
     
      <button class="right-carousel-button" *ngIf="isHomePage()" (click)="stopLoop(); showProductivity = true"> > </button>
    <button class="left-carousel-button" *ngIf="isHomePage()" (click)="stopLoop(); showDailyFunnel = true"> < </button>
 <div *ngIf="showLead">
        <app-home></app-home>
    </div>
    
    <!-- Render Productivity component if showProductivity is true -->
    <div *ngIf="showProductivity">
        <app-productivity></app-productivity>
    </div>
  
    <div *ngIf="showDailyFunnel">
        <app-daily-funnel></app-daily-funnel>
    </div>
   
  
    <div>
      
      <canvas id="pieChart1" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity" style="width: 100%; height: 220px; left: 500px;"></canvas>
  </div>
  
    <div>
      <canvas id="pieChart2" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity" style="left: 500px; width: 100%; height: 220px;"></canvas>
  </div>
  
  <div>
      <canvas id="pieChart3" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity" style="left: 500px; width: 100%; height: 220px;"></canvas>
  </div>
   <!-- <div class="square-box" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity"> </div>
    <div class="square-box2" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity"> </div>
    <div class="square-box3" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity"> </div> -->
  
  <div cluster-component>
  <ng-container *ngIf="isHomePage() && showDashboard">
    <div class="welcome-message" *ngIf="isHomePage() && !showDailyFunnel &&  !showLead && !showProductivity">
  
      <div *ngFor="let cluster of clustersArray; let i = index" [ngClass]="{ 'current-cluster': i === currentIndex }">
  
      <h2 *ngIf="i === currentIndex">
          <!-- <span>Welcome</span>
          <span>to </span> -->
        <!-- <span>Cluster {{ currentClusterIndex === 0 ? clusters[5] : clusters[currentClusterIndex - 1] }}</span> -->
          <span>Cluster {{cluster}}</span> 
          <span>Dashboard </span> 
          <span>{{currentMonth}} - </span>
          <span>{{currentYear}}</span>
      </h2>
      <!-- <img src="assets/icons/coming2.png" alt="Coming Soon"> -->
  
  
  
  
  <div *ngIf="i === currentIndex">
  
  <table class="cl1-table" *ngIf="isHomePage() && !showDailyFunnel  && !showLead && !showProductivity && clusterDataResponse">
      <tr>
      <th>Overall</th>
      <th>Leads</th>
      <th>New Leads</th>
      <th>Total</th>
    </tr>
    <tr>
      <td></td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      
    </tr>
     
  </table>
  
  <table class="cl2-table" *ngIf="isHomePage() && !showDailyFunnel  && !showLead && !showProductivity && clusterDataResponse">
      <tr>
      <th>Customer</th>
      <th>Loans</th>
      <th>Insurance</th>
      <th>Payments</th>
      <th>Accounts</th>
      <th>Investment</th>
    </tr>
    <tr>
      <td></td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      
    </tr>
     
  </table>
  <table class="cl3-table" *ngIf="isHomePage() && !showDailyFunnel  && !showLead && !showProductivity && clusterDataResponse">
      <tr>
      <th>PQ</th>
      <th>Ontime</th>
      <th>01 to 11</th>
      <th>11 to 30</th>
      <th>30 to 60</th>
      <th>60 to 90</th>
      <th>90+</th>
    </tr>
    <tr>
      <td></td>
      <td>{{ clusterDataResponse[cluster].pq.ON_TIME }}</td>
      <td>{{ clusterDataResponse[cluster].pq.OD01to10}}</td>
      <td>{{ clusterDataResponse[cluster].pq.OD11to30}}</td>
      <td>{{ clusterDataResponse[cluster].pq.OD31to60}}</td>
      <td>{{ clusterDataResponse[cluster].pq.OD61to90}}</td>
      <td>{{ clusterDataResponse[cluster].pq.ninty_plus}}</td>
      
    </tr>
     
  </table>
  <table class="cl4-table" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity && clusterDataResponse">
      <tr>
      <th>Red Category</th>
      <th>Non starter</th>
     
    </tr>
    <tr>
      
      <td></td>
      <td>-</td>
     
      
    </tr>
     
  </table>
  
  
  <table class="cl5-table" *ngIf="isHomePage() && !showDailyFunnel && !showLead && !showProductivity && clusterDataResponse">
    <tr>
      <th>Collections</th>
      <th>Ontime</th>
      <th>01 to 11</th>
      <th>11 to 30</th>
      <th>30 to 60</th>
      <th>60 to 90</th>
      <th>90+</th>
    </tr>
    <tr>
      <td></td>
      <td>
        <span  class="arrow" [innerHTML]="clusterDataResponse[cluster].collection_perc.ON_TIME > 80 ? '&#8593;' : '&#8595;'" [style.color]="clusterDataResponse[cluster].collection_perc.ON_TIME > 80 ? 'green' : 'red'"></span>
        {{ clusterDataResponse[cluster].collection_perc.ON_TIME }}
      </td>
      <td>
        <span  class="arrow" [innerHTML]="clusterDataResponse[cluster].collection_perc.OD01to10 > 80 ? '&#8593;' : '&#8595;'" [style.color]="clusterDataResponse[cluster].collection_perc.OD01to10 > 80 ? 'green' : 'red'"></span>
        {{ clusterDataResponse[cluster].collection_perc.OD01to10 }}
      </td>
      <td>
        <span  class="arrow" [innerHTML]="clusterDataResponse[cluster].collection_perc.OD11to30 > 80 ? '&#8593;' : '&#8595;'" [style.color]="clusterDataResponse[cluster].collection_perc.OD11to30 > 80 ? 'green' : 'red'"></span>
        {{ clusterDataResponse[cluster].collection_perc.OD11to30 }}
      </td>
      <td>
        <span class="arrow" [innerHTML]="clusterDataResponse[cluster].collection_perc.OD31to60 > 80 ? '&#8593;' : '&#8595;'" [style.color]="clusterDataResponse[cluster].collection_perc.OD31to60 > 80 ? 'green' : 'red'"></span>
        {{ clusterDataResponse[cluster].collection_perc.OD31to60 }}
      </td>
      <td>
        <span  class="arrow" [innerHTML]="clusterDataResponse[cluster].collection_perc.OD61to90 > 80 ? '&#8593;' : '&#8595;'" [style.color]="clusterDataResponse[cluster].collection_perc.OD61to90 > 80 ? 'green' : 'red'"></span>
        {{ clusterDataResponse[cluster].collection_perc.OD61to90 }}
      </td>
      <td>
        <span  class="arrow" [innerHTML]="clusterDataResponse[cluster].collection_perc.ninty_plus > 80 ? '&#8593;' : '&#8595;'" [style.color]="clusterDataResponse[cluster].collection_perc.ninty_plus > 80 ? 'green' : 'red'"></span>
        {{ clusterDataResponse[cluster].collection_perc.ninty_plus }}
      </td>
    </tr>
  </table>
  
  </div>
  
  
  
      
      <button class="right-carousel-button" *ngIf="isHomePage()" (click)="stopLoop(); showProductivity = true"> > </button>
      <button class="left-carousel-button" *ngIf="isHomePage()" (click)="stopLoop(); showDailyFunnel = true"> < </button>
      
  
      
    </div>
  </div>
  </ng-container>
  </div>