import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HlpHotleadReportComponent } from '../partner-module/hlp-hotlead-report/hlp-hotlead-report.component';
import { LoanCollectionReportComponent } from '../finance-module/loan-collection-report/loan-collection-report.component';
import { CreditDueDemandReportComponent } from '../common-module/credit-due-demand-report/credit-due-demand-report.component';
import { AssignmentReportComponent } from '../ice-module/assignment-report/assignment-report.component';
import { MasterBussinessReportComponent } from '../common-module/master-bussiness-report/master-bussiness-report.component';
import { CreditFunnelTrackingReportComponent } from '../common-module/credit-funnel-tracking-report/credit-funnel-tracking-report.component';
import { HlpStatusReportComponent } from '../partner-module/hlp-status-report/hlp-status-report.component';



@Component({
  selector: 'app-s-and-d-module',
  templateUrl: './s-and-d-module.component.html',
  styleUrls: ['./s-and-d-module.component.scss'],
  entryComponents: [CreditDueDemandReportComponent,CreditFunnelTrackingReportComponent,HlpStatusReportComponent,
    HlpHotleadReportComponent,LoanCollectionReportComponent,AssignmentReportComponent,MasterBussinessReportComponent]
})
export class SAndDModuleComponent  {
  reports:any={
              'HLP Status Report':{name:'hlp_status_report',permission: ['hlp_status_report']},
              'HLP Hotlead Report':{name:'hlp_hotlead_report',permission: ['hlp_hotlead_report']},
              'Credit Repayment Report':{ name: 'loan_collection_report',permission:['loan_collection_report']},
              'Master Business Report':{name: 'master_business_report',permission:['master_business_report']},
              'Credit Funnel Tracking Report':{name: 'credit_funnel_tracking_report',permission:['credit_funnel_tracking_report']},
              'Credit Due Demand Report':{name:'credit_due_demand_report',permission:['credit_due_demand_report']},
              'HLP Invoicing Report':{name:'hlp_invoicing_report',permission:['hlp_invoicing_report']},
              'Assignment Report':{name:'assignment_report',permission:['assignment_report']},
              'Business Prime Sales Report':{name:'business_prime_sales_report',permission:['business_prime_sales_report']}
            }

  constructor(private router:Router){
  }
  reportClicked(data:any){
    if (this.reports[data].name === 'hlp_status_report')
    this.router.navigate(['/home/mis/HlpStatusReport'], { queryParams: { module: 'Central', report: this.reports[data].name } })
    else if (this.reports[data].name === 'hlp_hotlead_report')
    this.router.navigate(['/home/mis/hlp_hotlead_report'], { queryParams: { module: 'Central', report: this.reports[data].name } })
    else if (this.reports[data].name === 'loan_collection_report')
    this.router.navigate(['/home/mis/LoanCollectionReport'], { queryParams: { module: 'S&D', report: this.reports[data].name } })
    else if (this.reports[data].name === 'master_business_report')
    this.router.navigate(['/home/mis/MasterBusinessReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_funnel_tracking_report')
    this.router.navigate(['/home/mis/CreditFunnelTrackingReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
    else if (this.reports[data].name === 'credit_due_demand_report')
    this.router.navigate(['/home/mis/CreditDueDemandReport'], { queryParams: { module: 'S&D', report: this.reports[data].name } })
    else if (this.reports[data].name === 'hlp_invoicing_report')
    this.router.navigate(['/home/mis/HlpInvoicingReport'], { queryParams: { module: 'S&D', report: this.reports[data].name } })
    else if (this.reports[data].name === 'assignment_report')
    this.router.navigate(['/home/mis/AssignmentReport'], { queryParams: { module: 'Central', report: this.reports[data].name } })
    else if (this.reports[data].name === 'business_prime_sales_report')
    this.router.navigate(['/home/mis/BusinessPrimeSalesReport'], { queryParams: { module: 'Central', report: this.reports[data].name } })
  }
  goBack(){
    window.history.back()
  }
}
