import { Component } from '@angular/core';

@Component({
  selector: 'app-paymint-submerchant-report',
  templateUrl: './paymint-submerchant-report.component.html',
  styleUrls: ['./paymint-submerchant-report.component.scss']
})
export class PaymintSubmerchantReportComponent {
  name='PaymiReportnt Submerchant '
  reportInformation  = 'Customers Opted for Paymint'
}
