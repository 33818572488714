import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-funnel-overall-tracking-report',
  templateUrl: './credit-funnel-overall-tracking-report.component.html',
  styleUrls: ['./credit-funnel-overall-tracking-report.component.scss']
})
export class CreditFunnelOverallTrackingReportComponent {
  name='Credit Funnel OverAll Tracking Report'
  reportInformation  = 'Provide the Monthly overall funnel data.'
}
