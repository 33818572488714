import { Component,OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { interval } from 'rxjs';
import { switchMap,startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LoopService } from './loop.service';
// import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  showOverlay: boolean = true; // Initially show the overlay
  showTable: boolean = true;
  showMain: boolean = false;
  showTableOne: boolean = true;
  showTableTwo: boolean = true;
  showTableLead: boolean = true;
  showCluster: boolean = false;
  showProductivity: boolean = false;
  showDailyFunnel:boolean = false;
  loopRunning: boolean = true;


  // navigateToCluster() {
  //   this.router.navigate(['home/cluster']); // Navigate to the ClusterComponent
  // }



  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  showTableTwoContainer(): void{
    this.showMain = true;
    this.showTableOne = true;
  }
  showTableOneContainer(): void {
    this.showMain = false;
    this.showTableOne = true;
}

   showMainDashboard(): void {
    this.showMain = true;
    this.showTableOne = false;
}


isHomePage(): boolean {
  return this.router.url === '/home';
}


  isRoleSelected:boolean
  isUserSelected:boolean
  isMisSelected:boolean
  isCreateUser:boolean
  isCreateRole:boolean
  onHome:boolean
  updateUser:boolean
  updateRole:boolean
  isArchiveReportSelected: boolean
  currentViewIsTableOne: boolean = true;
  misPermission=["insurance_report","disbursement_details_report","goat_trust_report","collection_report","welfare_report","demand_sheet_report","customer_details_report","ice_response_report","rejection_report","lead_to_loan_disbursment_report",
                  "test_disbursement_report","test_collection_report",'test_hlp_report']

  days: number[] = [];
  // category: object = {};
  category: string[] = [];
  responseArray: any[] = [];
  headerIndex: number;
  headerTemplate: any;
  processedItems = new Set();
  currentMonth:string;
  currentYear:Number;




  dashboardData:any=[]
  ngOnInit(): void {
    const date = new Date();
    this.currentMonth = date.toLocaleDateString('en-US', {month:'long'});
    this.currentYear = date.getFullYear();
    setTimeout(() => {
      this.showOverlay = false; // Hide the overlay after 1 second
      this.router.navigate(['/mis']);
    }, 1000);

    this.days = Array.from({ length: 31 }, (_, i) => i + 1);

    // this.startCarousel();
    // if (this.isHomePage() &&  this.loopService.isLoopRunning()) {
    //   this.startLoop();
    // }


    // Fetch token every 89 minutes
    interval(89 * 60 * 1000) // 89 minutes in milliseconds
      .pipe(
        switchMap(() => this.authService.fetchToken())
      )
      .subscribe((res: any) => {
        localStorage.setItem('freshDeskToken', res.freshdesk_token);
        (window as any).FreshworksWidget('authenticate', { token: res.freshdesk_token });
      });

    // Fetch data from daily hotspot dashboard
    interval(10 * 60 * 1000) // 15 minutes
    .pipe(
      startWith(0), // Emit a value immediately when the component initializes
      switchMap(() => this.dashboardService.getDailyHotspotDashboard())
    )
    .subscribe(
      (data: any) => {
        //console.log('API Data getDailyHotspotDashboard Received:', data); // Log API response
        this.responseArray = data;
      },
      (error: any) => {
        console.error('Error fetching daily hotspot dashboard data:', error);
      }
    );


    interval(10 * 60 * 1000) // 10 minutes
    .pipe(
      startWith(0), // Emit a value immediately when the component initializes
      switchMap(() => this.dashboardService.getFunnelDataDashboard())
    )
    .subscribe(
      (data: any) => {
        this.dashboardData = data;
        this.headerIndex = Object.keys(this.dashboardData[0]).length + 1;
        //console.log(this.dashboardData, 'dashboard');
      },
      (error) => {
        console.error('API Error:', error);
      }
    );

  }
    constructor(
      private location: Location,
    public  router:Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private activatedRoute:ActivatedRoute,
    private authService:AuthService,
    private dashboardService: DashboardService,
    private loopService: LoopService
    ) {
    this.matIconRegistry.addSvgIcon(
      'Arth',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Arth Logo.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'corner_image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/corner_image.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/OVS.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Dashboard Icon.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'MIS',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/MIS icon.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'Nudge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/Nudge Icon.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'OVC',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/OVC.svg')
    )
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.isRoleSelected=this.router.url.slice(0,11) ==='/home/roles'
        this.isArchiveReportSelected=this.router.url.slice(0,11) == 'home/archive'
      this.isUserSelected=window.location.pathname.slice(0,11) ==='/home/users'
      this.isMisSelected=window.location.pathname.slice(0,9) ==='/home/mis'
      this.isCreateRole=window.location.pathname ==='/home/roles/create-new-role'
      this.isCreateUser=window.location.pathname ==='/home/users/create-new-user'
      this.onHome=this.router.url==='/home'
      this.activatedRoute.queryParamMap.subscribe(qParam=>{
        if(qParam.get('edit')==='true'){
          if(this.isCreateRole){
            this.updateRole=true
            this.isCreateRole=false
          }
          if(this.isCreateUser){
            this.updateUser=true
            this.isCreateUser=false
          }
        }
      else{
        this.updateRole=false
        this.updateUser=false
      }})

      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the home page
        if (event.url === '/home') {
          this.showTable = true;
        } else {
          this.showTable = false;
        }
      }
    });


  }

    click(){
    }


    goBack(){
      window.history.back()
    }
    getRange(start: number, end: number): number[] {
      return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    }


    logout(){
      localStorage.removeItem('refereshToken');
      localStorage.removeItem('freshDeskToken');
      (window as any).FreshworksWidget('hide');
      (window as any).FreshworksWidget('close')
      this.router.navigate([''])
    }
    startLoop(): void {
      if (!this.isHomePage()) {
        return; // Stop the API call if the component is no longer active
      }

      setTimeout(() => {
        // Check if the loop should continue
        if (!this.isHomePage() || !this.loopService.isLoopRunning()) {
          return; // Exit the function if the loop should stop
        }

        // Show daily funnel
        this.showDailyFunnel = true;

        setTimeout(() => {
          // Check if the loop should continue
          if (!this.isHomePage() || !this.loopService.isLoopRunning()) {
            return; // Exit the function if the loop should stop
          }
          // Hide daily funnel and show cluster
          this.showDailyFunnel = false;
          this.showCluster = true;

          setTimeout(() => {
            // Check if the loop should continue
            if (!this.isHomePage() || !this.loopService.isLoopRunning()) {
              return; // Exit the function if the loop should stop
            }

            // Hide cluster and show productivity
            this.showCluster = false;
            this.showProductivity = true;

            setTimeout(() => {
              // Check if the loop should continue
              if (!this.isHomePage() || !this.loopService.isLoopRunning()) {
                return; // Exit the function if the loop should stop
              }

              // Hide productivity and restart the loop by calling startLoop again
              this.showProductivity = false;
              this.startLoop();
            }, 10000); // Wait for 5 seconds before restarting the loop
          }, 35000); // Wait for 5 seconds before showing productivity
        }, 10000); // Wait for 5 seconds before showing cluster
      }, 10000); // Wait for 5 seconds before showing daily funnel
    }


    // Method to stop the loop
    stopLoop(): void {
      if (this.loopService.isLoopRunning()) {
        this.loopService.stopLoop();
      }
    }

    // stopLoop(): void {
    //   this.loopRunning = false;
    // }



    openDashboard(){
       window.open('https://app.klipfolio.com/#', "_blank");

}
    }

