<!DOCTYPE html>
<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 8px;
}

table {
  border-spacing: 0 10px; /* Adjust the second value to control the space between rows */
}
</style>
</head>
<body>

<div class="welcome-message" *ngIf="isHomePage() && !showLead && !showDailyFunnel &&  !showCluster">
    <h2>
        <!-- <span>Welcome</span>
        <span>to </span> -->
        <span>Productivity </span>
        <span>Dashboard </span>
        <span>{{currentMonth}} - </span>
        <span>{{currentYear}}</span>
    </h2>
    <!-- <img src="assets/icons/coming2.png" alt="Coming Soon"> -->
</div>

<div id="container" class="table-header" *ngIf="isHomePage() && showTableThree && !showDailyFunnel &&  !showLead && !showCluster">
  <h2 class="dashboard-title">PQ Productivity</h2>
  <!-- Content for table one -->
</div>

<div id="container" class="table-header" *ngIf="isHomePage() && showTableTwo && !showDailyFunnel &&  !showLead && !showCluster">
  <h2 class="dashboard-title">Central Ops </h2>
  <!-- Content for table two -->
</div>

<div id="container" class="table-header" *ngIf="isHomePage() && !showDailyFunnel &&  showTableOne && !showLead && !showCluster">
  <h2 class="dashboard-title">Sales Productivity</h2>
  <!-- Content for table three -->
</div>

<div class="router">
    <router-outlet></router-outlet>
</div>

<button class="right-carousel-button" *ngIf="isHomePage()" (click)="stopLoop(); showLead = true"> > </button>
<button class="left-carousel-button" *ngIf="isHomePage()" (click)="stopLoop(); showCluster = true"> < </button>

<div *ngIf="showLead">
    <app-home></app-home>
</div>
<div *ngIf="showDailyFunnel">
  <app-daily-funnel></app-daily-funnel>
</div>

<!-- Render Productivity component if showProductivity is true -->
<div *ngIf="showCluster">
    <app-cluster></app-cluster>
</div>





<div class="container">
  <div id="container" class="container" *ngIf="isHomePage() && showTableOne && !showDailyFunnel && !showLead && !showCluster">
  <button class="btn btn-primary carousel-button" *ngIf="showTableOne && !showLead && !showDailyFunnel &&  !showCluster" (click)="showThirdTable()"> < </button>
   <table class="huda-table"> 
  <tr>
    <th colspan="2">Huddle daily</th>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.runrate">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;" rowspan="2">CLM</td>
    <td>HLP Application</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.HTL_Application">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td>HTL onboarding</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.HLP_Onboarding">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;" rowspan="2">CLO</td>
    <td>Repeat Journey Count,Topup</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.Repeat_Journey">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td>PQ Visits</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.Disbursement">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">BDO</td>
    <td>HLP CAF</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.HLP_CAF">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">WO</td>
    <td>Per Day Disbursement Center Wise</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.Disbursement">
      <th>{{ item }}</th>
    </ng-container>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;"rowspan="2">CLC</td>
    <td>CAF Count</td>
    <ng-container *ngFor="let item of dashboardData.sales_prductivity.HLP_CAF">
      <th>{{ item }}</th>
    </ng-container>
  </tr>

</table>
<button class="btn btn-primary carousel-button" *ngIf="isHomePage() && showTableOne &&  !showDailyFunnel && !showLead && !showCluster" (click)="showSecondTable()"> > </button>
</div>

<!------------------------------------------------tabletwo------------------------------------------------------------------------------------------------->

<button class="btn btn-primary second-carousel-button" *ngIf="isHomePage() && showTableTwo  && !showDailyFunnel &&  !showLead && !showCluster" (click)="showFirstTable()"> < </button>

<table id="second-container" class="runrate-table" *ngIf="isHomePage() && showTableTwo && !showDailyFunnel &&  !showLead && !showCluster">
  
  <!-- <tr>
    <th>Run Rate</th>
    <ng-container *ngFor="let item of dashboardData.runrate">
      <td>{{ item }}</td>
    </ng-container>
  </tr> -->
  <tr>
    <th>Run Rate</th>
    <ng-container *ngFor="let item of dashboardData.central_ops.runrate">
      <td style="background-color: lightsteelblue; font-weight: bold;">{{ item }}</td>

    </ng-container>

  </tr>
  
  <tr>
    <th>Hunting</th>
    <ng-container *ngFor="let item of dashboardData.central_ops.hunting">
      <td>{{ item }}</td>
    </ng-container>
  </tr>
  <tr>
    <tr>
      <th>PQA-W/L</th>
      <ng-container *ngFor="let item of dashboardData.central_ops.pqa_wl">
        <td>{{ item }}</td>
      </ng-container>
    </tr>
  <tr>

    <tr>
      <tr>
        <th>Loan Acceptance</th>
        <ng-container *ngFor="let item of dashboardData.central_ops.loan_acceptance">
          <td>{{ item }}</td>

        </ng-container>
      </tr>
    <tr>

      <tr>
        <tr>
          <th>Cross Sell</th>
          <ng-container *ngFor="let item of dashboardData.central_ops.cross_sell">
            <td>{{ item }}</td>

          </ng-container>
        </tr>
      <tr>
  <tr>
    <tr>
      <tr>
        <th>QC</th>
        <ng-container *ngFor="let item of dashboardData.central_ops.qc">
          <td>{{ item }}</td>
        </ng-container>
      </tr>
    <tr>
    
 
 
</table>
<button class="btn btn-primary second-carousel-button" *ngIf="isHomePage() && !showDailyFunnel &&  showTableTwo && !showLead && !showCluster" (click)="showThirdTable()"> > </button>


</div>

<!--------------------------------------------------------tablethree------------------------------------------------------------------>

<button class="btn btn-primary third-carousel-button" *ngIf="isHomePage() && !showDailyFunnel &&  showTableThree  && !showLead && !showCluster" (click)="showSecondTable()"> < </button>

<table id="third-container" class="runrate-table" *ngIf="isHomePage ()&& showTableThree && !showDailyFunnel &&  !showLead && !showCluster">
  
  <tr>
    <th >Center</th>
   
    <th>Nudge Customer Pull</th>
    <th>Unpaid Base</th>
    <th>Connected Call</th>
    <th>Not Connected Call</th>
    <th>No.Of.Profile Updates</th>
    <th>Base Paid</th>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">PQ-CO(D+B)</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">PQ-CO-(L)</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">PQ-CO-(A)</td> 
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
 
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">PQ-CO-(R)</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">PQ-CO-(RP)</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
 
  </tr>
  <tr>
    <td style="background-color: lightsteelblue;">PQ-CO-(NP)</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    
  </tr> 
</table>
<button class="btn btn-primary third-carousel-button" *ngIf="isHomePage() && !showDailyFunnel &&  showTableThree && !showLead && !showCluster" (click)="showFirstTable()"> > </button>






