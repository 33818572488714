import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welfare-report',
  templateUrl: './welfare-report.component.html',
  styleUrls: ['./welfare-report.component.scss']
})
export class WelfareReportComponent implements OnInit {

config:any
name='Welfare Report'

ngOnInit(): void {
  this.config=[
    {
      header:"Sr No",
      field:"sr_no"
    },
    {
      header:"Lead Id",
      field:"lead_id"
    },
    {
      header:"Lead Date",
      field:'lead_date'
    },
    {
      header:"Customer UCIC",
      field:"customer_ucic"
    },
    {
      header:"Loan Type",
      field:"loan_type"
    },
    {
      header:"Customer Name",
      field:"customer_name"
    },
    {
      header:"Mobile",
      field:"mobile"
    },
    {
      header:"Alternate Mobile",
      field:"alternate_mobile"
    },
    {
      header:"address Lane 1",
      field:"address_line_1"
    },
    {
      header:"Address Lane 2",
      field:"address_line_2"
    },
    {
      header:"Home Pincode",
      field:"home_pincode"
    },
    {
      header:"Sub District",
      field:"home_sub_district"
    },
    {
      header:"District",
      field:"home_district"
    },
    {
      header:"State",
      field:"home_state"
    },
    {
      header:"Shop Name",
      field:"shop_name"
    },
    {
      header:"Is Address Similar",
      field:"is_similar_address"
    },
    {
      header:"Shop Address 1",
      field:"shop_address_1"
    },
    {
      header:"Shop Address 2",
      field:"shop_address_2"
    },
    {
      header:"Shop Pincode",
      field:"shop_pincode"
    },
    {
      header:"Shop Sub District",
      field:"shop_sub_district"
    },
    {
      header:"Shop District",
      field:"shop_district"
    },
    {
      header:"Shop State",
      field:"shop_state"
    },
    {
      header:"Employee Name",
      field:"clc_name"
    },
    {
      header:"Center",
      field:"center"
    },
    {
      header:"Cluster",
      field:"cluster"
    },
    {
      header:"Loan Acc No",
      field:"loan_acc_no"
    },
    {
      header:"Final Status",
      field:"final_status"
    },
    {
      header:'Partner',
      field:'partner_type'
    },
    {
      header:'Product Type',
      field:'product_type'
    },
    {
      header:'Lender Type',
      field:'lender_type'
    }
  ]
}
}
