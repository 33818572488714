import { Component } from '@angular/core';

@Component({
  selector: 'app-kinara-business-details-report',
  templateUrl: './kinara-business-details-report.component.html',
  styleUrls: ['./kinara-business-details-report.component.scss']
})
export class KinaraBusinessDetailsReportComponent {
  name='Kinara Business Detail Report'
  reportInformation  = 'This report provides information about the business details to Kinara';


}



