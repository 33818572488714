import { HttpClient,HttpHeaders, HttpParams, HttpParamsOptions} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
// import * as XLSX from 'xlsx';
import { Observable ,throwError} from 'rxjs';
import { map, catchError ,tap} from 'rxjs/operators';



@Injectable({providedIn:'root'})

export class FetchReport{

    private apiBaseUrl = environment.apiUrl

    constructor(private http:HttpClient){

    }

    esHeaders() {
		let headers = new HttpHeaders();
		let accessToken:any = localStorage.getItem('refereshToken');

		if (accessToken) {
			headers = headers.set('Authorization', `b ${accessToken}`);
		}
		return {headers};
	}

    fetchInsuranceReport(){
       let body={
        "insurance":{
            "name": "somehintg"
        }

        }
        // const httpParams: HttpParamsOptions = { fromObject: body } as HttpParamsOptions;
        // const options = { params: new HttpParams(body), headers: headers };
    return this.http.post(this.apiBaseUrl + 'insurance_report',body)
    }

    getFilters(report:any){
        return this.http.get(this.apiBaseUrl+report+'/filters',this.esHeaders())
    }

    fetchReport(report:any,body:any){
        let body1={...body,page_size:5,emp_id:'AT011'}
        // let body={
        //     "goat_trust":{
        //         "name": "somehintg"
        //     }

        //     }
        return this.http.post(this.apiBaseUrl+`${report}`,body1,this.esHeaders())
    }

    getReportData(report: any,module_name:any,body: any) {
      const pageSize = body.page_size || 5;
      const currentPage = body.page || 1;

      body = { ...body, page_size: pageSize, emp_id: 'AT011', page: currentPage };
      return this.http.post(`${this.apiBaseUrl}/${module_name}/${report}`, body, this.esHeaders());
    }
    downloadReport(report:any, body:any){
      body = { ...body, emp_id:'AT011'};
      return this.http.post(`${this.apiBaseUrl}${report}/download`, body, this.esHeaders());
    }
    getReportLength(report: any): Observable<number> {
      return this.http.get<{ report_length: number }>(`${this.apiBaseUrl}/report_length/${report}`, this.esHeaders())
        .pipe(
          map(response => {
            return response.report_length}),
          catchError(error => {
            console.error('Error fetching report size:', error);
            throw error;
          })
        );
    }

    getReportLastDate(report: any): Observable<number> {
      return this.http.get<number>(`${this.apiBaseUrl}/report_last_generated_at/${report}`, this.esHeaders())
        .pipe(
          tap(response => console.log('report_last_generated Response:', response)),
          catchError(error => {
            console.error('Error fetching report last generated date:', error);
            return throwError(() => error);
          })
        );
    }

    fetchCKYCReport(report:any,body:any){
        let body1={...body,page_size:5,emp_id:'AT011'}
        // let body={
        //     "goat_trust":{
        //         "name": "somehintg"
        //     }

        //     }
        return this.http.post(this.apiBaseUrl+`${report}`,body,this.esHeaders())
    }


    // downloadXlsx(module_name:any,report:any,body:any){
    //     let body1={...body,emp_id:'AT011'}
    //         // body[report]={"name": "somehintg"}
    //         let header:any=this.esHeaders()
    //         const options: Object={
    //             ...header,
    //             observe: 'body',
    //             responseType:'text' as 'json'

    //         }
    //     return this.http.post(this.apiBaseUrl+`${module_name}/${report}/download`,body1,options)
    // }
    // downloadXlsxForSheet2(module_name:any,report:any,body:any){
    //     let body2={...body,emp_id:'AT011',sheet: 'Sheet2'}
    //         // body[report]={"name": "somehintg"}
    //         let header:any=this.esHeaders()
    //         const options: Object={
    //             ...header,
    //             observe: 'body',
    //             responseType:'text' as 'json'

    //         }
    //     return this.http.post(this.apiBaseUrl+`${module_name}/${report}/download`,body2,options)
    // }


    downloadLTD(){

        let body={
            "ltd_mis":{
                "name": "something"
            }

            }
            let header:any=this.esHeaders()
            const options: Object={
                ...header,
                observe: 'body',
                responseType:'text' as 'json'

            }

        return this.http.post(this.apiBaseUrl+`finance/ltd_mis_report/download`,body,options)

    }

    generateCkyc(filter:any){
        let body={...filter}
        return this.http.post(this.apiBaseUrl+'ckyc_report_generate',body,this.esHeaders())
    }
    downloadXlsx(module_name:any,report:any,body:any){
      const pageSize = body.page_size || 2147483647;
      const currentPage = body.page || 1;
        let body1={...body,emp_id:'AT011',page_size:pageSize,page:currentPage}
            // body[report]={"name": "somehintg"}
            let header:any=this.esHeaders()
            const options: Object={
                ...header,
                observe: 'body',
                responseType:'text' as 'json'
            }
        return this.http.post(this.apiBaseUrl+`${module_name}/${report}/download_xlsx`,body1,options)
    }

}
