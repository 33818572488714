import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goat-trust-report',
  templateUrl: './goat-trust-report.component.html',
  styleUrls: ['./goat-trust-report.component.scss']
})
export class GoatTrustReportComponent implements OnInit{
  config:any
  name='Partner Insurance Report'

  ngOnInit(): void {
    this.config=[
     {
      header:'Sr No',
      field:'sr_no'
     },
     {
      header:'Lead Id',
      field:'lead_id'
     },
     {
      header:'Customer UCIC',
      field:'customer_ucic'
     },
     {
      header:'Onboard Form Type',
      field:'customer_onboard_form_type'
     },
     {
      header:'Lead Date',
      field:'lead_date'
     },
     {
      header:'Customer Name',
      field:'customer_name'
     },
     {
      header:'Mobile',
      field:'mobile'
     },
     {
      header:'Alternate Mobile',
      field:'alternate_mobile'
     },
     {
      header:'Co-borrower Name',
      field:'co_borrower_name'
     },
     {
      header:'HLP Id',
      field:'cp_id'
     },
     {
      header:'Tenure',
      field:'tenure'
     },
     {
      header:'Employee Name',
      field:'clc_name'
     },
     {
      header:'Center',
      field:'center'
     },
     {
      header:'Cluster',
      field:'cluster'
     },
     {
      header:'Shop District',
      field:'shop_district'
     },
     {
      header:'Shop State',
      field:'shop_state'
     },
     {
      header:'Appraisal Date',
      field:'appraisal_date'
     },
     {
      header:'Appraisal Status',
      field:'appraisal_status'
     },
     {
      header:'Loan Acc No',
      field:'loan_acc_no'
     },
     {
      header:'Partner Code',
      field:'partner_code'
     },
     {
      header:'Product Code',
      field:'product_code'
     },
     {
      header:'Kyc Verified Date',
      field:'kyc_verified_date'
     },
     {
      header:'Kyc Verified By',
      field:'kyc_verified_by'
     },
     {
      header:'Re Kyc Date',
      field:'re_kyc_date'
     },
     {
      header:'Rekyc By',
      field:'rekyc_by'
     },
     {
      header:'Kyc Status',
      field:'kyc_status'
     },
     {
      header:'Loan Amount',
      field:'loan_amount'
     },
     {
      header:'Net Disbursed',
      field:'net_disbursed'
     },
     {
      header:'Disbursement Date',
      field:'disbursement_date'
     },
     {
      header:'Status',
      field:'status'
     },
     {
      header:'Final Status',
      field:'final_status'
     },
     {
      header:'Partner',
      field:'partner_type'
    },
     {
      header:'Product Type',
      field:'product_type'
    },
    {
      header:'Lender Type',
      field:'lender_type'
    }

    ]
  }

}