import { Component } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';



@Component({
  selector: 'app-welfare-module',
  templateUrl: './welfare-module.component.html',
  styleUrls: ['./welfare-module.component.scss']
})
export class WelfareModuleComponent {
  reports:any={'Welfare':{name:'welfare_report'}}

  constructor(private router:Router){
  
  }
  reportClicked(data:any){
  // this.router.navigate(['home/mis/FetchReport'],{ queryParams: {module:'Welfare_Module',report:this.reports[data].name}})
  this.router.navigate([`home/mis/${this.reports[data].name}`],{ queryParams: {module:'Welfare_Module',report:this.reports[data].name}})

  }

  goBack(){
    window.history.back()
  }

}
