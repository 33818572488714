import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-pre-disbursement-report',
  templateUrl: './credit-pre-disbursement-report.component.html',
  styleUrls: ['./credit-pre-disbursement-report.component.scss']
})
export class CreditPreDisbursementReportComponent {
  name='Credit Pre-Disbursement Report'
  reportInformation  = 'Report provides loan disbursement detail and their deduction breakups';
}
