import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef;
  private message: string;

  constructor(private overlay: Overlay) { }


  show(message: string): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
    const spinnerPortal = new ComponentPortal(SpinnerOverlayComponent);
    this.overlayRef.attach(spinnerPortal);
    this.message = message;
    //console.log('Message set in overlay:', message); // Log the message
  }



  hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

}

