import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ice-rejection-report',
  templateUrl: './ice-rejection-report.component.html',
  styleUrls: ['./ice-rejection-report.component.scss']
})
export class IceRejectionReportComponent implements OnInit {

  config:any
  name='Rejection Reason report'

  ngOnInit(): void {

    this.config=[
      {
        header:'Customer UCIC',
        field:'customer_ucic'
      },
      {
        header:'Lead Date',
        field:'lead_date'
      },
      {
        header:'Decision Date',
        field:'decision_date'
      },
      {
        header:'Customer Name',
        field:'customer_name'
      },
      {
        header:'Cluster',
        field:'cluster'
      },
      {
        header:'Center',
        field:'center'
      },
      {
        header:'Employee Name',
        field:'employee_name'
      },
      {
        header:'Status',
        field:'status'
      },
      {
        header:'Remark Amount',
        field:'remark_amount'
      },
      {
        header:'Partner',
        field:'partner_type'
      },
      {
        header:'Product Type',
        field:'product_type'
      },
      {
        header:'Lender Type',
        field:'lender_type'
      }
    ]
    
  }

}
