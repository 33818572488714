import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-collection-report',
  templateUrl: './test-collection-report.component.html',
  styleUrls: ['./test-collection-report.component.scss']
})
export class TestCollectionReportComponent implements OnInit{
  config:any
  name='Collection Report'

  ngOnInit(): void {

    this.config=[
      {
        header:'Loan Uid',
        field:'loan UID'
      },
      {
        header:'DPD Bucket',
        field:'DPD Bucket'
      },
      {
        header:'OD Principal',
        field:'OD Principal'
      },
      {
        header:'OD 1-30 Days',
        field:'OD 1-30 Days'
      },
      {
        header:'OD 31-60 Days',
        field:'OD 31-60 Days'
      },
      {
        header:'OD 61-90 Days',
        field:'OD 61-90 Days'
      },
      {
        header:'OD 91-180 Days',
        field:'OD 91-180 Days'
      },
      {
        header:'OD 180-365 Days',
        field:'OD 180-365 Days'
      },
      {
        header:'OD 365 Days+',
        field:'OD 365 Days+'
      },
      {
        header:'Amount',
        field:'amount'
      },
      {
        header:'Date Stamp',
        field:'Date Stamp'
      },
      {
        header:'Tenure',
        field:'tenure'
      },
      {
        header:'AUM / POS',
        field:'AUM / POS'
      },
      {
        header:'Last Receipt Date',
        field:'Last Receipt Date'
      },
      {
        header:'Last EMI Amount',
        field:'Last EMI Amount'
      }
    ]

}
}


// ["loan UID",                                                                    

// "DPD Bucket",                                                                  

// "OD Principal",                                                                

// "OD 1-30 Days",                                                              

// "OD 31-60 Days",                                                             

// "OD 61-90 Days",                                                             

// "OD 91-180 Days",                                                            

// "OD 180-365 Days",                                                           

// "OD 365 Days+",                                                              

// "amount",                                                                    

// "Date Stamp",                                                                

// "tenure",                                                                    

// "AUM / POS",                                                                 

// "Last Receipt Date",                                                         

// "Last EMI Amount"]