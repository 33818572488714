import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  list: any[] = [];
  yourModel = '';
  filteredList: any[] = [];
  roles: any[] = [];
  campaigns: string[] = ['Campaign 1', 'Campaign 2', 'Campaign 3']; // Sample campaigns
  selectedRole: string = ''; // Added selectedRole property for filtering
  getXlsx: boolean = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private matIconRegistry: MatIconRegistry,
    public router: Router,
    private roleService: RoleService,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'corner_image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/corner_image.svg')
    );
  }

  ngOnInit(): void {
    this.fetchUsers();
    this.roleService.fetchRoles().subscribe((data: any) => {
      this.roles = data;
    });
  }

  fetchUsers(): void {
    this.userService.fetchUser().subscribe((data: any) => {
      this.list = data;
    });
  }

  downloadXlsx(): void {
    this.getXlsx = true;
  }

  editUser(event: any): void {
    this.userService.selectUser(event);
    localStorage.setItem('userDetail', JSON.stringify(event));
    this.router.navigate(['/home/users/create-new-user'], { queryParams: { edit: true } });
  }

  sendXlsx(): void {
    this.getXlsx = false;
  }

  searchKeyword(): void {
    const keyword = this.yourModel.toLowerCase();
    this.list = this.list.filter((user: any) =>
      user.name.toLowerCase().includes(keyword) &&
      (!this.selectedRole || user.role === this.selectedRole)
    );
  }

  filterByRole(): void {
    this.userService.fetchUser().subscribe((data: any) => {
      this.list = data.filter((user: any) => user.role === this.selectedRole);
    });
  }

  filterAll(): void {
    this.fetchUsers();
  }
}
