import { Component, OnInit } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { DashboardService } from 'src/app/services/dashboard.service';


interface CustomerProjection {
  series: string;
  [key: string]: any;
}
interface RepCustomerProjection {
  series: string;
  [key: string]: any;
}

@Component({
  selector: 'app-dashboard4',
  templateUrl: './dashboard4.component.html',
  styleUrls: ['./dashboard4.component.scss']
})
export class Dashboard4Component implements OnInit{

  customer_projection_data: CustomerProjection[] = [];
  prime_projection_data: RepCustomerProjection[] = [];
  errorMessage: string = '';
  filteredData: any = {};
  filteredData2: any = {};
  chartOptions: any = {};
  columnchartOptions: any = {};
  rep_chartOptions: any = {};
  rep_columnchartOptions: any = {};
  isLoading: boolean = true;
  currentMonth: string = '';
  currentYear: number = new Date().getFullYear();
  data: any = {};
  rep_data: any = {};
  new_categories: any[] = [];

  constructor(private dashboardservice: DashboardService) {}

  ngOnInit(): void {
    this.dashboardservice.getPrimePlusProjectionDashboad().subscribe({
      next: (data) => {
        this.customer_projection_data = Array.isArray(data) && data.length > 0 ? data[0] : [];
        //console.log('Received customer_projection_data:', this.customer_projection_data);
        setTimeout(() => {
          this.isLoading = false;
          setTimeout(() => {
            this.processData();  // Call the function after loading the data
          }, 300);
        }, 3000);
      },
      error: (error) => {
        this.errorMessage = error.message;
        this.isLoading = false;
        console.error('Error fetching data:', this.errorMessage);
      }
    });
//Prime

this.dashboardservice.getPrimeProjectionDashboad().subscribe({
  next: (data) => {
    this.prime_projection_data = Array.isArray(data) && data.length > 0 ? data[0] : [];
    //console.log('Received prime_projection_data:', this.prime_projection_data);
    setTimeout(() => {
      this.isLoading = false;
      setTimeout(() => {
        this.processData();  // Call the function after loading the data
      }, 300);
    }, 3000);
  },
  error: (error) => {
    this.errorMessage = error.message;
    this.isLoading = false;
    console.error('Error fetching data:', this.errorMessage);
  }
});

  }

  // Function that processes the data and sets the values
  processData(): void {
    if (this.isLoading || !this.customer_projection_data.length|| !this.prime_projection_data.length) {
      console.error('Data is still loading or empty');
      return;
    }

    const date = new Date();
    this.currentMonth = date.toLocaleDateString('en-US', { month: 'long' });
    this.currentYear = date.getFullYear();

    // Ensure data and rep_data are initialized
    this.data = this.data || {};
    this.rep_data = this.rep_data || {};

    //console.log('Processing data with:', { data: this.data, rep_data: this.rep_data });

    // Process filteredData
    this.filteredData =this.customer_projection_data
    .filter(item => !['ftw', 'ftm', 'ftd'].includes(item.series));

    // Process filteredData2
    this.filteredData2 = Object.keys(this.rep_data)
      .filter(key => ['ftd', 'ftw', 'ftm'].includes(key))
      .reduce((obj, key) => {
        obj[key] = this.rep_data[key];
        return obj;
      }, {} as any);

    //console.log('Filtered Data:', { filteredData: this.filteredData, filteredData2: this.filteredData2 });

    // Render charts
    this.renderCharts();
  }

  // Function to render the charts
  renderCharts(): void {
    // Clear any existing charts
    this.clearCharts();

    // Extract categories
    const excludeKeys = ['ftd', 'ftm', 'ftw', 'series'];
    const categories2 = ["FTD", "FTW", "FTM"];

    // Transform data to match the required series format
    const seriesdata = this.transformData(this.customer_projection_data);
    const rep_seriesdata = this.transformData(this.prime_projection_data);

    const categories = Object.keys(this.customer_projection_data[0])
      .filter(key => !excludeKeys.includes(key));
    const rep_categories = Object.keys(this.prime_projection_data[0])
      .filter(key => !excludeKeys.includes(key));

    // Prepare seriesdata2
    const seriesdata2 = this.transformFTData(this.customer_projection_data);
    const rep_seriesdata2 = this.transformFTData(this.prime_projection_data);

    // Default new_categories if empty
    this.new_categories = this.new_categories.length ? this.new_categories : categories;
    console.log('categories',this.new_categories);

    // Chart options for new data
    this.chartOptions = {
      series: seriesdata,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      title: {
        text: 'Bussiness Prime Plus+ Projection Daily Funnel',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#333',
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        categories: this.new_categories,
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      colors: ['#5a8e6d', '#d1cd49', '#b45f5f', '#9e7fb3', '#ad4db3', '#b3676e', '#832a3b', '#1e0f2e', '#0e5955', '#8f3a1d'],
      legend: {
        position: 'left',
        offsetY: 40
      },
      fill: {
        opacity: 0.8
      },
      tooltip: {
        shared: true,
        intersect: false
      }
    };

    this.columnchartOptions = {
      series: seriesdata2,
      chart: {
        type: 'bar',
        height: 260,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        categories: categories2,
        labels: {
          show: true
        }
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      colors: ['#5a8e6d', '#d1cd49', '#b45f5f', '#9e7fb3', '#ad4db3', '#b3676e', '#832a3b', '#1e0f2e', '#0e5955', '#8f3a1d'],
      legend: {
        show: false
      },
      fill: {
        opacity: 0.8
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      title: {
        text: 'Cumulative Daily/Weekly/Monthly',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#333',
        }
      }
    };


    // Chart options for rep data
    this.rep_chartOptions = {
      series: rep_seriesdata,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      title: {
        text: 'Bussiness Prime Projection Daily Funnel',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#333',
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        categories: this.new_categories,
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      colors: ['#5a8e6d', '#d1cd49', '#b45f5f', '#9e7fb3', '#ad4db3', '#b3676e', '#832a3b', '#1e0f2e', '#0e5955', '#8f3a1d'],
      legend: {
        position: 'left',
        offsetY: 40
      },
      fill: {
        opacity: 0.8
      },
      tooltip: {
        shared: true,
        intersect: false
      }
    };

    this.rep_columnchartOptions = {
      series: rep_seriesdata2,
      chart: {
        type: 'bar',
        height: 260,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        }
      },
      xaxis: {
        categories: categories2,
        labels: {
          show: true
        }
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      colors: ['#5a8e6d', '#d1cd49', '#b45f5f', '#9e7fb3', '#ad4db3', '#b3676e', '#832a3b', '#1e0f2e', '#0e5955', '#8f3a1d'],
      legend: {
        show: false
      },
      fill: {
        opacity: 0.8
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      title: {
        text: 'Cumulative Daily/Weekly/Monthly',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#333',
        }
      }
    };

    // Render charts
    const barChartElement = document.querySelector("#prime_plus_stack_chart");
    const columnChartElement = document.querySelector('#prime_plus_collumn_chart');
    const prime_barChartElement = document.querySelector("#prime_stack_chart");
    const prime_columnChartElement = document.querySelector('#prime_collumn_chart');

    // Check if elements exist before rendering
    if (barChartElement) {
      new ApexCharts(barChartElement, this.chartOptions).render();
    } else {
      console.error('Element #stack_chart not found');
    }

    if (columnChartElement) {
      new ApexCharts(columnChartElement, this.columnchartOptions).render();
    } else {
      console.error('Element #collumn_chart not found');
    }

    if (prime_barChartElement) {
      new ApexCharts(prime_barChartElement, this.rep_chartOptions).render();
    } else {
      console.error('Element #rep_stack_chart not found');
    }

    if (prime_columnChartElement) {
      new ApexCharts(prime_columnChartElement, this.rep_columnchartOptions).render();
    } else {
      console.error('Element #rep_collumn_chart not found');
    }
  }

  // Clear existing charts from the DOM
  clearCharts(): void {
    const charts = document.querySelectorAll('.apexcharts-canvas');
    charts.forEach(chart => chart.remove());
  }


  // Transform data to the required series format
  transformData(data: any[]): any[] {
    return data.map((item) => {
      const { series, ftd, ftm, ftw, ...regions } = item;  // Exclude 'ftd', 'ftm', 'ftw', and 'series' from 'regions'

      const regionData = Object.values(regions); // Get array of region values

      return {
        name: series,
        data: regionData,  // The data array of region values
        group: "apexcharts-axis-0"  // Set a static group value for each series
      };
    });
  }
  transformFTData(data: any[]): any[] {
    return data.map((item) => {
      const { series, ftd, ftw, ftm } = item;  // Extract 'ftd', 'ftm', 'ftw' from the object

      return {
        name: series,       // Use 'series' as 'name'
        data: [ftd, ftw, ftm],  // Store 'ftd', 'ftm', 'ftw' in an array under 'data'
        group: "apexcharts-axis-0"  // Set a static group value for each series
      };
    });
  }
}
