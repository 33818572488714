import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-disbursement-report',
  templateUrl: './test-disbursement-report.component.html',
  styleUrls: ['./test-disbursement-report.component.scss']
})
export class TestDisbursementReportComponent implements OnInit{

  config:any
  name='Disbursement Report'

  ngOnInit(): void {

    this.config=[
      {
        header:'Loan Uid',
        field:'loan UID'
      },
      {
        header:'Name',
        field:'Name'
      },
      {
        header:'Dob',
        field:'dob'
      },
      {
        header:'Gender',
        field:'gender'
      },
      {
        header:'Contact',
        field:'contact'
      },
      {
        header:'District',
        field:'district'
      },
      {
        header:'State',
        field:'state'
      },
      {
        header:'Lending Mode',
        field:'Lending Mode'
      },
      {
        header:'Partner',
        field:'partner'
      },
      {
        header:'Product Type',
        field:'Product Type'
      },
      {
        header:'Amount',
        field:'amount'
      },
      {
        header:'Date Stamp',
        field:'Date Stamp'
      },
      {
        header:'Tenure',
        field:'tenure'
      },
    ]

}
}


// ["loan UID",                                                                    
// "Name",                                                                        
// "dob",                                                                         
// "gender",                                                                      
// "contact",                                                                     
// "district",                                                                    
// "state",                                                                       
// "Lending Mode",                                                                
// "partner",                                                                     
// "Product Type",                                                                
// "amount",                                                                      
// "Date Stamp",                                                                  
// "tenure"]