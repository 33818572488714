import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({providedIn:'root'})

export class AuthService{

    private apiBaseUrl = environment.apiUrl

    constructor(private http:HttpClient){

    }

    login(data:any){
        let body={
            "email":data.emailId,
            "password_digest":data.password
            // "email": "siddharth.s@arthdigital.net",
            // "password_digest": "siddharth"
        }
        return this.http.post(this.apiBaseUrl +'auth/login',body)

    }

    fetchToken(){
        return this.http.get(this.apiBaseUrl +'freshdesk_token',this.esHeaders())
    }

    esHeaders() {
		let headers = new HttpHeaders();
		let accessToken:any = localStorage.getItem('refereshToken');
		if (accessToken) {
			headers = headers.set('Authorization', `b ${accessToken}`);
		}
		return {headers};
	}

}
