import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-journey-drop-report',
  templateUrl: './customer-journey-drop-report.component.html',
  styleUrls: ['./customer-journey-drop-report.component.scss']
})
export class CustomerJourneyDropReportComponent implements OnInit{
  config:any
  name='Customer Journey Drop Report'

  ngOnInit(): void {

    this.config=[
        {
          header:'Sno',
          field:'sno'
        },
        {
          header:'Lead ID',
          field:'lead_id'
        },
        {
          header:'Loan Uid',
          field:'loan_uid'
        },
        {
          header:'Customer Ucic',
          field:'customer_ucic'
        },
        {
          header:'Customer Mobile',
          field:'customer_mobile'
        },
        {
          header:'Partner Code',
          field:'partner_code'
        },
        {
          header:'Journey Started Date',
          field:'journey_started_date'
        },
        {
          header:'Offer Claimed Date',
          field:'offer_claimed_date'
        },
        {
          header:'Last OTP Requested Date',
          field:'last_otp_requested_date'
        },
        {
          header:'Last OTP Submitted Date',
          field:'last_otp_submitted_date'
        },
        {
          header:'Selfie Uploaded Date',
          field:'selfie_uploaded_date'
        },
        {
          header:'POA Uploaded Date',
          field:'poa_uploaded_date'
        },
        {
          header:'Profile Edited Date',
          field:'profile_edited_date'
        },
        {
          header:'TNC Accepted Date',
          field:'tnc_accepted_date'
        },
        {
          header:'Last eNACH Requested Date',
          field:'last_enach_requested_date'
        },
        {
          header:'eNACH Completed Date',
          field:'enach_completed_date'
        },
        {
          header:'eNACH Status',
          field:'enach_status'
        },
        {
          header:'Phase',
          field:'phase'
        }
    ];

  }
}
    

