import { Component } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';

import { CreditFunnelTrackingReportComponent } from '../common-module/credit-funnel-tracking-report/credit-funnel-tracking-report.component';
import { EmployeeAttendanceReportComponent } from './employee-attendance-report/employee-attendance-report.component';


@Component({
  selector: 'app-human-resources-module',
  templateUrl: './human-resources-module.component.html',
  styleUrls: ['./human-resources-module.component.scss'],
  entryComponents: [CreditFunnelTrackingReportComponent, EmployeeAttendanceReportComponent]
})
export class HumanResourcesModuleComponent {

  reports:any={
    'Credit Funnel Tracking Report':{name: 'credit_funnel_tracking_report',permission:['credit_funnel_tracking_report']},
    'Employee Attandance Report':{name: 'employee_attendance_report',permission:['employee_attendance_report']},
  }

    constructor(private router:Router){

    }


    reportClicked(data:any){
    if (this.reports[data].name === 'credit_funnel_tracking_report')
    this.router.navigate(['/home/mis/CreditFunnelTrackingReport'], { queryParams: { module: 'common', report: this.reports[data].name } })
    else if (this.reports[data].name === 'employee_attendance_report')
    this.router.navigate(['/home/mis/EmployeeAttendanceReport'], { queryParams: { module: 'hr', report: this.reports[data].name } })
    }
    goBack(){
      window.history.back()
    }

}
