<!DOCTYPE html>
<html>
<head>
<style>
table, th, td {
  border: 2px solid black;
  border-collapse: collapse;
  padding: 8px;
}

table {
  border-spacing: 1 10px; /* Adjust the second value to control the space between rows */
}
</style>
</head>
<body>

<div class="welcome-message" *ngIf="isHomePage() && !showLead && !showCluster  && !showProductivity">
    <h2>
        <!-- <span>Welcome</span>
        <span>to </span> -->
        <span>Daily Funnel </span>
        <span>Dashboard </span>
        <span>{{ currentMonth }}  - </span>
        <span>{{currentYear}}</span>
    </h2>
    <!-- <img src="assets/icons/coming2.png" alt="Coming Soon"> -->
</div>

<div class="router">
    <router-outlet></router-outlet>
</div>

<button class="right-carousel-button" *ngIf="isHomePage() && !showLead && !showCluster  && !showProductivity" (click)= "stopLoop(); showCluster = true"> > </button>
<button class="left-carousel-button" *ngIf="isHomePage() && !showLead && !showCluster  && !showProductivity" (click)= "stopLoop(); showLead = true"> < </button>

<div *ngIf="showLead">
    <app-home></app-home>
</div>

<!-- Render Productivity component if showProductivity is true -->
<div *ngIf="showCluster">
    <app-cluster></app-cluster>
</div> 
<div *ngIf="showProductivity">
    <app-productivity></app-productivity>
</div>

<div id="tabletwo-container" class="tabletwo-container" *ngIf="isHomePage() && !showLead && !showCluster  && !showProductivity">

    <div class="data-container" *ngIf="isHomePage() && !showLead && !showCluster  && !showProductivity">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="table-responsive">
                        <table class="table table-custom">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Daily Leads Funnel</th>
                                    <th>Daily Sales Funnel</th>
                                    <th>Daily Operation Funnel</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let center of centerKeys; let i = index">
                                    <tr>
                                        <td style="background: lightsteelblue; font-size:20px;"><strong *ngIf="i === centerMiddleIndex">Center</strong></td>
                                        <td style="font-size:18px;" ><strong>{{ center }}</strong></td>
                                        <td>{{ dailyFunnelData.center[center][0] }}</td>
                                        <td>{{ dailyFunnelData.center[center][1] }}</td>
                                        <td>{{ dailyFunnelData.center[center][2] }}</td>
                                    </tr>
                                </ng-container>
                                <tr>
                                  </tbody>
                                  <thead class="second_header">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let partner of partnerKeys; let i = index">
                                        <tr>
                                            <td style="background: lightsteelblue; font-size:20px;"><strong *ngIf="i === partnerMiddleIndex">Partner</strong></td>
                                            <td ><strong>{{ partner }}</strong></td>
                                            <td>{{ dailyFunnelData.partner[partner][0] }}</td>
                                            <td>{{ dailyFunnelData.partner[partner][1] }}</td>
                                            <!-- <td>{{ dailyFunnelData.partner[partner][2] }}</td> -->
                                            <!-- <td>
                                                <ng-container *ngIf="dailyFunnelData.partner[partner].length === 3; else nestedLoop">
                                                    <div *ngFor="let keyValuePair of getObjectEntries(dailyFunnelData.partner[partner][2])" class="key-value-pair">
                                                        <div class="key" style="text-align: left; padding-left: 1px;">{{ keyValuePair[0] }}</div>
                                                        <div class="value">{{ keyValuePair[1] }}</div>
                                                    </div>
                                                  </ng-container>
                                                  <ng-template #nestedLoop>
                                                    {{ dailyFunnelData.partner[partner][2] }}
                                                  </ng-template>
                                              </td> -->
                                              <td>0</td>
                                        </tr>
                                    </ng-container>
                                      </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</div>