import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {


    // modules: any = {finance:{name:'Finance Module', permission:[]},insurance:{name:'Insurance Module',permission:[]},welfare:{name:'Welfare Module',permission:[]},
  // crm: {name:'CRM Module',permission:[]},collection:{name:'Collection Module',permission:[]}, customer:{name:'Customer Module',permission:[]},ice:{name:'ICE Module',permission:[]},
  // payment: {name:'Payment Module',permission:[]},partner:{name: 'Partner Module',permission:[]}}

  modules: any = [{name:'Business Finance',permission:['goat_trust_report','insurance_report','credit_business_overall_report']},
 // {name:'Common',permission:['credit_funel_tracking_report','credit_evaluation_report','master_data_report','loan_collection_report','credit_due_demand_report']},
 {name:'Central Operations',permission:["customer_details_report","lead_to_loan_disbursment_report",'test_collection_report','test_disbursement_report','ckyc_report','eligibility_check_failed_customer_report',"customer-journey-drop-report","kinara_business_details_report","kinara_customer_details_report"]},
 {name:'Corporate Finance', permission:[
  'loan_collection_report',
  'credit_reconciliation_report','kinara-business-details-report','kinara-customer-details-report','kinara-disbursement-details-report']},
 {name:'Portfolio Collections',permission:["collection_report","demand_sheet_report"]},

 {name:'Risk',permission:["ice_response_report","rejection_report","credit_funel_tracking_report","credit_evaluation_report"]},
//{name:'Risk',permission:["ice_response_report","rejection_report","credit_funel_tracking_report","credit_evaluation_report"]},
  {name:'Sales and Distribution',permission:['hlp_report','hlp_hotlead_report']},
  // {name:'Welfare',permission:["welfare_report"]},
  {name:'Human Resource',permission:['hlp_report','hlp_hotlead_report']},
  // {name:'Campaign Management',permission:["welfare_report"]},
  // {name:'Products',permission:["welfare_report"]},
  // {name:'Marketing',permission:["welfare_report"]},
   //{name:'CRM Module',permission:['hlp_report']},
  //{name:'Payment Module',permission:['hlp_report']},
  // {name: 'Partnerships',permission:['hlp_report','hlp_hotlead_report']}
]

  constructor(private router: Router) {

  }

  moduleClicked(module: any) {
    if (module === 'Corporate Finance') {
      this.router.navigate(['home/mis/FinanceModule'])
    }
    if (module === 'Common') {
      this.router.navigate(['home/mis/CommonModule'])
    }
    if (module === 'Business Finance') {
      this.router.navigate(['home/mis/InsuranceModule'])
    }
    if (module === 'Portfolio Collections') {
      this.router.navigate(['home/mis/CollectionModule'])
    }
    if (module === 'Central Operations') {
      this.router.navigate(['home/mis/CustomerModule'])
    }
    if(module==='Risk'){
      this.router.navigate(['home/mis/IceModule'])
    }
    if(module==='Welfareee Module'){
      this.router.navigate(['home/mis/WelfareModule'])
    }
    if(module==='Partner Module'){
      this.router.navigate(['home/mis/PartnerModule'])
    }
    if(module==='Sales and Distribution'){
      this.router.navigate(['home/mis/SAndDModule'])
    }
    if(module==='Human Resource'){
      this.router.navigate(['home/mis/HumanResourseModule'])
    }
  }

}
