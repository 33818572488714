import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { interval } from 'rxjs';
import { LoopService } from '../loop.service';


@Component({
  selector: 'app-productivity',
  templateUrl: './productivity.component.html',
  styleUrls: ['./productivity.component.scss']
})
export class ProductivityComponent {
  showLead: boolean = false;
  showCluster: boolean = false;
  showTableOne:  boolean = true;
  showTableTwo : boolean = false;
  showTableThree : boolean = false;
  dashboardData: any;
  headerIndex: number;
  showDailyFunnel: boolean = false;
  currentMonth:string;
  currentYear:number;

  constructor(public router: Router, private dashboardService: DashboardService,private loopService: LoopService) {}


  showFirstTable(): void {
    this.showTableTwo = false;
    this.showTableOne = true;
    this.showTableThree  = false;
}

   showSecondTable(): void {
    this.showTableTwo = true;
    this.showTableOne = false;
    this.showTableThree  = false;
}
showThirdTable(): void {
  this.showTableTwo = false;
  this.showTableOne = false;
  this.showTableThree  = true;
}

  ngOnInit(): void {
    const date = new Date();
    this.currentMonth = date.toLocaleDateString('en-US',{month:'long'});
    this.currentYear = date.getFullYear();
    this.startCarousel();
    this.dashboardService.getProductivityFunnelDashboard().subscribe(
      (data: any) => {
        this.dashboardData = data;
        // this.headerIndex = this.dashboardData && this.dashboardData.length > 0 ?
        //                   Object.keys(this.dashboardData[0]).length + 1 : 0;

        //console.log(typeof(this.dashboardData), 'dashboard');
      },
      (error) => {
        console.error('API Error:', error);
      }
    );


  }


  isHomePage(): boolean {
    return this.router.url === '/home';
  }
  stopLoop(): void {
    this.loopService.stopLoop();
  }

  ngOnChanges(): void {
    //console.log('ClusterComponent changes detected');
  }
  startCarousel(): void {
    // Start the carousel every 0.5 minutes (adjust the time interval as needed)
      interval(0.1 * 60 * 1000) // 0.5 minutes in milliseconds
      .subscribe(() => {
        // Toggle between the views
        if (this.showTableOne) {
          this.showTableOne = false;
          this.showTableTwo = true;
          this.showTableThree = false;
        } else if (this.showTableTwo) {
          this.showTableOne = false;
          this.showTableTwo = false;
          this.showTableThree = true;
        } else {
          this.showTableOne = true;
          this.showTableTwo = false;
          this.showTableThree = false;
        }
      });
  }

}

