<div class="main">
    <img class='logo' src="assets/icons/Arth_logo.svg">

    <div class="auth">
        <!-- Replace the label with a div for styling purposes -->
        <div class="mt-5 heading">
            <img src="assets/icons/arth-pink.png" alt="Login" class="login-icon">
        </div>
        <div class="mt-5 form-container">
            <form (ngSubmit)="onSubmit()" name="loginForm" [formGroup]="loginForm" class="form-input">
                <div class="input-container">
                    <mat-label>Email ID</mat-label>
                    <input class="input form-control mx-2" size="32" placeholder="Email ID" formControlName="emailId">
                </div>
                <div class="input-container">
                    <mat-label>Password</mat-label>
                    <input type="password" class="input form-control mx-2" size="32" placeholder="Password" formControlName="password">
                </div>
                <!-- Button -->
                <div class="error-message mt-2" *ngIf="errorMessage">{{ errorMessage }}</div>
                <button type="submit" class="mt-2 btn btn-primary login-button">Login</button>
            </form>
        </div>
    </div>
</div>
