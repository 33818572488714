import { Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
 roles:any=['Master Admin','Admin','Manager','Supervisor','CET','Collections','S&D','Ops Finanace','dd']
 cc=[1,2,3,4,5,6,7,8,9]
 loop:any
 loopArray:any

 constructor(
  private roleService:RoleService,
  public router:Router
  ){}

  ngOnInit(): void {
    this.roleService.fetchRoles().subscribe((data:any)=>{
      //console.log('roles Fetched',data)
      this.roles=data
    this.loop=Math.ceil(this.roles.length/4)
    this.loopArray=Array.from({length: this.loop}, (v, k) => (k+1)*4-5);

    })
  }


//  loopArray=[ ...Array(this.loop).keys() ].map( i => i-1);

 check(data:any){
  //console.log(this.loopArray)
  this.roleService.fetchSpecificRole(data).subscribe((a:any)=>{
    //console.log(a)
    this.roleService.store=a
    localStorage.setItem('roleDetail',JSON.stringify(a))
    this.router.navigate(['/home/roles/create-new-role'],{ queryParams: {edit:true,role:data}})
  })

 }
}
