import { Component, OnInit,AfterViewInit,OnDestroy } from '@angular/core';
// import { ApexChart } from 'ng-apexcharts';
import * as ApexCharts from 'apexcharts';
import { DashboardService } from 'src/app/services/dashboard.service';
import { subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { GoatTrustReportComponent } from '../../mis/insurance-module/goat-trust-report/goat-trust-report.component';
// import { values } from 'lodash';

interface SeriesData {
  name: string;
  data: number[];
}

interface FunnelDataItem {
  day: string;
  Assigned: number;
  "Loan form": string;
  "Credit Offered": number;
  "Loan Acceptance": number;
  "QC Approved": number;
  RFD: number;
  [key: string]: string | number;
}

@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.scss']
})

export class Dashboard1Component implements OnInit ,AfterViewInit,OnDestroy {

  errorMessage:string = '';
  isLoading: boolean = true;
  data :any;
  monthly_data:any;
  monthly_area_data:any;
  summedData: { name: string; sum: number }[] = [];
  private intervalId: any;
  private donutCharts: ApexCharts[] = [];

  constructor(
    private dashboardservice: DashboardService
    ) {}
  ngOnInit(){
    this.get_data();
    // this.startUpdatingClass();
    this.intervalId = setInterval(() => {
      window.location.reload();
    }, 120000);

  }
  ngAfterViewInit(){

  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.donutCharts.forEach(chart => chart.destroy());
  }

  startUpdatingClass(): void {
    this.intervalId = setInterval(() => {
      this.get_data();
      this.updateClass();
      this.isLoading=false;
    }, 2 * 60 * 1000);
  }

  updateClass(): void {

    const element = document.querySelector('.cards');
    if (element) {
      element.classList.toggle('card');
    }
    //console.log('cards_updated');
  }

  get_data(): void{
    this.dashboardservice.getFunnelDataDashboad2().subscribe(
      {
        next:(data) => {
          this.isLoading = false;
          this.data = data[0];
          //console.log('funnel_data', this.data);
        },
        error:(error) => {
          this.errorMessage = error.message;
          this.isLoading = false;
        }
      }
    );

    this.dashboardservice.getMonthlyFunnelDataDashboad().subscribe(
      {
        next:(d) => {
          this.monthly_data = d[0];
          //console.log('funnel_monthly_data', this.monthly_data);
          this.getmonthlyareadata();
          //console.log('monthly_area_data',this.monthly_area_data);
          setTimeout(() => {
            setTimeout(() => {
              setTimeout(() => {
                this.isLoading = false;
              }, 1000);
              this.renderChart();
            }, 2000);
            this.renderDonutCharts();
          }, 3000);
        //console.log('**********new_commit:23SEP18FGTHJU**************');

        },
        error:(error) => {
          this.errorMessage = error.message;
          this.isLoading = false;
        }
      }
    );
    }

  colorPalettes = [
    '#dcfce7','#fadde7', '#fff4de', '#f3e8ff', '#edf6f9', '#ffc9b9',
    '#EE3E80', '#473192', '#45C1C0', '#F78D2D'
  ];
  colorPalettes2 = ['#a0d5b1','#cfa4a1','#f7d081','#c4a8ff',
  '#c4d6d9',
  '#ff9a8e','#b73c6e','#2e1a5d','#1a8f8e','#c75a1d']
  cardData = this.getCardData();

  getCardData() {
    if (!this.data) return [];
    return [
      { title: 'Assigned',
      values: [
        { label: 'Total', value: this.data.assigned },
      ]
       },
      {
        title: 'Loan Form',
        values: [
          { label: 'Total', value: this.data.loan_form.total },
          { label: 'Attempt', value: this.data.loan_form.attempted},
          { label: 'Complete', value: this.data.loan_form.completed}
        ]
      },
      { title: 'Credit Offered',
      values: [
        { label: 'Total', value: this.data.credit_offered  }
      ]},
      {
        title: 'Loan Acceptance',
        values: [
          { label: 'Total', value: this.data.loan_acceptance.total },
          { label: 'New', value: this.data.loan_acceptance.new },
          { label: 'Repeat', value: this.data.loan_acceptance.repeat }
        ]
      },
      { title: 'QC Approved',
      values: [
        { label: 'Total', value:  this.data.qc_approved }
      ] },
      { title: 'RFD',
        values: [
        { label: 'Total', value:  this.data.rfd },
        ]}
    ];
  }
  getCardByIndex(index: number) {
    const cardData = this.getCardData();
    return cardData[index];
  }
  getValuesFromIndex(cardIndex: number, startIndex: number) {
    const card = this.getCardByIndex(cardIndex);
    if (card && card.values) {
      return card.values.slice(startIndex);
    }
    return [];
  }

  getValueByLabel(cardIndex: number, label: string): number | undefined {
    const card = this.getCardByIndex(cardIndex);
    if (card && card.values) {
      const valueObject = card.values.find(item => item.label === label);
      return valueObject ? valueObject.value : undefined;
    }
    return undefined;
  }

  renderDonutCharts() {

    const loanFormData = [
      { name: 'Attempted', value: this.data.loan_form.attempted },
      { name: 'Completed', value: this.data.loan_form.completed }
    ];

    const loanAcceptanceData = [
      { name: 'New', value: this.data.loan_acceptance.new },
      { name: 'Repeat', value: this.data.loan_acceptance.repeat }
    ];

    const loanFormChart = new ApexCharts(document.querySelector('#loan_form_chart'), {
      chart: {
        height:200,
        type: 'donut',
      },
      series: loanFormData.map(item => item.value),
      labels: loanFormData.map(item => item.name),
      colors: [
        this.getCardColor(9),
        this.getCardColor(8),
        this.getCardColor(10)
      ],
      fill:{
        type:"gradient"
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%'
          }
        }
      },
      dataLabels: {
        enabled: true
      }
    });
    //console.log('Loan Form Chart:', loanFormChart);
    this.donutCharts.push(loanFormChart);
    loanFormChart.render();

    const loanAcceptanceChart = new ApexCharts(document.querySelector('.loan_acceptance_chart'),{
      chart: {
        height:200,
        type: 'donut',
      },
      series: loanAcceptanceData.map(item => item.value),
      labels: loanAcceptanceData.map(item => item.name),
      colors: [
        this.getCardColor(9),
        this.getCardColor(8),
        this.getCardColor(10)
      ],
      fill:{
        type:"gradient"
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%'
          }
        }
      },
      dataLabels: {
        enabled: true
      }
    });
    //console.log('Loan Acceptance Chart:', loanAcceptanceChart);
    this.donutCharts.push(loanAcceptanceChart);
    loanAcceptanceChart.render();

  }


  getmonthlyareadata(): void {
    if (!this.monthly_data) {
      this.monthly_area_data = [];
      return ;
    }
    const data_collection: Record<string, number[]> = {};

    this.monthly_data.forEach((item: FunnelDataItem) => {
      Object.keys(item).forEach((key: string) => {
        if (key === 'day') return;
        if (!data_collection[key]) {
          data_collection[key] = [];
        }
        data_collection[key].push(Number(item[key]));
      });
    });

    this.monthly_area_data = Object.keys(data_collection).map((k, i) => {
      return {
        name: k,
        data: Object.values(data_collection[k as keyof typeof data_collection]).map((values) => Number(values)),
        color: this.colorPalettes2[i] || '#000000',
      };
    });




    //console.log('monthly_area_data', this.monthly_area_data);
  }

  getDaysOfCurrentMonthAsStrings(): string[] {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days: string[] = [];
    for (let day = firstDay.getDate(); day <= lastDay.getDate(); day++) {
      days.push(day.toString().padStart(2, '0'));
    }
    return days;
  }


  renderChart() {

    const chartData = {
      chart: {
        height: 420,
        type: 'area'
      },
      stroke: {
        curve: 'smooth'
      },
      series:this.monthly_area_data,

      xaxis: {

        categories:this.getDaysOfCurrentMonthAsStrings()
      }
    };

    const chart = new ApexCharts(document.querySelector('.area_chart'), chartData);
    chart.render();

    this.summedData = chartData.series.map((series: SeriesData) => ({
      name: series.name,
      sum: series.name === 'RFD'
        ? Math.max(...series.data)
        : series.data.reduce((acc: number, curr: number) => acc + curr, 0)
    }));
  }


  getCardColor(index: number): string {
    return this.colorPalettes[index] || '#000000';
  }


  getChartColor(index:number):string {
    return this.colorPalettes2[index] || '#000000';
  }

}



