import { Component } from '@angular/core';

@Component({
  selector: 'app-hlp-status-report',
  templateUrl: './hlp-status-report.component.html',
  styleUrls: ['./hlp-status-report.component.scss']
})
export class HlpStatusReportComponent {
  name='HLP Status Report'
  reportInformation  = 'This report provides a brief information about HLPs'
}
