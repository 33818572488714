import { Component } from '@angular/core';

@Component({
  selector: 'app-hlp-invoicing-report',
  templateUrl: './hlp-invoicing-report.component.html',
  styleUrls: ['./hlp-invoicing-report.component.scss']
})
export class HlpInvoicingReportComponent {
  name='HLP Invocing Report'
  reportInformation  = 'Invoice generated by HLP.'
}
