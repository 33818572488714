import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ltd-report',
  templateUrl: './ltd-report.component.html',
  styleUrls: ['./ltd-report.component.scss']
})
export class LtdReportComponent implements OnInit{

  config:any
  name='Lead To Disbursement Report'

  ngOnInit(): void {

    this.config=[
      {
        header:'Serial No',
        field:'sno'
      },
      {
        header:'Lead Id',
        field:'lead_id'
      },
      {
        header:'Loan UID',
        field:'loan_uid'
      },
      {
        header:'Customer UCIC',
        field:'customer_ucic'
      },
      {
        header:'Loan Type',
        field:'loan_type'
      },
      {
        header:'Customer Name',
        field:'customer_name'
      },
      {
        header:'Customer Gender',
        field:'customer_gender'
      },
      {
        header:'Customer Mobile',
        field:'customer_mobile'
      },
      {
        header:'Customer Alternate Mobile',
        field:'customer_alternate_mobile'
      },
      {
        header:'Center',
        field:'center'
      },
      {
        header:'Cluster',
        field:'cluster'
      },
      {
        header:'City',
        field:'city'
      },
      {
        header:'State',
        field:'state'
      },
      {
        header:'Lead Date',
        field:'lead_date'
      },
      {
        header:'Co-Borrower Name',
        field:'co_borrower_name'
      },
      {
        header:'Referred by ARTH HLP',
        field:'referred_by_arth_hlp'
      },
      {
        header:'Sourcing Person ID',
        field:'sourcing_person_id'
      },
      {
        header:'Sourcing Person Name',
        field:'sourcing_person_name'
      },
      {
        header:'Partner Type',
        field:'partner_type'
      },
      {
        header:'Product Type',
        field:'product_type'
      },
      {
        header:'Product Code',
        field:'product_code'
      },
      {
        header:'Tenure',
        field:'tenure'
      },
      {
        header:'Lender Type',
        field:'lender_type'
      },
      {
        header:'Loan Amount',
        field:'loan_amount'
      },
      {
        header:'Net Disbursed Customers',
        field:'net_disbursed_customers'
      },
      {
        header:'Net Disbursed Partners',
        field:'net_disbursed_partners'
      },
      {
        header:'Net Disbursed Amount',
        field:'net_disbursed_amount'
      },
      {
        header:'Loan Status',
        field:'loan_status'
      },
      {
        header:'Loan Final Status',
        field:'loan_final_status'
      },
      {
        header:'Source Department',
        field:'source_department'
      }
    ]
  }
}
    