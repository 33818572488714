<nav class="dashboard_nav">
  <div class="left_nav">
    <img [src]="'assets/icons/Arth_logo.svg'" alt="Logo"  class="logo" />
    <div class="left_component">
      <div class = "nav_icon">
      <mat-icon class="bubbly-button" aria-hidden="false" aria-label="Dashboard" fontIcon="space_dashboard"></mat-icon>
      <a (click)="navigateTo('dashboard')" routerLink="/home/mis" class="nav-link">Dashboard</a>
      </div>
    <div class = "nav_icon">
      <mat-icon aria-hidden="false" aria-label="Reports" fontIcon="summarize"></mat-icon>
      <a (click)="navigateTo('home/mis')" routerLink="/report" class="nav-link">Reports</a>
    </div>
  <div class = "nav_icon">
      <mat-icon aria-hidden="false" aria-label="Users" fontIcon="group"></mat-icon>
      <a (click)="navigateTo('home/users')"routerLink="/home" class="nav-link">Users</a>
    </div>
    </div>
  </div>

  <div class="right_nav">
    <div class="right_component">
      <div class = "nav_icon">
        <mat-icon aria-hidden="false" aria-label="AI_gen" fontIcon="star"></mat-icon>
        <!-- <a routerLink="/home" class="nav-link">AI demand</a> -->
        <!-- <a class="nav-link">AI demand</a> -->
      </div>
    <div class = "nav_icon">
    <mat-icon aria-hidden="false" aria-label="Exit" fontIcon="exit_to_app"></mat-icon>
    <a click = 'logout()' routerLink="" class="nav-link">exit</a>
    </div>
  </div>
  </div>
</nav>

<div class="dashboard-container">
  <!-- <div class="dashboard-windows">
    <app-reports></app-reports>
  </div> -->
  <div class="dashboard-selector">
    <button (click)="previousDashboard()" [disabled]="currentDashboardIndex === 0" class="nav-button">Previous</button>
    <button (click)="nextDashboard()" [disabled]="currentDashboardIndex === dashboards.length - 1" class="nav-button">Next</button>

    <select (change)="onDropdownChange($event)" [value]="currentDashboardIndex" class="nav-select">
      <option *ngFor="let dashboard of dashboards; let i = index" [value]="i">
        {{ dashboard | titlecase }}
      </option>
    </select>

  </div>
  <div class="dashboard-windows">


    <ng-container [ngSwitch]="currentDashboardIndex">
      <app-dashboard1 *ngSwitchCase="0"></app-dashboard1>
      <app-dashboard2 *ngSwitchCase="1"></app-dashboard2>
      <app-dashboard3 *ngSwitchCase="2"></app-dashboard3>
      <app-dashboard4 *ngSwitchCase="3"></app-dashboard4>
      <app-dashboard5 *ngSwitchCase="4"></app-dashboard5>
      <!-- Add more dashboards as needed -->
    </ng-container>
  </div>
</div>
