import { Component } from '@angular/core';

@Component({
  selector: 'app-loan-collection-report',
  templateUrl: './loan-collection-report.component.html',
  styleUrls: ['./loan-collection-report.component.scss']
})
export class LoanCollectionReportComponent {
  config:any
  name='Credit Repayment Report'
  ngOnInit(): void {
  this.config=[
    {
      header:'Serial No',
      field:'sno'
    },
    {
      header:'Lead ID',
      field:'lead_id'
    },
    {
      header:'Loan UID',
      field:'loan_uid'
    },
    {
      header:'Customer UCIC',
      field:'customer_ucic'
    },
    {
      header:'Customer Name',
      field:'customer_name'
    },
    {
      header:'Customer Gender',
      field:'customer_gender'
    },
    {
      header:'Customer Mobile',
      field:'customer_mobile'
    },
    {
      header:'Partner Type',
      field:'partner_type'
    },
    {
      header:'Product Type',
      field:'product_type'
    },
    {
      header:'Product Code',
      field:'product_code'
    },
    {
      header:'Lender Type',
      field:'lender_type'
    },
    {
      header:'Loan Amount',
      field:'loan_amount'
    },
    {
      header:'Net Disbursed',
      field:'net_disbursed'
    },
    {
      header:'Disbursement Date',
      field:'disbursement_date'
    },
    {
      header:'Installment Amount',
      field:'installment_amount'
    },
    {
      header:'Due Date',
      field:'due_date'
    },
    {
      header:'Penalty Paid',
      field:'penalty_paid'
    },
    {
      header:'Interest Paid',
      field:'interest_paid'
    },
    {
      header:'Principal Paid',
      field:'principal_paid'
    },
    {
      header:'Amount Paid',
      field:'amount_paid'
    },
    {
      header:'Installment Paid',
      field:'installment_paid'
    },
    {
      header:'Installment Status',
      field:'installment_status'
    },
    {
      header:'Paid Date',
      field:'paid_date'
    },
    {
      header:'Payment Mode',
      field:'payment_mode'
    },
    {
      header:'Center',
      field:'center'
    },
    {
      header:'Cluster',
      field:'cluster'
    },
    {
      header:'DPD Days',
      field:'dpd_days'
    },
    {
      header:'Loan Status',
      field:'loan_status'
    },
    {
      header:'Writeoffcollection',
      field:'writeoffcollection'
    },
    {
      header:'Report Date',
      field:'report_date'
    }]
  }
  
}

  