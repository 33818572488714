import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({providedIn:'root'})

export class UserService{

    selectedUser:any=[]
    private apiBaseUrl = environment.apiUrl

    constructor(private http:HttpClient){

    }

    esHeaders() {
		let headers = new HttpHeaders();
		let accessToken:any = localStorage.getItem('refereshToken');
		if (accessToken) {
			headers = headers.set('Authorization', `b ${accessToken}`);
		}
		return {headers};
	}

    fetchUser(){
    return this.http.get(this.apiBaseUrl+'users',this.esHeaders())
    }

    get_location(){
      return this.http.get(this.apiBaseUrl+'get_location',this.esHeaders())
    }



    createNewUser(data:any){
        data={...data,'password_digest':'abacaf'}
        return this.http.post(this.apiBaseUrl+'users',data,this.esHeaders())
    }

    editUser(data:any){
    data={...data,'password_digest':'abacaf'}
    //console.log('data',data)
    return this.http.put(this.apiBaseUrl+'users'+`/${data.emp_id}`,data,this.esHeaders())

    }

    search(data:any){
        return this.http.get(this.apiBaseUrl+'/universal_search/users/'+`/${data}`,this.esHeaders())
    }

    selectUser(data:any){
        this.selectedUser=data

    }

}
