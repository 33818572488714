import { Component } from '@angular/core';

@Component({
  selector: 'app-master-bussiness-report',
  templateUrl: './master-bussiness-report.component.html',
  styleUrls: ['./master-bussiness-report.component.scss']
})
export class MasterBussinessReportComponent {
  config:any
  name='Master Business Report'
  reportInformation  = 'Bussiness Report.'
}
